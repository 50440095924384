import service from "@/utils/requestAdmin";

/**
 * 获取IDC列表
 * @returns {*}
 */
export function getIdcListApi() {
    return service({
        url: '/idc',
        method: 'get',
    })
}

/**
 * 获取机器在线状态
 * @param params
 * @returns {*}
 */
export function getSurvivalApi(params) {
    return service({
        url: '/jdReptileHook',
        method: 'get',
        params
    })
}