import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login/index.vue";
import MainLayout from "@/views/MainLayout/index.vue";
import IDC from "@/views/IDC/index.vue";
import SelectGoods from "@/views/SelectGoods/index.vue";
import JdGoods from "@/views/JdGoods/index.vue";
import ECard from "@/views/ECard/index.vue";
import ComBo from "@/views/ComBo/index.vue";
import AddComboGiftPack from "@/views/ComBo/components/AddComboGiftPack/index.vue";
import Order from "@/views/Order/index.vue";
import SpareSelectGoods from "@/views/SpareSelectGoods/index.vue";
import XiTunBao from "@/views/xitunbao/xi_tun_bao.vue";
import XiTunBaoOrder from "@/views/XiTunBaoOrder/index.vue";
import XiTunBaoCard from "@/views/XiTunBaoCard/index.vue";
import XiTunBaoKdlOrder from "@/views/XiTunBaoKdlOrder/index.vue";
import AfterSale from "@/views/afterSale/after_sale.vue";

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '',
                component: SelectGoods,
            },
            {
                path: 'selectGoods',
                component: SelectGoods,
            },
            {
                path: 'SpareSelectGoods',
                component: SpareSelectGoods,
            },
            {
                path: 'jdGoods',
                component: JdGoods,
            },
            {
                path: 'idc',
                component: IDC,
            },
            {
                path: 'ECard',
                component: ECard,
            },
            {
                path: 'combo',
                component: ComBo,
                meta:{
                    keepAlive:true
                }
            },
            {
                path: 'order',
                component: Order,
            }, {
                path: 'XiTunBao',
                component: XiTunBao,
            },/*{
                path: 'XiTunBaoOrder',
                component: XiTunBaoOrder,
            }, {
                path: 'XiTunBaoCard',
                component: XiTunBaoCard,
            },*/ {
                path: 'XiTunBaoKdlOrder',
                component: XiTunBaoKdlOrder,
            }, {
                path: 'AfterSale',
                component: AfterSale,
            }, {
                path: 'addOrUpdateComboGiftPack/:id?',
                component: AddComboGiftPack,
                name: "AddOrUpdateComboGiftPack",
            }
        ]
    },
    {
        path: '/login',
        component: Login,
    }
]
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
