// user.js
import axios from "axios";

const state = {
    goodsId: "",
    goodList: [
        
    ],
};

const mutations = {
    SET_GOODS_LIST(state, goodsList) {
        state.goodList = goodsList;
    },
};

const actions = {
    setGoodsList({ commit }, goodsList) {
        commit("SET_GOODS_LIST", goodsList);
    },
};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
