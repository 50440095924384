import service from "@/utils/requestBackend";

/**
 * 获取卡券列表
 * @param params
 * @returns {*}
 */
export function getECardList(params) {
    return service({
        url: '/ecard-card',
        method: 'get',
        params,
    })
}

/**
 * 创建卡券
 * @param data
 * @returns {*}
 */
export function createECard(data) {
    return service({
        url: '/ecard-card',
        method: 'post',
        data,
    })
}

/**
 * 修改卡券
 *
 * @param data
 * @returns {*}
 */
export function editECard(data) {
    return service({
        url: '/ecard-card',
        method: 'put',
        data,
    })
}

/**
 * 删除卡券
 *
 * @param params
 * @returns {*}
 */
export function deleteECard(params) {
    return service({
        url: '/ecard-card',
        method: 'delete',
        params,
    })
}

export function getECardOne(params) {
    return service({
        url: '/ecard-card/getOne',
        method: 'get',
        params,
    })
}

export function uploadECardUser(data) {
    return service({
        url: '/ecard-card-user/import',
        method: 'post',
        data,
    })
}

export function uploadComboECardUser(data) {
    return service({
        url: '/ecard-card-user/importCombo',
        method: 'post',
        data,
    })
}

/**
 * 获取用户列表
 *
 * @param params
 * @returns {*}
 */
export function getUserList(params) {
    return service({
        url: '/ecard-card-user/getUserList',
        method: 'get',
        params,
    })
}


export function deleteUserApi(data) {
    return service({
        url: '/ecard-card-user/deleteUser',
        method: 'delete',
        data,
    })
}

export function updateUserApi(data) {
    return service({
        url: '/ecard-card-user/updateUser',
        method: 'post',
        data,
    })
}