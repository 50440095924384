<template>
  <div style="padding-top: 10px">
    <title-card style="max-width: 97%; margin: 10px auto" title="订单管理">
      <div style="position: absolute; right: 70px">
      </div>
      <template v-slot:subTitle>

      </template>
      <div style="margin-left: auto; margin-right: 10px;">
        <el-button type="primary" @click="$router.replace('/AfterSale')">前往售后记录</el-button>
      </div>
    </title-card>
    <div style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px">
      <div v-show="moreConditions">
        <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;align-items: center;">
          <div style="margin-left: 10px;margin-bottom: 10px">
            卡券编号：
            <el-input v-model="params.cardId" placeholder="请输入需要查询的卡券编号"
                      size="mini" style="width: 200px"></el-input>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            礼包名称：
            <el-input v-model="params.goodsName" placeholder="请输入需要查询的卡包名称"
                      size="mini" style="width: 200px"></el-input>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            手机号：
            <el-input v-model="params.phone" placeholder="请输入需要查询的手机号"
                      size="mini" style="width: 200px"></el-input>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            收件人：
            <el-input v-model="params.name" placeholder="请输入需要查询的收件人"
                      size="mini" style="width: 200px"></el-input>
          </div>


          <div style="margin-left: 10px;margin-bottom: 10px">
            <el-button size="medium" type="primary" @click="getOrderList">搜索</el-button>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px;margin-right: 443px;">
            <el-button size="medium" @click="resetListParams">重置</el-button>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            订单类型：
            <el-select v-model.number="params.type" clearable placeholder="请选择订单类型" size="mini">
              <el-option :value="1" label="卡券卡密直接下单"></el-option>
              <el-option :value="2" label="卡券存入卡包下单"></el-option>
              <el-option :value="3" label="导入用户分区下单"></el-option>
            </el-select>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            订单状态：
            <el-select v-model="params.status" multiple clearable placeholder="请选择订单状态" size="mini">
              <el-option :value="-2" label="部分取消"></el-option>
              <el-option :value="-1" label="已取消"></el-option>
              <el-option :value="0" label="提交失败"></el-option>
              <el-option :value="1" label="待发货"></el-option>
              <el-option :value="2" label="已发货"></el-option>
              <el-option :value="3" label="已妥投"></el-option>
              <el-option :value="4" label="部分妥投"></el-option>
              <el-option :value="5" label="已完成"></el-option>
              <el-option :value="6" label="部分完成"></el-option>
              <el-option :value="7" label="部分发货"></el-option>
            </el-select>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            日期：
            <el-date-picker
                v-model="value2"
                :picker-options="pickerOptions"
                align="right"
                end-placeholder="结束下单日期"
                range-separator="至"
                size="mini"
                start-placeholder="开始下单日期"
                type="datetimerange">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button type="primary" plain @click="moreConditions = !moreConditions" size="small">搜索条件
            <i v-if="moreConditions" class="el-icon-arrow-up"></i>
            <i v-if="!moreConditions" class="el-icon-arrow-down"></i>
          </el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button type="success" size="small" @click="exportOrder">导出订单数据</el-button>
        </div>
      </div>
      <el-table
          :data="rows"
          style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="margin: 10px">
              <el-table
                  :data="props.row.list"
                  style="width: 100%;margin-bottom: 20px;">
                <el-table-column
                    fixed
                    label="JD订单号(子)"
                    prop="orderNo"
                    width="150px">
                </el-table-column>
                <el-table-column
                    fixed
                    label="京东原价"
                    prop="originalPrice"
                    width="80px">
                </el-table-column>
                <el-table-column
                    fixed
                    label="采购价"
                    prop="disPrice"
                    width="80px">
                </el-table-column>
                <el-table-column
                    fixed
                    label="数量"
                    prop="num"
                    width="60px">
                </el-table-column>
                <el-table-column
                    label="SkuId"
                    prop="skuId"
                    width="150px">
                </el-table-column>
                <el-table-column
                    label="订单状态"
                    width="80px">
                  <template slot-scope="scope">
                    {{
                      scope.row.status == 1 ? '待发货' : scope.row.status == 2 ? '已发货' : scope.row.status == 3 ? '已妥投' : scope.row.status == 4 ? '已完成' : '已取消'
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="商品名"
                    prop="skuName" width="1000px">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                >
                  <template slot-scope="{row}">
                    <el-link v-if="row.orderNo" :underline="false" type="primary"
                             @click="getBackendDeliveryByJdOrderId(row)">查看物流
                    </el-link>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            :align="'center'"
            fixed
            label="卡券编号"
            prop="cardId"
            width="200px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            :align="'center'"
            fixed
            label="卡券名"
            prop="cardName"
            width="200px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            fixed
            label="礼包名称"
            prop="goodsName"
            width="200px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            label="一采一购订单号"
            prop="id"
            width="176">
        </el-table-column>
        <el-table-column
            fixed
            label="JD订单号(父)"
            prop="orderNo"
            width="120">
        </el-table-column>
        <el-table-column
            fixed
            label="订单状态">
          <template slot-scope="scope">
            {{
              scope.row.status == -2 ? '部分取消' : scope.row.status == -1 ? '已取消' : scope.row.status == 0 ? '失败' : scope.row.status == 1 ? '待发货' :
                  scope.row.status == 2 ? '已发货' : scope.row.status == 3 ? '已妥投' : scope.row.status == 4 ? '部分妥投' : scope.row.status == 5 ? '已完成' :
                      scope.row.status == 6 ? '部分完成' : scope.row.status == 7 ? '部分发货' : '失败'
            }}
          </template>
        </el-table-column>
        <el-table-column
            fixed
            label="订单类型">
          <template slot-scope="scope">
            {{
              scope.row.type == 1 ? '卡券卡密直接下单' : scope.row.type == 2 ? '卡券存入卡包下单' : scope.row.type == 3 ? '导入用户分区下单' : '其他方式下单'
            }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            fixed
            label="运费"
            prop="freight"
            width="80">
          <template slot-scope="{row}">
            {{ row.freight | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="下单时间"
            prop="createTime"
            width="176">
        </el-table-column>
        <el-table-column
            align="center"
            label="收货人"
            prop="name"
            width="80"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            label="收货手机号"
            prop="phone"
            width="120">
        </el-table-column>
        <el-table-column
            label="收货地址" prop="provinceName"
            width="300" :show-overflow-tooltip="true">
          <template slot-scope="{row}">
            {{ row.provinceName }} {{ row.cityName }} {{ row.countyName }} {{ row.streetName }}-{{ row.detailAddress }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="是否拆单"
            width="80">
          <template slot-scope="{row}">
            {{ row.isSplit ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
            label="响应信息"
            prop="response"
            width="1000px">
        </el-table-column>
        <el-table-column
            label="操作">
        </el-table-column>
      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-dialog
        :visible.sync="deliveryDialog"
        title="物流"
        width="30%">
      <el-timeline :reverse="true">
        <el-timeline-item
            v-for="(activity, index) in deliveryList"
            :key="index"
            :timestamp="activity.trackMsgTime | parseTime">
          {{ activity.trackContent }}
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliveryDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {exportOrderApi, getBackendDeliveryByJdOrderIdApi, getOrderListApi} from "@/api/backend/orderApi";
import sd from "silly-datetime";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Order",
  data() {
    return {
      // 更多查询
      moreConditions: false,
      rows: [],
      size: 10,
      current: 1,
      total: 0,
      loading: false,
      params: {
        current: 1,
        size: 10,
        status: "",
        type: "",
        cardId: "",
        goodsName: "",
        phone: "",
        name: "",
        startCreateTime: "",
        endCreateTime: ""
      },
      deliveryList: [],
      deliveryDialog: false,
      value2: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  methods: {
    async getOrderList() {
      if (this.value2.length > 0) {
        this.params.startCreateTime = sd.format(this.value2[0])
        this.params.endCreateTime = sd.format(this.value2[1])
      }
      let config = {
        ...this.params,
        status:this.params.status ? this.params.status.join(','):''
      }
      let {data} = await getOrderListApi(config)
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = data.total
    },
    async getBackendDeliveryByJdOrderId(item) {
      this.deliveryDialog = true;
      this.deliveryList = []
      let {data} = await getBackendDeliveryByJdOrderIdApi({
        orderId: item.orderNo,
        skuId: item.skuId
      })
      this.deliveryList = data
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getOrderList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getOrderList();
    },
    /**
     *  重置列表查询参数
     */
    resetListParams() {
      let temp = JSON.parse(JSON.stringify(this.params))
      this.params = {
        current: temp.current,
        size: temp.size,
        status: "",
        cardId: "",
        phone: "",
        name: "",
        startCreateTime: "",
        endCreateTime: ""
      };
      this.value2 = [];
      this.getOrderList();
    },
    async exportOrder() {
      const response = await exportOrderApi(this.params)
      const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sd.format(new Date())}.xlsx`; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  },
  created() {
    this.getOrderList()
  }
}
</script>