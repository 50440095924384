import service from "@/utils/requestBackend";

/**
 * 获取地址
 */
export function findBackendJdRegionApi(data) {
    return service({
        url: "/jdregion/findBackendJdregion",
        method: "get",
        params: data
    })
}

export function getGoodsDetailByIdsApi(data) {
    return service({
        url: "/sjGoods/getGoodsDetailByIds",
        method: "get",
        params: data
    })
}