<template>
  <div style="padding-top: 10px">
    <div v-loading="loading"
         :element-loading-text="loadingMsg"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         style="max-width: 100%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px"
    >
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        用户ID：
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="params.id" placeholder="请输入需要查询的使用者id"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          手机号：
          <el-input v-model="params.mobile" placeholder="请输入需要查询的手机号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          名称：
          <el-input v-model="params.nickname" placeholder="请输入需要查询的名称"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value2"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束结束日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始生成日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="getUserList">搜索</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="resetListParams">重置</el-button>
        </div>
      </div>
      <el-table
          :data="rows"
          style="width: 100%">
        <el-table-column
            fixed
            label="ID"
            prop="id"
            width="50">
        </el-table-column>
        <el-table-column
            label="用户手机号"
            width="200"
            prop="mobile">
        </el-table-column>
        <el-table-column
            label="用户名称"
            width="120"
            prop="nickname">
        </el-table-column>
        <el-table-column
            label="用户头像"
            prop="cardName"
            width="150">
          <template slot-scope="{row}">
            <img :src="row.avatar" style="width: 40px;height: 40px;border-radius: 50%;margin-right: 10px" alt="">
          </template>
        </el-table-column>
        <el-table-column
            label="当前余额"
            width="80"
            prop="integral">
        </el-table-column>
        <el-table-column
            label="历史余额"
            width="80"
            prop="integralHistory">
        </el-table-column>
        <el-table-column
            width="180"
            label="创建时间">
          <template slot-scope="{row}">
            {{ `${row.createTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            width="180"
            label="上次登录时间">
          <template slot-scope="{row}">
            {{ `${row.updateTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            width="180"
            label="余额生成时间">
          <template slot-scope="{row}">
            {{ `${row.integralCreateTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            width="180"
            label="余额修改时间">
          <template slot-scope="{row}">
            {{ `${row.integralUpdateTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="400px">
          <template slot-scope="{row}">
            <el-button
                size="mini"
                @click="adminEditIntegral(row,1)">修改用户积分
            </el-button>
            <el-button
                size="mini"
                @click="getUserIntegralDetail(row.id,1)">查询用户积分
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <!-- 添加图片   -->
    <el-dialog title="添加卡图片" :visible.sync="EditUserIntegralDialog" :modal="false" width="20%">
      <!-- 表单 -->
      <el-form ref="goodsTableData" :model="userIntegralDto" label-width="100px" class="demo-ruleForm" label-position="top">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户ID" prop="name" class="fw-bold fs-10">
              <el-input v-model="userIntegralDto.userId" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分状态" prop="type" class="fw-bold">
              <el-radio-group v-model="userIntegralDto.incomeType">
                <el-radio :label="1">增加积分</el-radio>
                <el-radio :label="2">扣除积分</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分" class="fw-bold fs-10">
              <el-input v-model="userIntegralDto.integral" type="number" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分变动原因" prop="name" class="fw-bold fs-10" >
              <el-input v-model="userIntegralDto.desc" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分关联ID" prop="name" class="fw-bold fs-10" >
              <template slot="label">
                积分关联ID
                <el-tooltip content="有确定的关联消费卡id或者订单id就输入对应的id，没有就填-1或者不填" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
              <el-input v-model="userIntegralDto.otherId" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-form-item class="d-flex justify-content-end">
          <el-button type="primary" @click="adminEditIntegral(userIntegralDto,2)">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 查看用户积分变化   -->
    <el-dialog :visible.sync="UserIntegralDetailDialog" :modal="false" width="60%">
      <template slot="title">
        <div class="fw-bold mb-2" style="font-size: 20px">用户积分明细</div>
        <div style="font-size: 15px;">
          用户增加积分总和：<span class="text-success me-3">{{totalIncreaseIntegral}}</span>  用户扣除积分总和：<span class="text-danger">{{totalDecreaseIntegral}}</span>
        </div>
      </template>
      <el-row>
        <el-col :span="24">
          积分类型：
          <el-select v-model="userIntegralDetail.incomeType" placeholder="请选择积分类型" clearable @change="getUserIntegralDetail(userIntegralDetail.userId,null)">
            <el-option value="" label="全部"></el-option>
            <el-option value="1" label="积分增加"></el-option>
            <el-option value="2" label="积分减少"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-table
          :data="detailRows"
          style="width: 100%"
          :row-class-name="tableRowClassName">
        <el-table-column
            fixed
            label="ID"
            prop="id"
            width="70">
        </el-table-column>
        <el-table-column
            label="用户ID"
            width="200"
            prop="userId">
        </el-table-column>
        <el-table-column
            label="积分类型"
            width="120">
          <template slot-scope="{row}">
            <div v-if="row.incomeType == 1">增加积分</div>
            <div v-else>扣除积分</div>
          </template>
        </el-table-column>
        <el-table-column
            label="积分(点数)"
            width="80"
            prop="integral">
        </el-table-column>
        <el-table-column
            width="180"
            label="记录i时间">
          <template slot-scope="{row}">
            {{ `${row.createTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="变动原因(备注)"
            width="300"
            prop="desc">
        </el-table-column>

      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 30, 50, 100, 200]"
          :total="detailTotal"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="detailHandleSizeChange"
          @current-change="detailHandleCurrentChange">
      </el-pagination>
    </el-dialog>

  </div>
</template>
<script>

import sd from "silly-datetime";
import {
  getIntUserPageApi,adminEditIntegralApi,getIntUserIntegralDetailPageApi
} from "@/api/backend/xitunbaoApi";


export default {
  name: "XiTunBaoUser",
  data() {
    return {
      value2: [],
      rows: [],
      size: 10,
      current: 1,
      total: 0,
      loading: false,
      loadingMsg: "列表加载中...",
      params: {
        current: 1,
        size: 10,
        nickname: "",
        id: "",
        mobile: ""
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      userIntegralDto: {
        userId: "",
        incomeType: "",
        integral: "",
        desc: "",
        otherId: ""
      },
      EditUserIntegralDialog: false,
      UserIntegralDetailDialog: false,
      userIntegralDetail: {
        current: 1,
        size: 10,
        userId: "",
        incomeType: "",
      },
      detailRows: [],
      detailTotal: 0,
      totalIncreaseIntegral: "",
      totalDecreaseIntegral: "",

    }
  },
  filters: {

    formatDate(value) {
      return sd.format(+value)
    },
    cardTypeF(value) {
      switch (value) {
        case "1":
          return '电子卡'
        case "2":
          return '实体卡'
      }
    },
  },
  methods: {
    resetListParams() {
      
      this.params = {
        current: 1,
        size: 10,
        nickname: "",
        id: "",
        mobile: ""
      }
      this.getUserList();
    },
    async getUserList() {
      this.params.startCreateTime = ""
      this.params.endCreateTime = ""
      this.loading = true;
      this.loadingMsg = "列表加载中...";
      this.params.startCreateTime = this.value2.length > 0 ? `${+this.value2[0]}`.slice(0, 10) : ""
      this.params.endCreateTime = this.value2.length > 0 ? `${+this.value2[1]}`.slice(0, 10) : ""
      let {data} = await getIntUserPageApi(this.params);
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = data.total
      this.loading = false;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getUserList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getUserList();
    },

    /* 修改用户积分 */
    async adminEditIntegral(val,type) {
      //  类型为  1  打开窗口
      if (type === 1 ) {
        this.EditUserIntegralDialog = true;
        this.userIntegralDto.userId = val.id;
      }
      //  类型为2  提交修改数据
      if (type === 2) {
        let config = this.userIntegralDto
        if (this.checkEditUserIntegral(config)) {
          return;
        }
        await adminEditIntegralApi(this.userIntegralDto);
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.userIntegralDto = {
            userId: "",
            incomeType: "",
            integral: "",
            desc: "",
            otherId: ""
        };
        this.getUserList();
        this.EditUserIntegralDialog = false;
      }
    },
    //  检测非空
    checkEditUserIntegral(config) {
      if (!config.userId) {
        this.$message({
          message: '请选择用户',
          type: 'warning'
        });
        return true;
      }
      if (!config.integral) {
        this.$message({
          message: '请输入积分',
          type: 'warning'
        });
        return true;
      }
      if (!config.desc) {
        this.$message({
          message: '请输入积分变动原因',
          type: 'warning'
        })
        return true;
      }
      if (!config.incomeType) {
        this.$message({
          message: '请输入积分状态',
          type: 'warning'
        })
        return true;
      }
      return false;
    },
    /* 查询用户积分明细 */
    tableRowClassName({row, rowIndex}) {
      if (row.incomeType == 1) {
        return 'success-row';
      }
      return '';
    },
    detailHandleSizeChange(val) {
      this.userIntegralDetail.size = val;
      this.getUserIntegralDetail(this.userIntegralDetail.userId,null);
    },
    /**
     * 页码切换时触发
     * @param val
     */
    detailHandleCurrentChange(val) {
      this.userIntegralDetail.current = val;
      this.getUserIntegralDetail(this.userIntegralDetail.userId,null);
    },
    async getUserIntegralDetail(temp,type) {
      this.userIntegralDetail.userId = temp;
      if (type && type === 1) {
        this.UserIntegralDetailDialog = true;
      }
      let {data} = await getIntUserIntegralDetailPageApi(this.userIntegralDetail)
      this.detailRows = data.rows;
      this.detailTotal = data.total;
      this.totalDecreaseIntegral = data.totalDecreaseIntegral;
      this.totalIncreaseIntegral = data.totalIncreaseIntegral;
    }
  },
  created() {
    this.getUserList();
  }
}
</script>
<style>
.el-table .warning-row {
  background: palevioletred;
}

.el-table .success-row {
  background: green;
}
</style>