import service from "@/utils/requestBackend";


export function getSjGoodsListApi(data) {
    return service({
        url: '/sjGoods/getSjGoodsList',
        method: 'get',
        params: data
    })
}
export function getSjGoodsListCountApi(data) {
    return service({
        url: '/sjGoods/getSjGoodsCount',
        method: 'get',
        params: data
    })
}
export function getSjGoodsListBySpuIdApi(data) {
    return service({
        url: '/sjGoods/getSjGoodsListBySpuId',
        method: 'get',
        params: data
    })
}
//获取商品详情
export function getGoodsDetailById(data) {
    return service({
        url: '/sjGoods/detail',
        method: 'get',
        params: data
    })
}

//保存商品
export function saveSjGoodsApi(data) {
    return service({
        url: '/sjGoods',
        method: 'post',
        data
    })
}
//获取商品详情
export function getGoodsInfoApi(data) {
    return service({
        url: '/sjGoods/echo',
        method: 'get',
        params: data
    })
}
//导出文件
export function getExportSjGoodsListApi(data) {
    return service({
        url: '/sjGoods/getExportSjGoodsList',
        method: 'get',
        params: data,
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}
//上架小助手
export function getGoodsAssistantApi(data) {
    return service({
        url: '/sjGoods/goodsAssistant',
        method: 'post',
        data,
    })
}

export function updateSjGoodsPriceApi(data) {
    return service({
        url: '/sjGoods/updatePrice',
        method: 'put',
        data,
    })
}

export function updateSjGoodsSupplPriceApi(data) {
    return service({
        url: '/sjGoods/updateSupplPrice',
        method: 'put',
        data,
    })
}

export function updateIsQuanApi(data) {
    return service({
        url: '/sjGoods/updateIsQuan',
        method: 'put',
        data,
    })
}

export function updatePoolPriceApi(data) {
    return service({
        url: '/sjGoods/updatePoolPrice',
        method: 'put',
        data,
    })
}

export function updateSjGoodsSalePriceApi(data) {
    return service({
        url: '/sjGoods/updateSalePrice',
        method: 'put',
        data,
    })
}
//修改库存
export function updateStockApi(data) {
    return service({
        url: '/sjGoods/updateStock',
        method: 'put',
        data,
    })
}
// 更新状态
export function updateIsShowApi(data) {
    return service({
        url: '/sjGoods/updateBatchIsShow',
        method: 'put',
        data,
    })
}

export function updateBatchSevenNrrApi(data) {
    return service({
        url: '/sjGoods/updateBatchSevenNrr',
        method: 'put',
        data,
    })
}
export function updateBatchFreightApi(data) {
    return service({
        url: '/sjGoods/updateBatchFreight',
        method: 'put',
        data,
    })
}

export function selectionGoodsApi(data) {
    return service({
        url: '/sjGoods/pushAllByIsShow',
        method: 'post',
        data
    })
}

export function getReviewedSjGoodsCountApi(data) {
    return service({
        url: '/chooseGoodsManagement/getReviewedSjGoodsCount',
        method: 'get',
        params: data
    })
}

export function getReviewedSjGoodsListApi(data) {
    return service({
        url: '/chooseGoodsManagement/getReviewedSjGoodsList',
        method: 'get',
        params: data
    })
}

export function getReviewedPoolGoodsListApi(data) {
    return service({
        url: '/chooseGoodsManagement/getReviewedPoolGoodsList',
        method: 'get',
        params: data
    })
}

export function getReviewedPoolGoodsCountApi(data) {
    return service({
        url: '/chooseGoodsManagement/getReviewedPoolGoodsCount',
        method: 'get',
        params: data
    })
}

export function getStaffListApi() {
    return service({
        url: '/category/getStaffList',
        method: 'get'
    })
}

export function unSelectedGoodsApi(data) {
    return service({
        url: '/sjGoods/unSelectedGoods',
        method: 'get',
        params: data
    })
}

export function unSelectedPoolGoodsApi(data) {
    return service({
        url: '/sjGoods/unSelectedPoolGoods',
        method: 'get',
        params:data
    })
}

export function unSelectedPoolGoodsCountApi(data) {
    return service({
        url: '/sjGoods/unSelectedPoolGoodsCount',
        method: 'get',
        params: data
    })
}

export function updateGoodsInfoOfSecondaryOperatorsApi(data) {
    return service({
        url: '/sjGoods/updateSecondaryGoodsInfo',
        method: 'put',
        data
    })
}

export function editDisPriceApi(data) {
    return service({
        url: '/search/editDisPrice',
        method: 'post',
        data
    })
}

