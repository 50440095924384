<!-- 组件模板内容 -->
<template>
  <div>
    <div class="toolbar" style="background-color: #fff; padding: 0.5rem;border-radius: 15px">
      <div
        :id="`kt_toolbar_container`"
        class="container-fluid d-flex flex-stack"
        style="padding: 0 30px"
      >
        <div
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          :data-kt-swapper-parent="`{default: '#kt_content_container', 'lg': '#kt_toolbar_containe'}`"
          class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
        >
          <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" :style="`font-size:${titleSize} !important`">
            {{ title }}
          </h1>
          <span class="h-20px border-gray-200 border-start mx-4"></span>
          <slot name="subTitle"></slot>
          <!--<ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">-->
          <!--  &lt;!&ndash;<li class="breadcrumb-item text-muted">{{ subTitle }}</li>&ndash;&gt;-->
          <!--</ul>-->
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleCard",
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    title: {
      type: String,
      default: "标题卡片",
    },
    subTitle: {
      type: String,
    },
    titleSize:{
      type:String,
      default:"1.17rem"
    }
  },
  // 组件的data属性
  data() {
    return {
    };
  },
  // 组件的方法
  methods: {},
  // 在created钩子中执行的操作
  created() {},
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>