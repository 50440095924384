import sd from "silly-datetime";
import {
    createECard,
    deleteECard,
    deleteUserApi,
    editECard,
    getECardList,
    getECardOne,
    getUserList,
    updateUserApi,
    uploadComboECardUser,
    uploadECardUser
} from "@/api/backend/eCardApi";
import {addECardGoodsApi, deleteDelGoodsApi, getGoods} from "@/api/backend/eCardGoodsApi";
import {getCategoryList} from "@/api/backend/categoryApi";
import {getComboGiftPackListApi} from "@/api/backend/comboGiftPackApi";
import Key from "@/views/ECard/components/Key/index.vue";
import qrcode from "qrcode";

export const mixins = {
    name: "ECard",
    components: {
        Key
    },
    data() {
        /**
         * 验证分区数量
         * @param rule
         * @param value
         * @param callback
         * @returns {*}
         */
        var checkRegion = (rule, value, callback) => {
            if (value === "") {
                return callback(new Error('分区数量不能为空'));
            }
            if (!Number.isInteger(value)) {
                callback(new Error('请输入数字值'));
            } else {
                if (value < 0) {
                    callback(new Error('数量不能为负数'));
                } else {
                    callback();
                }
            }
        };
        return {
            price: {
                minPrice: "",
                maxPrice: ""
            },
            moreConditions: false,
            loading: true,
            listParams: {
                current: 1,
                size: 10,
                id: "",
                title: "",
                startCreateTime: "",
                endCreateTime: "",
                startEndTime: "",
                endEndTime: ""
            },
            qrCodeDialogVisible: false,
            // 表格数据
            tableData: [],
            // 时间选择器设置
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            pickerOptionsCreate: {
                disabledDate(time) {
                    return time.getTime() <= Date.now();
                }
            },
            // 时间选择器的值
            value2: [],
            value3: [],
            total: 0,
            // 创建卡券弹窗
            dialogFormCreateVisible: false,
            // 编辑卡券弹窗
            dialogFormEditVisible: false,
            // 修改分区可选数量弹窗
            dialogEditNumVisible: false,
            dialogEditGoodsVisible: false,
            // 导入用户弹窗
            dialogImportUserVisible: false,
            // 用户管理弹窗
            dialogEditUserVisible: false,
            // 修改用户分区可选数量弹窗
            dialogEditUserRegionVisible: false,
            visible: false,
            importUserValue: 1,
            importUserOptions: [{
                value: 1,
                label: "导入首页用户"
            }, {
                value: 2,
                label: "导入组合礼包用户"
            }],
            // 创建卡券表单
            createForm: {
                title: "",
                endTime: ""
            },
            // 编辑卡券表单
            editForm: {
                id: "",
                title: "",
                endTime: ""
            },
            // 创建卡券表单验证规则
            rules: {
                title: [
                    {required: true, message: '请输入卡券名称', trigger: 'blur'}
                ],
                endTime: [
                    {required: true, message: '请选择卡券结束日期', trigger: 'change'}
                ]
            },
            rulesNum: {
                regionOneNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionTwoNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionThreeNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionFourNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionFiveNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
            },
            editECardUserFormRules: {
                userName: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                ],
                regionOneNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionTwoNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionThreeNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionFourNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
                regionFiveNum: [
                    {required: true, validator: checkRegion, trigger: 'change'},
                ],
            },
            // 修改分区可选数量表单
            editNumForm: {
                id: "",
                regionOneNum: 0,
                regionTwoNum: 0,
                regionThreeNum: 0,
                regionFourNum: 0,
                regionFiveNum: 0,
            },
            // 挑选商品到卡卷的组合礼包列表
            tableDataPack: [],
            goodList: [],
            totalGoodList: [],
            eCardId: "",
            paramss: {
                isTwoShow: 1,
                telNum: "",
                isShow: 1,
                title: "",
                type: "1",
                categoryId1: "",
                categoryId2: "",
                size: 10,
                current: 1,
                isJd: 2,
                lowestBuy: 1
            },
            priceValue: "",
            priceType: [
                {
                    type: 1,
                    name: "展示价"
                },
                {
                    type: 2,
                    name: "市场价"
                },
                {
                    type: 3,
                    name: "分销价"
                },
                {
                    type: 4,
                    name: "销售价"
                }
            ],
            pageParams: {
                size: 10,
                current: 1,
                isShow: '1',
                name: '',
                giftCode: '',
                minGiftShowPrice: '', // 展示价
                maxGiftShowPrice: '',
                minGiftPrice: '',  // 市场价
                maxGiftPrice: '',
                minGiftSupplyPrice: '',  // 供应价
                maxGiftSupplyPrice: '',
                minGiftSalePrice: '',  // 销售价
                maxGiftSalePrice: '',
                minGiftTotalGoods: '',
                maxGiftTotalGoods: '',
                maxGiftRate: '',
                minGiftRate: '',
            },
            addECardGoodsObj: {
                cardId: "",
                goodsId: "",
                region: ""
            },
            totalCount1: 0,
            item: {},
            qrCodeImag: "",
            tableUserData: [],
            userListParams: {
                current: 1,
                size: 10,
                cardId: "",
                tel: ""
            },
            userListTotal: 10,
            editECardUserForm: {
                id: "",
                cardId: "",
                userName: "",
                regionOneNum: 0,
                regionTwoNum: 0,
                regionThreeNum: 0,
                regionFourNum: 0,
                regionFiveNum: 0
            },
            goodsPageParams: {
                size: 10,
                current: 1,
                cardId: this.eCardId,
                name: ""
            },
            goodsTotalCount: 0

        }
    },
    methods: {
        // 搜索
        search() {
            // 展示价
            if (this.priceValue === 1) {
                this.pageParams.minGiftShowPrice = this.price.minPrice;
                this.pageParams.maxGiftShowPrice = this.price.maxPrice;
            } else if (this.priceValue === 2) {
                //  市场价
                this.pageParams.minGiftPrice = this.price.minPrice;
                this.pageParams.maxGiftPrice = this.price.maxPrice;
            } else if (this.priceValue === 3) {
                this.pageParams.minGiftSupplyPrice = this.price.minPrice;
                this.pageParams.maxGiftSupplyPrice = this.price.maxPrice;
            } else if (this.priceValue === 4) {
                this.pageParams.minGiftSalePrice = this.price.minPrice;
                this.pageParams.maxGiftSalePrice = this.price.maxPrice;
            }
            this.getComboGiftPackList();
        },
        // 重置
        reset() {
            this.pageParams = {
                size: 10,
                current: 1,
                isShow: '1',
                name: '',
                giftCode: '',
                minGiftShowPrice: '', // 展示价
                maxGiftShowPrice: '',
                minGiftPrice: '',  // 市场价
                maxGiftPrice: '',
                minGiftSupplyPrice: '',  // 供应价
                maxGiftSupplyPrice: '',
                minGiftSalePrice: '',  // 销售价
                maxGiftSalePrice: '',
                minGiftTotalGoods: '',
                maxGiftTotalGoods: '',
                maxGiftRate: '',
                minGiftRate: '',
            }
        },
        // 获取卡券列表
        async getECardList() {
            this.listParams.startCreateTime = "";
            this.listParams.endCreateTime = "";
            this.listParams.startEndTime = "";
            this.listParams.endEndTime = "";
            if (this.value2 != null && this.value2.length > 0) {
                this.listParams.startCreateTime = sd.format(this.value2[0])
                this.listParams.endCreateTime = sd.format(this.value2[1])
            }
            if (this.value3 != null && this.value3.length > 0) {
                this.listParams.startEndTime = sd.format(this.value3[0])
                this.listParams.endEndTime = sd.format(this.value3[1])
            }
            let {data} = await getECardList(this.listParams)
            this.total = data.total
            this.tableData = data.rows
            this.loading = false;
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.listParams.size = val;
            this.getECardList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.listParams.current = val;
            this.getECardList();
        },
        handleSizeChangeUserList(val) {
            this.userListParams.size = val;
            this.getUserList();
        },
        handleCurrentChangeUserList(val) {
            this.userListParams.current = val;
            this.getUserList();
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChangeGoods(val) {
            this.pageParams.size = val;
            this.getComboGiftPackList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChangeGoods(val) {
            this.pageParams.current = val;
            this.getComboGiftPackList();
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        goodsHandleSizeChangeGoods(val) {
            this.goodsPageParams.size = val;
            this.getSelGoods();
            this.getAllSelGoods();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        goodsHandleCurrentChangeGoods(val) {
            this.goodsPageParams.current = val;
            this.getSelGoods();
            this.getAllSelGoods();
        },
        /**
         *  重置列表查询参数
         */
        resetListParams() {
            let temp = JSON.parse(JSON.stringify(this.listParams))
            this.listParams = {
                current: temp.current,
                size: temp.size,
                id: "",
                title: "",
                startCreateTime: "",
                endCreateTime: "",
                startEndTime: "",
                endEndTime: ""
            };
            this.value2 = [];
            this.value3 = [];
            this.getECardList();
        },
        /**
         * 打开创建卡券弹窗
         */
        openCreate() {
            this.clearCreateFrom();
            this.dialogFormCreateVisible = true;
        },
        /**
         * 清空创建表单
         */
        clearCreateFrom() {
            this.createForm = {
                title: "",
                endTime: ""
            }
        },
        /**
         * 提交创建卡券表单
         */
        submitCreateForm() {
            this.$refs['ruleFormCreate'].validate((valid) => {
                if (valid) {
                    this.createECard();
                } else {
                    return false;
                }
            });
        },
        /**
         * 创建卡券请求
         * @returns {Promise<void>}
         */
        async createECard() {
            await createECard(this.createForm);
            this.$message({
                message: '创建成功',
                type: 'success'
            });
            this.getECardList();
            this.dialogFormCreateVisible = false;
        },
        /**
         * 提交编辑卡券表单
         */
        submitEditForm() {
            this.$refs['ruleFormEdit'].validate((valid) => {
                if (valid) {
                    this.editECard();
                } else {
                    return false;
                }
            });
        },
        /**
         * 打开编辑卡券弹窗
         * @param data
         */
        openEdit(data) {
            let {id, title, endTime} = data
            this.editForm.id = id
            this.editForm.title = title
            this.editForm.endTime = endTime
            this.dialogFormEditVisible = true;
        },
        /**
         * 编辑卡券请求
         *
         * @returns {Promise<void>}
         */
        async editECard() {
            await editECard(this.editForm);
            this.$message({
                message: '编辑成功',
                type: 'success'
            });
            this.getECardList();
            this.dialogFormEditVisible = false;
        },
        /**
         * 删除卡券
         *
         * @param data
         */
        deleteCard(data) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteFoCard(data.id)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**
         * 删除卡券请求
         *
         * @param id
         * @returns {Promise<void>}
         */
        async deleteFoCard(id) {
            await deleteECard({id: id});
            this.$message({
                message: '删除成功',
                type: 'success'
            });
            this.getECardList();
        },
        /**
         * 打开导入用户弹窗
         * @param data
         */
        openImportUser(data) {
            this.eCardId = data.id;
            this.dialogImportUserVisible = true;
        },
        /**
         *  提交编辑卡券数量
         * @param data
         */
        submitEditNum() {
            this.$refs['ruleEditNum'].validate((valid) => {
                if (valid) {
                    this.editNumECard();
                } else {
                    return false;
                }
            });
        },
        /**
         * 编辑卡券数量请求
         *
         * @returns {Promise<void>}
         */
        async editNumECard() {
            await editECard(this.editNumForm);
            this.$message({
                message: '编辑成功',
                type: 'success'
            });
            this.dialogEditNumVisible = false;
        },
        /**
         * 获取卡券信息
         * @param aaa
         */
        async getCardById(aaa) {
            this.editNumForm.id = aaa.id
            let {data} = await getECardOne({id: this.editNumForm.id})
            this.editNumForm.regionOneNum = data.regionOneNum
            this.editNumForm.regionTwoNum = data.regionTwoNum
            this.editNumForm.regionThreeNum = data.regionThreeNum
            this.editNumForm.regionFourNum = data.regionFourNum
            this.editNumForm.regionFiveNum = data.regionFiveNum
            this.dialogEditNumVisible = true
        },
        openEditGoods(data) {
            this.eCardId = data.id;
            this.dialogEditGoodsVisible = true
            this.getComboGiftPackList()
            this.initCategory();
            this.getSelGoods()
            this.getAllSelGoods()
        },
        /**
         * 获取已选商品
         */
        async getSelGoods() {
            this.goodsPageParams.cardId = this.eCardId
            let {data} = await getGoods(this.goodsPageParams)
            // let {data} = await getGoods({cardId: this.eCardId, size: 99999999})
            this.goodList = data.rows
            this.goodsTotalCount = data.total;

        },
        /**
         * 获取所有已选商品 用于列表字段判断是否添加成功
         */
        async getAllSelGoods() {
            this.goodsPageParams.cardId = this.eCardId
            // let {data} = await getGoods(this.goodsPageParams)
            let {data} = await getGoods({cardId: this.eCardId, size: 99999999})
            this.totalGoodList = data.rows
        },
        async initCategory() {
            let {data} = await getCategoryList({
                parentId: 0
            })
            this.categoryId1 = data.list
        },
        async getComboGiftPackList() {
            this.loading = true;
            let {data} = await getComboGiftPackListApi(this.pageParams);
            this.loading = false;
            this.tableDataPack = data.rows;
            this.totalCount1 = data.total;
        },
        async addGoods(item, region) {
            this.addECardGoodsObj.cardId = this.eCardId;
            this.addECardGoodsObj.goodsId = item.id;
            this.addECardGoodsObj.region = region;

            await addECardGoodsApi(this.addECardGoodsObj);
            this.getSelGoods();
            this.getAllSelGoods();
        },

        openRegion(item) {
            this.$prompt('请输入分区', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^[1-5]$/,
                inputErrorMessage: '分区不正确，请填写1~5'
            }).then(({value}) => {
                this.addGoods(item, value)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        async clearSelectedGoods() {
            let {data} = await deleteDelGoodsApi({ids: this.goodsIds.join(",")})
            this.getSelGoods()
            this.getAllSelGoods()
        },
        async delGroupGoods(item) {
            let {data} = await deleteDelGoodsApi(
                {
                    ids: item.id
                }
            )
            this.getSelGoods()
            this.getAllSelGoods()
        },
        /**
         * 打开卡密窗口
         */
        generateKey(item) {
            this.item = item;
            this.$refs.key.initList(item);
        },
        /**
         * 扫码登录
         */
        qrCode(item) {
            this.qrCodeImag = ""
            this.$nextTick(async () => {
                this.qrCodeDialogVisible = true;
                let url = "https://eacrd.1c-1g.com/?cardId=" + item.id;
                // qrcode.toDataURL(url, (err, url) => {
                //     if (!err) {
                //         this.qrCodeImag = url;
                //     }
                // });
                try {
                    const canvas = this.$refs.canvass;
                    const ctx = canvas.getContext('2d');

                    const qrCodeOptions = {
                        errorCorrectionLevel: 'H',
                        type: 'image/png',
                        quality: 1,
                        margin: 1,
                        color: {
                            dark: '#000000ff',
                            light: '#ffffffff'
                        }
                    };

                    const qrCodeDataUrl = await qrcode.toDataURL(url, qrCodeOptions);
                    const qrCodeImage = new Image();
                    qrCodeImage.src = qrCodeDataUrl;

                    const logoImage = new Image();
                    logoImage.src = './images/logo.png'; // 替换成你的Logo图片路径

                    qrCodeImage.onload = () => {
                        canvas.width = qrCodeImage.width;
                        canvas.height = qrCodeImage.height;

                        ctx.drawImage(qrCodeImage, 0, 0, qrCodeImage.width, qrCodeImage.height);
                        const logoSize = qrCodeImage.width * 0.3; // 调整Logo大小
                        const logoPositionX = (qrCodeImage.width - logoSize) / 2;
                        const logoPositionY = (qrCodeImage.height - logoSize) / 2;

                        logoImage.onload = () => {
                            ctx.drawImage(logoImage, logoPositionX, logoPositionY, logoSize, logoSize);
                            this.qrCodeImag = canvas.toDataURL();
                        };
                    };
                } catch (error) {
                    console.error('Error generating QR code with logo:', error);
                }
            });
        },
        async uploadFile() {
            let file = this.$refs.file.files[0];
            if (!file) {
                return;
            }
            // 导入用户
            if (this.importUserValue === 1) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('cardId', this.eCardId);
                let data = await uploadECardUser(formData);
                if (data.code == 200) {
                    this.$message({
                        message: '导入成功',
                        type: 'success'
                    });
                    this.dialogImportUserVisible = false
                } else {
                    this.$message({
                        message: '导入失败！' + data.msg,
                        type: 'error'
                    });
                }
                return;
            }
            //  导入组合礼包用户
            let formData = new FormData();
            formData.append('file', file);
            formData.append('cardId', this.eCardId);
            let data = await uploadComboECardUser(formData);
            if (data.code == 200) {
                this.$message({
                    message: '导入成功',
                    type: 'success'
                });
                this.dialogImportUserVisible = false
            } else {
                this.$message({
                    message: '导入失败！' + data.msg,
                    type: 'error'
                });
            }


        },
        openEditUser(data) {
            this.dialogEditUserVisible = true
            this.userListParams.cardId = data.id
            this.getUserList();
        },
        async getUserList() {
            let {data} = await getUserList(this.userListParams);
            this.userListTotal = data.total;
            this.tableUserData = data.rows;
        },

        async deleteUser(item) {
            await deleteUserApi({
                "cardId": item.cardId,
                "id": item.id
            });
            await this.getUserList();
        },
        openUserRegion(item) {
            this.dialogEditUserRegionVisible = true;
            this.editECardUserForm.id = item.id
            this.editECardUserForm.cardId = item.cardId
            this.editECardUserForm.userName = item.userName
            this.editECardUserForm.regionOneNum = item.regionOneNum
            this.editECardUserForm.regionTwoNum = item.regionTwoNum
            this.editECardUserForm.regionThreeNum = item.regionThreeNum
            this.editECardUserForm.regionFourNum = item.regionFourNum
            this.editECardUserForm.regionFiveNum = item.regionFiveNum
        },
        submitEditECardUserForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.updateECardUser();
                } else {
                    return false;
                }
            });
        },

        async updateECardUser() {
            await updateUserApi(this.editECardUserForm);
            this.dialogEditUserRegionVisible = false;
            await this.getUserList()
        }

    },
    created() {
        this.getECardList();

    },
    //监听属性 类似于data概念
    computed: {
        goodsIds() {
            // return this.goodList.map(item => item.id);
            return this.totalGoodList.map(item => item.id);
        },
        idsArr() {
            // return this.goodList.map(item => item.goodsId);
            return this.totalGoodList.map(item => item.goodsId);
        },
    }

}