// utils/ObjectUtil.js

export default {
    removeEmptyValues(obj) {
        for (const key in obj) {
            if (obj[key] == null || obj[key] === '' || (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)) {
                delete obj[key];
            }
        }
    }
}
