<template>
  <div style="padding-top: 10px">
    <title-card style="max-width: 97%; margin: 10px auto" title="喜豚宝消费卡">
      <div style="position: absolute; right: 70px">
      </div>
      <template v-slot:subTitle>

      </template>
    </title-card>
    <div v-loading="loading"
         :element-loading-text="loadingMsg"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px"
    >
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="params.remark" placeholder="请输入需要查询的消费卡备注"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.cardId" placeholder="请选择面值" size="mini" @change="getCardList">
            <el-option v-for="(item,index) in cardNameList" :key="index" :label="item.cardName"
                       :value="`${item.id}`"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.cardType" placeholder="请选择类型" size="mini" @change="getCardList">
            <el-option label="电子卡" value="1"></el-option>
            <el-option label="实体卡" value="2"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value2"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束结束日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始生成日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="getCardList">搜索</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="resetListParams">重置</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="exportCardList">导出</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="createDialog=true">卡密生成</el-button>
        </div>
      </div>
      <el-table
          :data="rows"
          style="width: 100%">
        <el-table-column
            fixed
            label="ID"
            prop="id"
            width="50">
        </el-table-column>
        <el-table-column
            label="卡批次"
            width="200"
            prop="mdlOrder">
        </el-table-column>
        <el-table-column
            label="卡类型"
            width="120">
          <template slot-scope="{row}">
            {{ row.cardType | cardTypeF }}
          </template>
        </el-table-column>
        <el-table-column
            label="卡面值"
            prop="cardName"
            width="150">
        </el-table-column>
        <el-table-column
            label="数量"
            width="80"
            prop="num">
        </el-table-column>
        <el-table-column
            label="批次总面值"
            width="180">
          <template slot-scope="{row}">
            {{ row.num * row.cardFaceValue }}
          </template>
        </el-table-column>
        <el-table-column
            label="备注"
            prop="remark">
        </el-table-column>
        <el-table-column
            width="180"
            label="生成时间">
          <template slot-scope="{row}">
            {{ `${row.createTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="500px">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="getXiTunBaoCardDetail(row)">查看卡密</el-button>
            <el-button size="mini" type="info" @click="editRemark(row)">修改备注</el-button>
            <el-button size="mini" type="danger" @click="deleteCard(row)">删除卡</el-button>
            <el-button size="mini" class="badge-success" @click="cardStatus = true; cardData = row">修改状态</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="96%"
        @opened="$refs.KeyList.initList(item)"
        top="50px"
    >
      <KeyList ref="KeyList"></KeyList>
    </el-dialog>
    <el-dialog
        :visible.sync="createDialog"
        title="生成卡密"
        top="50px"
        width="30%"
        @opened="initCreateDialog">
      <el-form ref="form" :model="createParams" :rules="rules" label-width="80px">
        <el-form-item label="备注" prop="remark">
          <el-input v-model="createParams.remark" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="卡号前缀" prop="cardNoPrefix">
          <el-input v-model="createParams.cardNoPrefix" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="卡号起始" prop="startCardNum">
          <el-input v-model="createParams.startCardNum" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input v-model="createParams.num" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="卡ID" prop="cardId">
          <el-select v-model="createParams.cardId" placeholder="请选择卡ID" size="mini">
            <el-option v-for="(item,index) in cardNameList" :key="index" :label="item.cardName"
                       :value="`${item.id}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡类型" prop="cardType">
          <el-select v-model="createParams.cardType" placeholder="请选择卡类型" size="mini">
            <el-option label="电子卡" value="1"></el-option>
            <el-option label="实体卡" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="createParams.cardType==1" label="手机号" prop="phone">
          <div style="display: flex">
            <div style="width: 40%">
              <el-input v-model="createParams.phone" size="mini"></el-input>
            </div>
            <div style="width: 40%;margin-left: 10px">
              <el-button size="mini" type="primary" @click="getPhoneByUser">获取用户信息</el-button>
            </div>
          </div>
        </el-form-item>
        <template v-if="createParams.cardType==1">
          <el-form-item label="用户ID" prop="userId">
            <el-input v-model="userId" :disabled="true" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="userName">
            <el-input v-model="userName" :disabled="true" size="mini"></el-input>
          </el-form-item>
        </template>

        <el-form-item>
          <el-button size="mini" type="primary" @click="xiTunBaoCardItemCreate">立即生成</el-button>
          <el-button size="mini" @click="createDialog=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="cardStatus" title="修改卡片状态" top="50px" width="30%">
      <el-button size="mini" type="danger" @click="confirmEditCardStatus(-1)">全部禁用</el-button>
      <el-button size="mini" type="success" @click="confirmEditCardStatus(0)">全部开通</el-button>
      <el-button size="mini" type="primary" @click="confirmEditCardStatus(1)">全部已发货</el-button>
      <el-button size="mini" type="warning" @click="confirmEditCardStatus(4)">全部已退款</el-button>
    </el-dialog>
  </div>
</template>
<script>

import sd from "silly-datetime";
import {
  deleteXiTunBaoCardItemCreateApi,
  editRemarkApi,
  exportXiTunBaoCardListApi,
  getPhoneByUserApi,
  getXiTunBaoCardListApi,
  getXiTunBaoCardNameListApi,
  xiTunBaoCardItemCreateApi,
  editCardStatusApi
} from "@/api/backend/xitunbaoApi";
import KeyList from "@/views/XiTunBaoCard/components/keyList.vue";

export default {
  name: "Order",
  components: {KeyList},
  data() {
    var checkUserId = (rule, value, callback) => {
      if (this.createParams.cardType == 1) {
        if (!this.userId) {
          return callback(new Error('用户ID不能为空'));
        }
      }
      callback();
    };
    return {
      cardData: {},
      cardStatus: false,
      rows: [],
      size: 10,
      current: 1,
      total: 0,
      loading: false,
      loadingMsg: "列表加载中...",
      rules: {
        remark: [
          {required: true, message: '请输入卡备注', trigger: 'blur'},
        ],
        cardNoPrefix: [
          {required: true, message: '请输入卡号前缀', trigger: 'blur'}
        ],
        startCardNum: [
          {required: true, message: '请请输入卡号起始', trigger: 'blur'}
        ],
        num: [
          {required: true, message: '请输入生成数量', trigger: 'blur'}
        ],
        cardId: [
          {required: true, message: '请选择卡ID', trigger: 'blur'}
        ],
        cardType: [
          {required: true, message: '请选择卡类型', trigger: 'blur'}
        ],
        userId: [
          {validator: checkUserId, trigger: 'blur'}
        ]
      },
      params: {
        current: 1,
        size: 10,
        remark: "",
        cardId: "",
        cardType: "",

      },
      cardNameList: [],
      value2: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dialogTitle: "查看卡密",
      dialogVisible: false,
      item: {},
      createDialog: false,
      createParams: {
        num: "",
        cardId: "",
        cardType: "",
        remark: "",
        startCardNum: "",
        cardNoPrefix: "",
        userId: ""
      },
      phone: "",
      userId: "",
      userName: ""
    }
  },
  filters: {

    formatDate(value) {
      return sd.format(+value)
    },
    cardTypeF(value) {
      switch (value) {
        case "1":
          return '电子卡'
        case "2":
          return '实体卡'
      }
    },
  },
  methods: {
    async getPhoneByUser() {
      let {data} = await getPhoneByUserApi({
        phone: this.createParams.phone
      })
      this.$message({
        message: '获取成功',
        type: 'success'
      });
      this.userId = data.id;
      this.userName = data.nickname;
    },
    deleteCard(item) {

      this.$confirm('此操作将永久删除该批次及卡密，是否确认继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteXiTunBaoCardItemCreateApi(item)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getCardList();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    initCreateDialog() {
      this.createParams = {
        num: "",
        cardId: "",
        cardType: "",
        remark: "",
        startCardNum: "",
        cardNoPrefix: ""
      }
      this.phone = ""
      this.userId = ""
      this.userName = ""
    },
    async xiTunBaoCardItemCreate() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.createParams.userId = this.userId;
          await xiTunBaoCardItemCreateApi(this.createParams)
          this.createDialog = false
          this.getCardList();
        } else {
          return false;
        }
      });

    },
    async exportCardList() {
      this.loadingMsg = "文件导出中..."
      this.loading = true;
      const response = await exportXiTunBaoCardListApi(this.params)
      const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sd.format(new Date())}.xlsx`; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.$message({
        message: '文件下载成功',
        type: 'success'
      });
      this.loading = false;
    },
    editRemark(item) {
      this.$prompt('请输入修改的备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.remark
      }).then(async ({value}) => {
        await editRemarkApi({
          id: item.id,
          remark: value
        })
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        await this.getCardList();
      }).catch(() => {

      });
    },
    getXiTunBaoCardDetail(item) {
      this.dialogVisible = true;
      this.dialogTitle = item.remark;
      this.item = item;
      //this.$refs.KeyList.initList(item);
    },
    resetListParams() {
      this.value2 = []
      this.params = {
        current: 1,
        size: 10,
        remark: "",
        cardType: "",
        startCreateTime: "",
        endCreateTime: ""
      }
      this.getCardList();
    },
    async getCardList() {
      this.params.startCreateTime = ""
      this.params.endCreateTime = ""
      this.loading = true;
      this.loadingMsg = "列表加载中...";
      this.params.startCreateTime = this.value2.length > 0 ? `${+this.value2[0]}`.slice(0, 10) : ""
      this.params.endCreateTime = this.value2.length > 0 ? `${+this.value2[1]}`.slice(0, 10) : ""
      let {data} = await getXiTunBaoCardListApi(this.params);
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = data.total
      this.loading = false;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getCardList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getCardList();
    },
    async initCardNameList() {
      let {data} = await getXiTunBaoCardNameListApi();
      this.cardNameList = data
    },
    // 点击按钮时触发的方法，用于确认操作
    confirmEditCardStatus(status) {
      // 显示确认弹窗
      this.cardStatus = true;
      // 通过 MessageBox.confirm 方法显示二次确认对话框
      this.$confirm('确定要执行此操作吗？', '提示', {
        confirmButtonText: '确定', // 确定按钮的文本
        cancelButtonText: '取消', // 取消按钮的文本
        type: 'warning' // 消息框的类型，警告类型
      }).then(() => {
        // 用户点击了确定按钮，执行操作
        this.editCardStatus(this.cardData, status);
      }).catch(() => {
        // 用户点击了取消按钮，关闭弹窗
        this.cardStatus = false;
      });
      this.cardStatus = false;
    },
    /**
     * 修改卡的状态
     */
    async editCardStatus(item, status) {
      let card = {
        ...item,
        parentId: item.mdlOrder,
        status: status
      }
      await editCardStatusApi(card);
      this.getCardList();
      this.initCardNameList();
    }
  },
  created() {
    this.getCardList();
    this.initCardNameList();
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>