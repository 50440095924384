<!-- 组件模板内容 -->
<template>
  <div>
    <el-upload
      class="upload-demo"
      drag
      action="string"
      :http-request="handleChange"
      :file-list="fileList"
      :disabled="disabled"
      ref="upload"
      multiple
      :auto-upload="true"
      :accept="accept"
      :show-file-list="hidenList"
    >
    <!-- :limit="limit" -->
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击选取文件</em></div>
      <div class="el-upload__tip" slot="tip">
        只能上传{{ accept }}文件，且不超过{{ fileSize }}MB
      </div>
    </el-upload>
  </div>
</template>

<script>
import { uploadFileApi } from "@/api/backend/uploadFileApi";
export default {
  name: "UploadFile",
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    accept: {
      type: String,
      default: ".mp4,.mkv,.png,.jpg,.jpge",
    },
    fileSize: {
      type: String,
      default: "2",
    },
    moduleName: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 8,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hidenList:{
      type:Boolean,
      default:true
    }
  },
  // 组件的data属性
  data() {
    return {
      fileList: [],
    };
  },
  // 组件的方法
  methods: {
    async handleChange(param) {
      const data = new FormData();
      if (param) {
        data.append("file", param.file);
        data.append("moduleName", this.moduleName);
        if (param.file.size >= this.fileSize * 1024 * 1024) {
          this.$message({
            message: "文件过大,最大" + this.fileSize + "MB",
            type: "warning",
          });
          return;
        }
      }
      let res = await uploadFileApi(data);

      this.$message({
        message: "上传成功!",
        type: "success",
          });
      this.$emit("upload-success", res,this.fileList);
    },
  },
  // 在created钩子中执行的操作
  created() {},
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>