import service from "@/utils/requestBackend";


/**
 * 下载商品数据api
 */
export function downloadDataByPoolApi(data) {
    return service({
        url: '/download/pool',
        method: 'post',
        data
    })
}


/**
 * 修改商品池数据api
 */
export function updatePoolByPoolIdApi(data) {
    return service({
        url: '/jdPool/updatePoolDownload',
        method: 'post',
        data
    })
}


/**
 * 获取地址
 */
export function getJdCommodityPoolListApi(data) {
    return service({
        url: "/jdPool/poolPage",
        method: "get",
        params: data
    })
}

