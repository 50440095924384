import service from "@/utils/requestBackend";

/**
 * 获取订单列表
 */
export function getOrderListApi(params) {
    return service({
        url: "/order/getOrderList",
        method: "get",
        params
    });
}

/**
 * 获取订单物流
 */
export function getBackendDeliveryByJdOrderIdApi(params) {
    return service({
        url: "/order/getDeliveryByJdOrderId",
        method: "get",
        params
    });
}

/**
 * 导出订单接口
 * @param params
 * @returns {*}
 */
export function exportOrderApi(params) {
    return service({
        url: "/order/exportOrder",
        method: "get",
        params,
        responseType: 'blob',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}