<template>
  <div style="padding-top: 10px">
    <div style="max-width: 97%; background: #fff;border-radius: 14px;min-height: 87vh;">
      <div v-show="moreConditions">
        <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;align-items: center;">
          <div style="margin-left: 10px;margin-bottom: 10px">
            卡券编号：
            <el-input v-model="params.cardId" placeholder="请输入需要查询的卡券编号"
                      size="mini" style="width: 200px"></el-input>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            订单号(子)：
            <el-input v-model="params.orderId" placeholder="请输入需要查询的订单号"
                      size="mini" style="width: 200px"></el-input>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            礼包名称：
            <el-input v-model="params.comboName" placeholder="请输入需要查询的卡包名称"
                      size="mini" style="width: 200px"></el-input>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            商品名称：
            <el-input v-model="params.goodsName" placeholder="请输入需要查询的卡商品名称"
                      size="mini" style="width: 200px"></el-input>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            手机号：
            <el-input v-model="params.phone" placeholder="请输入需要查询的手机号"
                      size="mini" style="width: 200px"></el-input>
          </div>

          <div style="margin-left: 10px;margin-bottom: 10px">
            <el-button size="medium" type="primary" @click="getEcardAfterSaleList">搜索</el-button>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px;">
            <el-button size="medium" @click="resetListParams">重置</el-button>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            售后类型：
            <el-select v-model.number="params.type" clearable placeholder="请选择售后类型" size="mini">
              <el-option :value="1" label="卡券售后"></el-option>
            </el-select>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            售后状态：
            <el-select v-model="params.status" multiple clearable placeholder="请选择售后状态" size="mini">
              <el-option :value="1" label="正在售后"></el-option>
              <el-option :value="2" label="售后成功"></el-option>
              <el-option :value="3" label="取消售后"></el-option>
            </el-select>
          </div>
          <div style="margin-left: 10px;margin-bottom: 10px">
            日期：
            <el-date-picker
                v-model="value2"
                :picker-options="pickerOptions"
                align="right"
                end-placeholder="结束下单日期"
                range-separator="至"
                size="mini"
                start-placeholder="开始下单日期"
                type="datetimerange">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button type="primary" plain @click="moreConditions = !moreConditions" size="small">搜索条件
            <i v-if="moreConditions" class="el-icon-arrow-up"></i>
            <i v-if="!moreConditions" class="el-icon-arrow-down"></i>
          </el-button>
        </div>
        <!--        <div style="margin-left: 10px;margin-bottom: 10px">
                  <el-button type="success" size="small" @click="exportOrder">导出订单数据</el-button>
                </div>-->
      </div>
      <el-table
          :data="rows"
          style="width: 100%">
        <el-table-column
            :align="'center'"
            fixed
            label="卡券编号"
            prop="cardId"
            width="200px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            fixed
            label="礼包名称"
            prop="comboName"
            width="150px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            fixed
            label="商品名称"
            prop="goodsName"
            width="200px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            fixed
            label="JD订单号(子)"
            prop="orderId"
            width="120">
        </el-table-column>
        <el-table-column
            fixed
            label="售后状态"
        >
          <template slot-scope="scope">
            <span :style="{color: scope.row.state === 3 ? 'red' :
                     scope.row.state === 2 ? 'green' :
                     scope.row.state === 1 ? 'blue' :
                     'black'}">
              {{ scope.row.state === 3 ? '取消售后' :
                 scope.row.state === 2 ? '售后成功' :
                 scope.row.state === 1 ? '正在售后' :
                     '失败'
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            fixed
            label="售后类型">
          <template slot-scope="scope">
            {{
              scope.row.type == 1 ? '卡券售后' : '其他售后'
            }}
          </template>
        </el-table-column>
        <el-table-column
            fixed
            prop="applyReason"
            label="售后原因">
        </el-table-column>
        <el-table-column
            fixed
            prop="speciReason"
            label="具体原因"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            label="申请时间"
            prop="createTime"
            width="160">
        </el-table-column>
        <el-table-column
            align="center"
            label="收货人"
            prop="realName"
            width="80"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            label="收货手机号"
            prop="phone"
            width="120">
        </el-table-column>
        <el-table-column
            label="收货地址" prop="address"
            width="300" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="{row}">
            <el-dropdown @command="handleCommand">
<!--            <el-dropdown @command="handleCommand">-->
              <span class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="row">修改售后状态</el-dropdown-item>
                <el-dropdown-item divided command="detail">查看售后详情</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <el-dialog
        :visible.sync="afterSaleStatusDialog"
        title="售后状态"
        width="30%">
      <el-button @click="updateAfterSaleInfo(2)">售后成功</el-button>
      <el-button @click="updateAfterSaleInfo(3)">取消售后</el-button>
      <el-button @click="afterSaleStatusDialog = false">关 闭</el-button>
    </el-dialog>

<!--    查看京东vop 售后信息-->
    <el-dialog
        :visible.sync="detailDialog"
        title="物流"
        width="30%">
      <el-timeline :reverse="true">
        <el-timeline-item
            v-for="(activity, index) in detailList"
            :key="index"
            :timestamp="activity.trackMsgTime | parseTime">
          {{ activity.trackContent }}
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import sd from "silly-datetime";
import {getEcardAfterSaleListApi,updateEcardAfterSaleInfoApi} from "@/api/backend/afterSale";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Ecard_After_Sale",
  data() {
    return {
      afterInfo: {},
      afterSaleStatusDialog: false,
      // 更多查询
      moreConditions: false,
      rows: [],
      size: 10,
      current: 1,
      total: 0,
      loading: false,
      params: {
        current: 1,
        size: 10,
        status: "",
        type: "",
        cardId: "",
        orderId: "",
        comboName: "",
        goodsName: "",
        phone: "",
        name: "",
        startCreateTime: "",
        endCreateTime: ""
      },
      detailList: [],
      detailDialog: false,
      value2: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  methods: {
    async getEcardAfterSaleList() {
      if (this.value2.length > 0) {
        this.params.startCreateTime = sd.format(this.value2[0])
        this.params.endCreateTime = sd.format(this.value2[1])
      }
      let {data} = await getEcardAfterSaleListApi(this.params)
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = data.total
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getEcardAfterSaleList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getEcardAfterSaleList();
    },
    /**
     *  重置列表查询参数
     */
    resetListParams() {
      let temp = JSON.parse(JSON.stringify(this.params))
      this.params = {
        current: temp.current,
        size: temp.size,
        status: "",
        type: "",
        cardId: "",
        orderId: "",
        comboName: "",
        goodsName: "",
        phone: "",
        startCreateTime: "",
        endCreateTime: ""
      };
      this.value2 = [];
      this.getEcardAfterSaleList();
    },
    // 点击下拉菜单项时触发命令，根据命令显示对应的弹窗
    handleCommand(command) {
      if (command instanceof Object) {
        this.afterSaleStatusDialog = true;
        this.afterInfo = command;
      } else if (command == 'detail') {
        this.detailDialog = true;
      }
    },
    /**
     * 修改售后状态
     */
    async updateAfterSaleInfo(state) {
      let config = {
        id: this.afterInfo.id,
        state: state
      }
      await updateEcardAfterSaleInfoApi(config)
      this.afterSaleStatusDialog = false;
      this.afterInfo = {};
      this.getEcardAfterSaleList();

    }
  },
  created() {
    this.getEcardAfterSaleList();

  }
}
</script>

<style>
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>