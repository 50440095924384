import service from "@/utils/requestBackend";

export function getGoods(params) {
    return service({
        url: '/ecard-card-goods',
        method: 'get',
        params,
    })
}


export function addECardGoodsApi(data) {
    return service({
        url: '/ecard-card-goods',
        method: 'post',
        data,
    })
}

export function deleteDelGoodsApi(data) {
    return service({
        url: '/ecard-card-goods/delete',
        method: 'delete',
        params: data
    })
}