<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container"
           style="margin-top:10px;max-width:100%;flex-direction:row!important;display:flex; flex-wrap: wrap;">
        <!-- 搜索开始 -->
        <div style="width: 100%; margin-bottom: 10px;">
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem; display: flex;">
                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">图片ID：</label>
                <div class="demo-input-suffix">
                  <el-input
                      placeholder="请输入id"
                      prefix-icon="el-icon-search"
                      v-model.trim="picParams.id"
                      clearable @clear="getCardPicList">
                  </el-input>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">图片名称：</label>
                <div class="demo-input-suffix">
                  <el-input
                      placeholder="请输入内容"
                      prefix-icon="el-icon-search"
                      v-model.trim="picParams.cardPicName"
                      clearable @clear="getCardPicList">
                  </el-input>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 150px; padding-right: 7px;">图片分享详情名称：</label>
                <div class="demo-input-suffix">
                  <el-input
                      placeholder="请输入内容"
                      prefix-icon="el-icon-search"
                      v-model.trim="picParams.cardShareDetailPicName"
                      clearable @clear="getCardPicList">
                  </el-input>
                </div>


                <label class="el-form-item__label fw-bolder text-dark" style="width: 60px; padding-right: 7px;">状态：</label>
                <div class="demo-input-suffix">
                  <el-select v-model.number="picParams.status" clearable placeholder="请选择状态">
                    <el-option :value="1" label="启用"></el-option>
                    <el-option :value="-1" label="弃用"></el-option>
                  </el-select>
                </div>

                <el-button style="margin-left: 20px; height: 40px;" type="primary" size="mini" @click="getCardPicList"> 搜索</el-button>
                <el-button style="margin-left: 20px; height: 40px;" @click="reset" size="mini">重置</el-button>
                <el-button style="margin-left: 20px; height: 40px;" @click="AddCardPicDialog = true" size="mini">添加图片</el-button>

              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div class="card mb-5 mb-xl-8" style="margin-left:150px;width:90%;">
          <div class="card-body py-3" style="padding-left: 15px;">
            <div class="table-responsive">
              <!-- 列表数据展示 -->
              <el-table class="m-auto" :data="cardPicList" style="width: 100%" height="70vh"
                        element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        v-loading="picLoading">
                <el-table-column prop="id" label="卡图片ID" fixed width="100" align="center">
                </el-table-column>

                <el-table-column
                    label="卡图片名称"
                    prop="cardPicName"
                    width="120">
                </el-table-column>
                <el-table-column
                    label="卡图片详情"
                    prop="cardPicName"
                    width="120">
                  <template slot-scope="scope">
                    <div class="demo-image__preview">
                      <el-image
                          style="width: 100px; height: 100px"
                          :src="scope.row.cardPicUrl"
                          :preview-src-list="getSrcList(scope.row.cardPicUrl)">
                      </el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="卡分享详情图名称"
                    prop="cardShareDetailPicName"
                    width="140">
                </el-table-column>
                <el-table-column
                    label="卡分享详情图"
                    width="120">
                  <template slot-scope="scope">
                    <div class="demo-image__preview">
                      <el-image
                          style="width: 100px; height: 100px"
                          :src="scope.row.cardShareDetailPicUrl"
                          :preview-src-list="getSrcList(scope.row.cardShareDetailPicUrl)">
                      </el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="卡分享预览图"
                    width="120">
                  <template slot-scope="scope">
                    <div class="demo-image__preview">
                      <el-image
                          style="width: 100px; height: 100px"
                          :src="scope.row.cardSharePreviewPicUrl"
                          :preview-src-list="getSrcList(scope.row.cardSharePreviewPicUrl)">
                      </el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="卡分享LOGO图"
                    width="120">
                  <template slot-scope="scope">
                    <div class="demo-image__preview">
                      <el-image
                          style="width: 100px; height: 100px"
                          :src="scope.row.logoUrl"
                          :preview-src-list="getSrcList(scope.row.logoUrl)">
                      </el-image>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                    label="卡分享详情图背景色"
                    prop="bgColor"
                    width="150">
                </el-table-column>

                <el-table-column
                    label="祝福语"
                    prop="blessings"
                    width="200">
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="300" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="getCardPicData(scope.row.id)">
                      编辑卡图片详情
                    </el-button>
                  </template>
                </el-table-column>

              </el-table>
              <el-pagination
                  ref="pagination"
                  style="margin-top: 10px;text-align: center"
                  @size-change="picHandleSizeChange"
                  @current-change="picHandleCurrentChange"
                  :current-page.sync="picParams.current"
                  :page-sizes="[10, 20, 30, 50]"
                  :page-size="picParams.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="picTotalcount">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加图片   -->
    <el-dialog title="添加卡图片" :visible.sync="AddCardPicDialog" :modal="false" width="60%">
      <!-- 表单 -->
      <el-form ref="goodsTableData" :model="cardPicData" label-width="100px" :rules="rules" class="demo-ruleForm" label-position="top">
        <el-row>
          <el-col :span="3" style="margin-left: 200px !important;">
            <el-form-item label="卡主图名称" prop="name" class="fw-bold fs-10">
              <el-input v-model="cardPicData.cardPicName" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left: 200px !important;">
            <el-form-item label="卡分享详情图名称" prop="name" class="fw-bold fs-10">
              <el-input v-model="cardPicData.cardShareDetailPicName" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left:200px !important;">
            <el-form-item label="礼包状态" prop="type" class="fw-bold">
              <el-radio-group v-model="cardPicData.status">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="2">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- 主图 -->
          <el-col :span="3" style="margin-left:200px !important;">
            <el-form-item label="卡主图" prop="giftCode" required class="fw-bold">
              <div>
                <div class="text-center">
                  <div
                      class="image-input image-input-outline image-input-placeholder mb-3"
                      :class="cardPicData.cardPicUrl != ''
                        ? `image-input-empty`
                        : `image-input-changed`"
                      data-kt-image-input="true"
                      :style="{ cursor: cardPicData.cardPicUrl !== '' ? 'pointer' : 'default' }"
                  >
                    <div
                        class="image-input-wrapper w-150px h-150px"
                        :style="`background-image: url(${cardPicData.cardPicUrl})`"
                    ></div>
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="上传主图"
                    >
                      <i class="ki-outline ki-pencil fs-7"></i>
                      <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="uploadFileAvatar"
                      />
                      <input type="hidden" name="avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        @click="romverAvatar"
                        title="删除主图"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <!-- 分享详情图 -->
          <el-col :span="3" style="margin-left:200px !important;">
            <el-form-item label="卡分享详情图" prop="giftCode" required class="fw-bold">
              <div>
                <div class="text-center">
                  <div
                      class="image-input image-input-outline image-input-placeholder mb-3"
                      :class="cardPicData.cardShareDetailPicUrl != ''
                        ? `image-input-empty`
                        : `image-input-changed`"
                      data-kt-image-input="true"
                      :style="{ cursor: cardPicData.cardShareDetailPicUrl !== '' ? 'pointer' : 'default' }"
                  >
                    <div
                        class="image-input-wrapper w-150px h-150px"
                        :style="`background-image: url(${cardPicData.cardShareDetailPicUrl})`"
                    ></div>
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="上传详情图"
                    >
                      <i class="ki-outline ki-pencil fs-7"></i>
                      <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="uploadDetailFileAvatar"
                      />
                      <input type="hidden" name="avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        @click="romverDetailAvatar"
                        title="删除主图"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <!-- 分享预览图 -->
          <el-col :span="3" style="margin-left:200px !important;">
            <el-form-item label="卡分享预览图" prop="giftCode" required class="fw-bold">
              <div>
                <div class="text-center">
                  <div
                      class="image-input image-input-outline image-input-placeholder mb-3"
                      :class="cardPicData.cardSharePreviewPicUrl != ''
                        ? `image-input-empty`
                        : `image-input-changed`"
                      data-kt-image-input="true"
                      :style="{ cursor: cardPicData.cardSharePreviewPicUrl !== '' ? 'pointer' : 'default' }"
                  >
                    <div
                        class="image-input-wrapper w-150px h-150px"
                        :style="`background-image: url(${cardPicData.cardSharePreviewPicUrl})`"
                    ></div>
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="上传详情图"
                    >
                      <i class="ki-outline ki-pencil fs-7"></i>
                      <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="uploadPreviewFileAvatar"
                      />
                      <input type="hidden" name="avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        @click="romverPreviewAvatar"
                        title="删除主图"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left: 200px !important;">
            <el-form-item label="分享页背景色" prop="name" class="fw-bold fs-10">
              <el-input v-model="cardPicData.bgColor" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <div class="color-box" :style="{ backgroundColor: cardPicData.bgColor }"></div>
          </el-col>
          <!-- 分享logo图 -->
          <el-col :span="3" style="margin-left:200px !important;">
            <el-form-item label="卡分享LOGO图" prop="giftCode" required class="fw-bold">
              <div>
                <div class="text-center">
                  <div class="image-input image-input-outline image-input-placeholder mb-3"
                      :class="cardPicData.logoUrl != ''
                        ? `image-input-empty`
                        : `image-input-changed`"
                      data-kt-image-input="true"
                      :style="{ cursor: cardPicData.logoUrl !== '' ? 'pointer' : 'default' }"
                  >
                    <div
                        class="image-input-wrapper w-150px h-150px"
                        :style="`background-image: url(${cardPicData.logoUrl})`">
                    </div>
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="上传LOGO图"
                    >
                      <i class="ki-outline ki-pencil fs-7"></i>
                      <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="uploadLogoFileAvatar"
                      />
                      <input type="hidden" name="avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        @click="romverLogoAvatar"
                        title="删除Logo"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="margin-left: 60px !important;">
            <el-form-item label="祝福语" prop="name" class="fw-bold fs-10">
              <el-input v-model="cardPicData.blessings" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="d-flex justify-content-end">
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>
<script>


import {getCardPicListApi, addCardPicApi, getCardPicByIdApi} from "@/api/backend/xitunbaoCardApi";
import {uploadFileApi} from "@/api/backend/uploadFileApi";


export default {
  name: "XiTunBaoCardPic",

  data() {
    return {
      ids: [],
      srcList: [],
      cardPicCategoryId: "",
      selectCardPicDialog: false,
      picLoading: false,
      cardPicList: [],
      picParams: {
        current: 1,
        size: 10,
        cardPicName: "",
        cardShareDetailPicName: "",
        id: "",
      },
      picTotalcount: 99,
      AddCardPicDialog: false,
      cardPicData: {
        id: "",
        status: "",
        cardPicUrl: "",
        cardShareDetailPicUrl: "",
        cardSharePreviewPicUrl: "",
        cardPicName: "",
        cardShareDetailPicName: "",
        bgColor: "",
        logoUrl: "",
        blessings: "",
      },
      // 表单验证规则
      rules: {
        cardPicName: [
          {required: true, message: '请输入名称', trigger: 'blur'},
          {min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur'}
        ],
        cardShareDetailPicName: [
          {required: true, message: '请输入名称', trigger: 'blur'},
          {min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur'}
        ],
      },
    };
  },
  watch: {
    'cardPicData.bgColor': function (newVal) {
      if (!newVal.startsWith('#')) {
        this.cardPicData.bgColor = '#' + newVal;
      }
    }
  },
  // 方法
  methods: {
    // 复选框 将id 组成集合
    handleSelectionChange(val) {
      let arr = []
      val.forEach(item => {
        arr.push(item.id)
      })
      this.ids = arr;
    },

    /* 下拉菜单的 子列表*/
    getSrcList(cardPicUrl) {
      if (cardPicUrl) {
        return cardPicUrl.split(',');
      }
    },
    /* 查询 卡的所有图片 */
    /**
     * 查询卡图片集合
     */
    async getCardPicList() {
      this.picLoading = true;
      let {data} = await getCardPicListApi(this.picParams)
      this.picLoading = false;
      this.cardPicList = data.rows;
      this.picTotalcount = data.total;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    picHandleSizeChange(val) {
      this.picParams.size = val;
      this.getCardPicList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    picHandleCurrentChange(val) {
      this.picParams.current = val;
      this.getCardPicList();
    },
    /*重置*/
    reset() {
      this.picParams = {
        current: 1,
        size: 10,
      };
      this.getCardPicCategoryList();
    },
    /* 主图相关方法 */
    // 上传主图
    async uploadFileAvatar(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "XTB");
      let {data} = await uploadFileApi(formData);
      this.cardPicData.cardPicUrl = data.fileUrl;
    },
    // 删除主图
    romverAvatar() {
      this.cardPicData.cardPicUrl = "";
    },
    /* 分享详情图相关方法 */
    // 上传详情图
    async uploadDetailFileAvatar(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "XTB");
      let {data} = await uploadFileApi(formData);
      this.cardPicData.cardShareDetailPicUrl = data.fileUrl;
    },
    // 删除详情图
    romverDetailAvatar() {
      this.cardPicData.cardShareDetailPicUrl = "";
    },
    /* 分享预览图相关方法 */
    // 上传预览图
    async uploadPreviewFileAvatar(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "XTB");
      let {data} = await uploadFileApi(formData);
      this.cardPicData.cardSharePreviewPicUrl = data.fileUrl;
    },
    // 删除预览图
    romverPreviewAvatar() {
      this.cardPicData.cardSharePreviewPicUrl = "";
    },
    /* 分享logo图相关方法 */
    // 上传预LOGO
    async uploadLogoFileAvatar(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "XTB");
      let {data} = await uploadFileApi(formData);
      this.cardPicData.logoUrl = data.fileUrl;
    },
    // 删除LOGO
    romverLogoAvatar() {
      this.cardPicData.logoUrl = "";
    },
    // 校验
    dataVerification() {
      let data = this.cardPicData;
      if (!data.cardShareDetailPicName) {
        this.$message.warning('请选择卡分享详情图');
        return false;
      }
      if (!data.cardPicName) {
        this.$message.warning('请选择卡主图名称');
        return false;
      }
      /*if (!data.cardPicUrl) {
        this.$message.warning('请选择主图');
        return false;
      }*/
      if (!data.cardShareDetailPicUrl) {
        this.$message.warning('请选择详情图');
        return false;
      }
      if (!data.status) {
        this.$message.warning('请选择状态');
        return false;
      }
      if (!data.bgColor) {
        this.$message.warning('请填写背景色');
        return false;
      }
      if (!data.blessings) {
        this.$message.warning('请填写祝福语');
        return false;
      }
      if (data.blessings.length > 10) {
        this.$message.warning('请输入十个以内的祝福语');
        return false;
      }

      return true;
    },
    /*新增图片 提交*/
    async onSubmit() {
      if (!this.dataVerification()) {
        return;
      }
      // 新增 商品列表有值 并且 主键id没值
      try {
        await addCardPicApi(this.cardPicData);
        this.AddCardPicDialog = false;
        if (!this.cardPicData.id) {
          this.$message.success('新增图片成功!');
        } else {
          this.$message.success('修改图片成功!');
        }
        await this.getCardPicList();
        this.cardPicData = {
          id: "",
          status: "",
          cardPicUrl: "",
          cardShareDetailPicUrl: "",
          cardPicName: "",
          cardShareDetailPicName: "",
          cardSharePreviewPicUrl: "",
          bgColor: ""
        };
      } catch (e) {
        this.$message.error('新增图片失败！请重新输入数据！谢谢');
      }
    },
    /**
     * 回显卡图片详情
     */
    async getCardPicData(id) {
      this.picParams.id = id;
      this.AddCardPicDialog = true;
      let {data} = await getCardPicByIdApi(this.picParams);
      this.cardPicData = data;
    },
  },

  // 初始化这个方法
  created() {
    this.getCardPicList();
  },
};
</script>
<!--组件样式-->
<style>

.el-table .success-row {
  background: dodgerblue;
}

.color-box {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
}

</style>