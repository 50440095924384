<template>
  <div style="padding-top: 10px">
    <title-card style="max-width: 97%; margin: 10px auto" title="卡券列表">
      <div style="position: absolute; right: 70px">
        <el-button size="mini" type="primary" @click="openCreate">创 建 卡 券</el-button>
      </div>
      <template v-slot:subTitle>
      </template>

    </title-card>
    <div style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px;">
      <!--搜索栏条件 -->
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="listParams.id" placeholder="请输入需要查询的卡券编号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="listParams.title" placeholder="请输入需要查询的卡券名称"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value2"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束创建日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始创建日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value3"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束到期日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始到期日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="getECardList">搜索</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="resetListParams">重置</el-button>
        </div>
      </div>
      <div>
        <el-table
            v-loading="loading"
            :data="tableData"
            style="width: 100%">
          <el-table-column
              label="卡券编号"
              prop="id"
              width="180">
          </el-table-column>
          <el-table-column
              label="卡券名称"
              prop="title">
          </el-table-column>
          <el-table-column
              label="创建时间"
              prop="createTime">
          </el-table-column>
          <el-table-column
              label="结束时间"
              prop="endTime">
          </el-table-column>
          <el-table-column
              label="操作" width="600">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="openEdit(scope.row)">编辑</el-button>
              <el-button size="small" type="primary" @click="qrCode(scope.row)">查看</el-button>
              <el-button size="small" type="primary" @click="generateKey(scope.row)">卡密管理</el-button>
              <el-button size="small" type="warning" @click="openImportUser(scope.row)">导入用户</el-button>
              <el-button size="small" type="primary" @click="openEditUser(scope.row)">用户管理</el-button>
              <el-button size="small" type="success" @click="openEditGoods(scope.row)">商品管理</el-button>
              <el-button size="small" type="danger" @click="deleteCard(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            :page-size="0"
            :page-sizes="[10, 20, 30, 50]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            style="margin-top: 10px;text-align: center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <!--创建卡券弹窗-->
    <el-dialog :visible.sync="dialogFormCreateVisible" title="创建卡券" width="600px">
      <el-form ref="ruleFormCreate" :model="createForm" :rules="rules">
        <el-form-item label="卡券名称" label-width="200px" prop="title">
          <el-input v-model="createForm.title" autocomplete="off" style="width: 220px"></el-input>
        </el-form-item>
        <el-form-item label="卡券结束日期" label-width="200px" prop="endTime">
          <el-date-picker
              v-model="createForm.endTime"
              :default-value="new Date()"
              :picker-options="pickerOptionsCreate"
              align="right"
              placeholder="选择日期时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormCreateVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCreateForm">确 定</el-button>
      </div>
    </el-dialog>

    <!--编辑卡券弹窗-->
    <el-dialog :visible.sync="dialogFormEditVisible" title="编辑卡券" width="600px">
      <el-form ref="ruleFormEdit" :model="editForm" :rules="rules">
        <el-form-item label="卡券名称" label-width="200px" prop="title">
          <el-input v-model="editForm.title" autocomplete="off" style="width: 220px"></el-input>
        </el-form-item>
        <el-form-item label="卡券结束日期" label-width="200px" prop="endTime">
          <el-date-picker
              v-model="editForm.endTime"
              :default-value="new Date()"
              :picker-options="pickerOptionsCreate"
              align="right"
              placeholder="选择日期时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormEditVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditForm">确 定</el-button>
      </div>
    </el-dialog>

    <!--导入用户弹窗-->
    <el-dialog :visible.sync="dialogImportUserVisible" title="导入用户" width="600px">
      <el-select v-model.number="importUserValue" placeholder="请选择">
        <el-option
            v-for="item in importUserOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <input ref="file" name="file" style="margin-top: 20px" type="file">
      <el-link v-if="importUserValue == 1" :underline="false"
               href="https://admin.1c-1g.com/一采一购卡券用户导入模板.xlsx" style="margin-top: 10px">下载导入用户模板
      </el-link>
      <el-link v-if="importUserValue == 2" :underline="false" href="https://admin.1c-1g.com/一采一购导入卡券模板.xlsx"
               style="margin-top: 10px">下载导入组合礼包模板
      </el-link>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogImportUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadFile">上 传</el-button>
      </span>


    </el-dialog>

    <!--用户管理窗-->
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogEditUserVisible" title="用户管理" width="70%">
      <div style="width: 400px;display: flex;">
        <el-input
            v-model="userListParams.tel"
            clearable
            placeholder="请输入手机号"
            prefix-icon="el-icon-search"
            size="small">
        </el-input>
        <el-button icon="el-icon-search" size="small" style="margin-left: 10px" type="primary" @click="getUserList">
          搜索
        </el-button>
      </div>

      <el-table
          :data="tableUserData"
          align="center" style="width: 100%">
        <el-table-column
            align="center"
            label="手机号"
            prop="tel"
            width="130">
        </el-table-column>
        <el-table-column
            align="center"
            label="姓名"
            prop="userName"
            width="130">
        </el-table-column>
        <el-table-column
            align="center"
            label="区域一可选次数"
            prop="regionOneNum">
        </el-table-column>
        <el-table-column
            align="center"
            label="区域二可选次数"
            prop="regionTwoNum">
        </el-table-column>
        <el-table-column
            align="center"
            label="区域三可选次数"
            prop="regionThreeNum">
        </el-table-column>
        <el-table-column
            align="center"
            label="区域四可选次数"
            prop="regionFourNum">
        </el-table-column>
        <el-table-column
            align="center"
            label="区域五可选次数"
            prop="regionFiveNum">
        </el-table-column>
        <el-table-column
            align="center"
            label="导入时间"
            prop="createTime">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="200">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="openUserRegion(scope.row)">修改</el-button>

            <el-button slot="reference" size="small" type="danger" @click="deleteUser(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="userListParams.current"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          :total="userListTotal"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChangeUserList"
          @current-change="handleCurrentChangeUserList">
      </el-pagination>
    </el-dialog>


    <el-dialog :close-on-click-modal="false" :visible.sync="dialogEditUserRegionVisible" title="修改用户" width="600px">
      <el-form ref="editECardUserForm" :model="editECardUserForm" :rules="editECardUserFormRules" label-width="100px">
        <el-form-item label="活动名称" prop="userName">
          <el-input v-model="editECardUserForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="区域一" prop="regionOneNum">
          <el-input v-model.number="editECardUserForm.regionOneNum"></el-input>
        </el-form-item>
        <el-form-item label="区域二" prop="regionTwoNum">
          <el-input v-model.number="editECardUserForm.regionTwoNum"></el-input>
        </el-form-item>
        <el-form-item label="区域三" prop="regionThreeNum">
          <el-input v-model.number="editECardUserForm.regionThreeNum"></el-input>
        </el-form-item>
        <el-form-item label="区域四" prop="regionFourNum">
          <el-input v-model.number="editECardUserForm.regionFourNum"></el-input>
        </el-form-item>
        <el-form-item label="区域五" prop="regionFiveNum">
          <el-input v-model.number="editECardUserForm.regionFiveNum"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitEditECardUserForm('editECardUserForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 弹窗 挑选商品 -->
    <el-dialog :visible.sync="dialogEditGoodsVisible" title="请挑选商品到卡券" width="95%">
      <el-row :gutter="20">
        <el-col :span="18">
          <el-form label-position="left" label-width="100px" size="small">
            <el-row style="margin-top:10px;">
              <el-col :span="5" class="mg-r-20">
                <el-form-item label="组合礼包名称:">
                  <el-input v-model="pageParams.name" placeholder="请输入组合礼包名称进行搜索"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="mg-r-20">
                <el-form-item label="组合礼包编码:">
                  <el-input v-model="pageParams.giftCode" placeholder="请输入礼包编码或ERP编码进行搜索"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-button size="small" type="primary" @click="moreConditions = !moreConditions">更多条件
                  <i v-if="moreConditions" class="el-icon-arrow-up"></i>
                  <i v-if="!moreConditions" class="el-icon-arrow-down"></i>
                </el-button>
                <el-button size="small" @click="search">搜索</el-button>
                <el-button size="small" @click="reset">重置</el-button>
              </el-col>
            </el-row>
            <el-collapse-transition>
              <div v-show="moreConditions">
                <el-row>
                  <el-col :span="5" class="mg-r-20">
                    <el-form-item class="d-flex align-items-center switchBox" label="礼包状态:">
                      <el-select v-model="pageParams.isShow" class="el-select--small" placeholder="请选择"
                                 style="width: 90%;">
                        <el-option label="启用" value="1"></el-option>
                        <el-option label="停用" value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" class="mg-r-20">
                    <el-form-item class="el-form-item--small" label="价格:" label-width="50px">
                      <div style="display: flex; align-items: center;">
                        <el-select v-model="priceValue" class="el-select--small" placeholder="请选择"
                                   style="width: 90%;">
                          <el-option v-for="(item,index) in priceType" :key="index" :label="item.name"
                                     :value="item.type">
                          </el-option>
                        </el-select>
                        <el-input v-model="price.minPrice" class="w-400px el-input--small me-1 ms-3"
                                  placeholder="请输入最低价"></el-input>
                        <span class="middle-span">至</span>
                        <el-input v-model="price.maxPrice" class="w-400px el-input--small ms-1"
                                  placeholder="请输入最高价"></el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-collapse-transition>
          </el-form>
          <el-table v-loading="loading"
                    :data="tableDataPack" element-loading-background="rgba(0, 0, 0, 0.8)"
                    element-loading-spinner="el-icon-loading" element-loading-text="拼命加载中">
            <el-table-column align="center" fixed label="礼包图片" width="100">
              <template slot-scope="props">
                <img :src="props.row.imageUrl" alt="" style="width:70px;height:70px;">
              </template>
            </el-table-column>
            <el-table-column align="center" fixed label="礼包名称" prop="name" width="300">
              <template slot-scope="props">
                <el-button @click="$router.push({name: 'AddOrUpdateComboGiftPack',params: { id: props.row.id }})">{{ props.row.name }}</el-button>
              </template>
            </el-table-column>
            <el-table-column align="center" label="礼包编码" prop="giftCode" width="100">
            </el-table-column>
            <el-table-column label="礼包分类" width="100">
              <template slot-scope="props">
                <span>{{ props.row.categoryId1Name }}</span><br/>
                <span>{{ props.row.categoryId2Name }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="市场价" width="100">
              <template slot-scope="props">
                <span>{{ props.row.giftPrice | format }}</span>
              </template>
            </el-table-column>
            <el-table-column align ="center" label="供货价" width="100">
              <template slot-scope="props">
                <span>{{ props.row.giftSupplyPrice | format }}</span>
              </template>
            </el-table-column>
            <!--<el-table-column prop="giftSalePrice" label="销售价" width="100" sortable align="center">
            </el-table-column>-->
            <el-table-column align="center" label="利润率" sortable width="100">
              <template slot-scope="props">
                <span>{{ props.row.giftRate }} %</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品数" prop="giftTotalGoods" width="100">
            </el-table-column>
            <el-table-column align="center" label="单品详情" width="100">
              <template slot-scope="props">
                <span v-if="props.row.showSingle === 1">显示</span>
                <span v-else>省略</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态" prop="state" width="100">
              <template slot-scope="props">
                <span v-if="props.row.isShow === 1">启用</span>
                <span v-else>停用</span>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" prop="state" width="100">
              <template slot-scope="props">
<!--                <el-button v-if="!idsArr.includes(props.row.id)" size="small" type="text" @click="openRegion(props.row)">添加</el-button>-->
                <el-button v-if="!idsArr.includes(props.row.id)" size="small" type="text" @click="openRegion(props.row)">添加</el-button>
                <a v-else>已添加</a>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="pageParams.current"
              :page-size="10"
              :page-sizes="[10, 40, 60, 100]"
              :total="totalCount1"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChangeGoods"
              @current-change="handleCurrentChangeGoods">
          </el-pagination>
        </el-col>
        <el-col :span="6">

          <div style="display: flex;justify-content:space-between;">
            <el-input v-model="goodsPageParams.name" placeholder="请输入组合礼包名称进行搜索"></el-input>
            <el-button style="margin-left: 10px" size="mini" type="primary" @click="getSelGoods">搜索</el-button>
          </div>
          <div style="display: flex;justify-content:space-between;">
            <h4>已选商品<span style="font-size:12px;margin-left: 5px;">(共选择 {{ goodsTotalCount }} 个商品)</span></h4>
            <el-button v-if="goodsIds.length" size="small" type="danger" @click="clearSelectedGoods">清空已选商品</el-button>
          </div>

          <div class="table-responsive">
            <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
              <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th aria-controls="kt_ecommerce_products_table" aria-label="Product: activate to sort column ascending"
                    class="min-w-200px" colspan="1"
                    rowspan="1"
                    style="width: 212.141px" tabindex="0">
                  商品信息
                </th>
                <th>
                  分区
                </th>
                <th aria-label="操作" class="text-center sorting_disabled" colspan="1" rowspan="1"
                    style="width: 80px">
                  操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item, index) in goodList" :key="index"
                  :class="{ old: index % 2 == 0, even: index % 2 != 0 }">
                <td>
                  <div class="d-flex align-items-center">
                    <a class="symbol symbol-50px" href="javascript:void(0);">
                      <img v-if="item.img.indexOf('http') == 0" :src="`${item.img}`" class="symbol-label lozad"/>
                      <img v-else :src="`https://www.1c-1g.com${item.img}`" class="symbol-label lozad"/>
                    </a>
                    <div class="ms-5">
                      <a @click="$router.push({name: 'AddOrUpdateComboGiftPack',params: { id: item.goodsId }})"
                         class="text-gray-800 text-hover-primary fs-5" data-kt-ecommerce-product-filter="product_name"
                         href="javascript:void(0);">{{ item.title }}</a>
                    </div>

                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="ms-5">
                      <a class="text-gray-800 text-hover-primary fs-5" data-kt-ecommerce-product-filter="product_name"
                         href="javascript:void(0);">{{ item.region }}</a>
                    </div>
                  </div>
                </td>

                <!-- 操作 -->
                <td class="text-end">
                  <el-link type="danger" @click="delGroupGoods(item)">删除</el-link>
                </td>
              </tr>
              </tbody>
            </table>
            <el-pagination
                :current-page="goodsPageParams.current"
                :page-size="10"
                :page-sizes="[10, 40, 60, 100]"
                :total="goodsTotalCount"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="goodsHandleSizeChangeGoods"
                @current-change="goodsHandleCurrentChangeGoods">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
        :visible.sync="qrCodeDialogVisible"
        title=""
        width="20%">
      <img :src="qrCodeImag" width="100%">
    </el-dialog>
    <!-- 生成卡密 -->
    <Key ref="key"></Key>
    <canvas ref="canvass" style="width: 100%;height: 100%;display: none"></canvas>
  </div>
</template>

<script>
import {mixins} from "@/views/ECard/mixins";

export default {
  mixins: [mixins],
}
</script>


<style scoped>

</style>