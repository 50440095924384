import service from "@/utils/requestBackend";

export function exportExcelApi(params) {
    return service({
        url: '/export/excel',
        method:'get',
        params,
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}

export function exportCamiQRCodeExcelApi(params) {
    return service({
        url: '/export/cami',
        method:'get',
        params,
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}