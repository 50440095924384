import service from "@/utils/requestDownload";


/**
 * 下载商品数据api
 */
export function downloadDataByPoolApi(data) {
    return service({
        url: '/download/pool',
        method: 'post',
        data
    })
}
