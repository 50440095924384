import service from "@/utils/requestAdmin";

/**
 * 获取一级分类
 * @returns {*}
 */
export function getCategoryListApi() {
    return service({
        url: '/category/getFirstLevel',
        method: 'get',
    })
}

/**
 * 获取二级分类
 */
export function getCategory1ListApi(params) {
    return service({
        url: '/category/getOther',
        method: 'get',
        params
    })
}