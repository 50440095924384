<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  created() {

  },
  methods: {

  },
  computed: {
  },
};
</script>
<style>
.el-dialog {
  border-radius: 0.92rem !important;
}

a {
  text-decoration: none;
}
body {
  margin: 0;
  padding: 0;
}
</style>