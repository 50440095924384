<template>
  <el-dialog
      title="下载数据日志"
      :visible.sync="logsDialog"
      width="50%">
    <div ref="logContainer" class="log-container">
      <div v-for="(log, index) in logs" :key="index">{{ log }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeLogsDialog">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'LogViewer',
  mounted() {
    if (this.logs) {
      // 在 $nextTick 内使用 $refs 确保元素已经渲染
      this.$nextTick(() => {
        // 初始化时处理空日志容器
        const logContainer = this.$refs.logContainer;
        if (logContainer) {
          // 设置一个初始的滚动位置，确保滚动条在容器底部
          logContainer.scrollTop = logContainer.scrollHeight;
        }
      });
    }

    // 连接 WebSocket
    this.connectWebSocket();
  },
  data() {
    return {
      logsDialog: false,
      logs: [],
      socket: null,
    };
  },
  methods: {
    connectWebSocket() {
      // this.socket = new WebSocket('ws://localhost:9091/logs');
      // this.socket = new WebSocket('wss://admin.1c-1g.com/downloadApi/logs');
      let webSocketUrl = process.env.VUE_APP_WEBSOCKET_URL; // 动态读取 WebSocket URL

      this.socket = new WebSocket(webSocketUrl);

      this.socket.onmessage = (event) => {
        this.logs.push(event.data);
        if (this.logs) {
          // 每次接收到新日志时滚动到底部
          this.$nextTick(() => {
            const logContainer = this.$refs.logContainer;
            if (logContainer) {
              logContainer.scrollTop = logContainer.scrollHeight;
            }
          });
        }

      };

      this.socket.onopen = () => {
        // 开始发送心跳消息
        this.startHeartbeat();
      };

      this.socket.onclose = () => {
        // 停止发送心跳消息
        this.stopHeartbeat();
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
    openLogsDialog() {
      this.logsDialog = true;
    },
    closeLogsDialog() {
      this.logsDialog = false;
    },
    startHeartbeat() {
      if (this.socket.readyState === WebSocket.OPEN) {
        // 每30秒发送一次心跳消息
        this.heartbeatInterval = setInterval(() => {
          this.socket.send('heartbeat');
        }, 30000);
      }
    },
    stopHeartbeat() {
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
    }

  },
  created() {
    this.connectWebSocket();
  },
  beforeDestroy() {
    // 页面卸载时关闭WebSocket连接
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
};
</script>

<style>
.log-container {
  height: 300px; /* 设置一个合适的高度 */
  overflow-y: auto; /* 确保内容超出高度时出现滚动条 */
  max-height: 400px;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
