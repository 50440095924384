<template>
  <div style="padding-top: 10px">
    <title-card style="max-width: 97%; margin: 10px auto" title="订单管理">
      <div style="position: absolute; right: 70px">
      </div>
      <template v-slot:subTitle>

      </template>
    </title-card>
    <div v-loading="loading"
         :element-loading-text="loadingMsg"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px"
    >

      <el-table
          :data="rows"
          height="500"
          style="width: 100%">
        <el-table-column
            fixed
            label="ID"
            prop="id"
            width="50">
        </el-table-column>
        <el-table-column
            fixed
            label="订单号"
            prop="mdlOrder"
            width="200">
        </el-table-column>
        <el-table-column
            fixed
            label="京东订单号"
            prop="orderId"
            width="150">
        </el-table-column>
        <el-table-column
            fixed
            label="购买方式">
          <template slot-scope="{row}">
            {{ row.payType | payStatusF }}
          </template>
        </el-table-column>
        <el-table-column
            label="物流单号"
            prop="logisticsId"
            width="200">
        </el-table-column>
        <el-table-column
            label="商品名称"
            prop="skuName"
            width="200">
        </el-table-column>
        <el-table-column
            label="数量"
            prop="skuNum"
            width="80">
        </el-table-column>
        <el-table-column
            label="支付状态"
            width="100">
          <template slot-scope="{row}">
            {{ row.status | statusF }}
          </template>
        </el-table-column>
        <el-table-column
            label="商品状态"
            prop="validCode"
            width="80">
          <template slot-scope="{row}">
            {{ row.validCode | validCodeF }}
          </template>
        </el-table-column>
        <el-table-column
            label="采购价"
            prop="purchasePrice"
            width="100">
        </el-table-column>
        <el-table-column
            label="利润"
            prop="profit"
            width="100">
        </el-table-column>
        <el-table-column
            label="京东原价"
            width="100">
          <template slot-scope="{row}">
            <div v-if="row.originalPrice">
              {{ row.originalPrice | format }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="返积分"
            prop="returnIntegral"
            width="100">
        </el-table-column>
        <el-table-column
            label="支付金额"
            width="100">
          <template slot-scope="{row}">
            {{ row.payPrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="实付金额"
            width="100">
          <template slot-scope="{row}">
            {{ row.totalPrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="使用积分"
            prop="useIntegral"
            width="100">
        </el-table-column>
        <el-table-column
            label="支付时间"
            width="176">
          <template slot-scope="{row}">
            <div v-if="row.payTime">
              {{ `${row.payTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="下单时间"
            width="176">
          <template slot-scope="{row}">
            <div v-if="row.createTime">
              {{ `${row.createTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="确认收货时间"
            width="176">
          <template slot-scope="{row}">
            <div v-if="row.confirmTime">
              {{ `${row.confirmTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>
        <!--confirm_time-->
      </el-table>
    </div>

  </div>
</template>
<script>
import {getKdlOrderApi} from "@/api/backend/xitunbaoApi";
import sd from "silly-datetime";

export default {
  name: "Order",
  data() {

    return {
      rows: [],
      loading: false,
      loadingMsg: "列表加载中...",

    }
  },
  filters: {
    // 商品状态:3=未支付,4=退款,15=已支付等待发货,16=已发货,17=确认收货
    validCodeF(v) {
      switch (v) {
        case 3:
          return '未支付'
        case 4:
          return '退款'
        case 15:
          return '已支付等待发货'
        case 16:
          return '已发货'
        case 17:
          return '确认收货'
      }
    },
    payStatusF(v) {
      // 购买方式:1=正常付款,2=点数兑换
      switch (v) {
        case 1:
          return '正常付款'
        case 2:
          return '点数兑换'
      }
    },

    formatDate(value) {
      return sd.format(+value)
    },
    goodsTypeF(type) {
      switch (type) {
        case "1":
          return '普通商品'
        case "2":
          return '限时秒杀'
        case "3":
          return '送余额商品'
        case "4":
          return '一元购商品'
      }
    },
    statusF(type) {
      switch (type) {
        case 0:
          return '未支付'
        case 1:
          return '已支付'
        case 2:
          return '退款'
      }
    },
    orderTypeF(type) {
      // 1=普通商品订单,2=限时秒杀订单,3=送余额商品订单
      switch (type) {
        case "1":
          return '普通商品订单'
        case "2":
          return '限时秒杀订单'
        case "3":
          return '送余额商品订单'

      }
    }
  },
  methods: {

    async getOrderList() {
      this.loading = true;
      this.loadingMsg = "列表加载中...";
      let {data} = await getKdlOrderApi()
      this.rows = data
      this.loading = false;
    },

  },
  created() {
    this.getOrderList()
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>