import service from "@/utils/requestBackend";


/**
 * 下载商品数据api
 */
export function downloadDataByBrandApi(data) {
    return service({
        url: '/download/brand',
        method: 'post',
        data
    })
}


/**
 * 修改商品池数据api
 */
export function updateBrandByBrandIdApi(data) {
    return service({
        url: '/jdBrand/updateBrandDownload',
        method: 'post',
        data
    })
}

/**
 * 修改商品池数据api
 */
export function editRemarkApi(params) {
    return service({
        url: '/jdBrand/remark',
        method: 'get',
        params
    })
}


/**
 * 获取地址
 */
export function getJdBrandListApi(data) {
    return service({
        url: "/jdBrand/jdBrandPage",
        method: "get",
        params: data
    })
}

