import service from "@/utils/requestBackend";

/**
 * 获取订单列表
 */
export function getEcardAfterSaleListApi(params) {
    return service({
        url: "/admin/afterSales/list",
        method: "get",
        params
    });
}

export function updateEcardAfterSaleInfoApi(data) {
    return service({
        url: "/admin/afterSales/updateAfterSale",
        method: "post",
        data
    });
}