import {getComboGiftPackListApi} from "@/api/backend/comboGiftPackApi";
import {
    batchUpdateKeyApi,
    createECardApi,
    deleteAllKeyApi,
    deleteKeyApi,
    editRemarkApi,
    getECardItemListApi
} from "@/api/backend/eCardItem";
import {CardKeyRule} from "@/utils/CardKeyRule";
import qrcode from "qrcode";
import {exportCamiQRCodeExcelApi} from "@/api/backend/export";
import sd from "silly-datetime";

export const mixins = {
    data() {
        return {
            multipleSelection: [],
            batchRules: {
                cardNo: [
                    {validator: this.checkCardNo, trigger: 'blur'}
                ],
                cardNoPrefix: [
                    {validator: this.checkCardNoPrefix, trigger: 'blur'}
                ]
            },
            qrCodeImag: "",
            item: {},
            qrCodeDialogVisible: false,
            dialogVisible: false,
            batchDialog: false,
            batchForm: {
                cardId: "",
                goodsId: "",
                type: "1",
                status: "1",
                cardNoPrefix: "",
                startCardNo: "",
                endCardNo: "",
            },
            loading: false,
            params: {
                size: 10,
                current: 1,
                isShow: '1',
                name: ""
            },
            goodsTotal: 0,
            list: [],
            goodItem: {},
            dialogVisibleKey: false,
            generateDialog: false,
            keyParams: {
                size: 10,
                current: 1,
                cardId: "",
                cardNo: "",
                cardNoPrefix: "",
                tel: ""
            },
            generateKeyLoading: false,
            keyList: [],
            keyTotal: 0,
            keyForm: {
                cardId: "",
                goodsId: "",
                num: 0,
                length: 10,
                rule: 1,
                cardNoPrefix: ""
            },
        }
    },
    methods: {
        /**
         * 批量修改备注
         */
        batchUpdateRemark() {
            this.$prompt('请输入修改的备注', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async ({value}) => {
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    let item = this.multipleSelection[i]
                    await editRemarkApi({
                        id: item.id,
                        remark: value
                    })
                }
                this.$message({
                    type: 'success',
                    message: '操作成功'
                });
                await this.getKeyList();
            }).catch(() => {

            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        /**
         * 修改备注
         */
        updateRemark(item) {
            this.$prompt('请输入修改的备注', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: item.remark
            }).then(async ({value}) => {
                await editRemarkApi({
                    id: item.id,
                    remark: value
                })
                this.$message({
                    type: 'success',
                    message: '操作成功'
                });
                await this.getKeyList();
            }).catch(() => {

            });
        },
        /**
         * 验证卡号前缀
         */
        checkCardNoPrefix(rule, value, callback) {
            if (this.batchForm.type === "2") {
                if (this.batchForm.cardNoPrefix.trim() === "") {
                    callback(new Error("请输入卡号前缀"));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        /**
         * 验证卡号区间
         * @param rule
         * @param value
         * @param callback
         */
        checkCardNo(rule, value, callback) {
            if (this.batchForm.type === "1") {
                if (this.batchForm.startCardNo.trim() === "" || this.batchForm.endCardNo.trim() === "") {
                    callback(new Error("请输入起始卡号和结束卡号"));
                } else {
                    callback();
                }
            }
            callback();
        },
        /**
         * 卡密切换size
         * @param val
         */
        async handleKeySizeChange(val) {
            this.keyParams.size = val;
            await this.getKeyList();
        },
        /**
         * 卡密切换页码
         * @param val
         */
        async handleKeyCurrentChange(val) {
            this.keyParams.current = val;
            await this.getKeyList();
        },
        /**
         * 获取卡密规则
         * @returns {{}}
         */
        getCardKeyRule() {
            return CardKeyRule;
        },
        /**
         * 查看卡密
         */
        async openKey(item) {
            this.goodItem = item;
            this.qrCodeDialogVisible = false;
            this.dialogVisibleKey = true;
            this.keyParams.cardId = this.item.id;
            this.keyParams.goodsId = item.id;
            this.keyForm.cardId = this.item.id;
            this.keyForm.goodsId = item.id;
            await this.getKeyList()
        },
        /**
         * 初始化列表
         * @param item
         */
        async initList(item) {
            this.item = item;
            this.dialogVisible = true;
            await this.getGoodsList();
        },
        /**
         * 获取商品列表
         */
        async getGoodsList() {
            this.loading = true;
            let {data} = await getComboGiftPackListApi(this.params);
            this.list = data.rows;
            this.goodsTotal = data.total;
            this.loading = false;
        },
        /**
         * 获取卡密
         */
        async getKeyList() {
            let {data} = await getECardItemListApi(this.keyParams)
            this.keyList = data.rows
            this.keyTotal = data.total
        },
        /**
         * 生成卡密
         */
        async generateKey() {
            this.generateKeyLoading = true;
            let obj = JSON.parse(JSON.stringify(this.keyForm));
            if (obj.cardNoPrefix.trim() === "") {
                delete obj.cardNoPrefix;
            }
            await createECardApi(obj)
            this.generateKeyLoading = false;
            this.generateDialog = false;
            await this.getKeyList();
            this.keyForm.cardNoPrefix = "";
            this.keyForm.num = "";
        },
        /**
         * 删除卡密
         */
        async deleteKey(item) {
            await deleteKeyApi({
                id: item.id
            })
            await this.getKeyList();
        },
        /**
         * 编辑卡密
         */
        editKey(item) {
            console.log(item)
        },
        /**
         * 批量操作
         */
        async batchOperation() {
            this.batchForm.cardId = this.keyParams.cardId;
            this.batchForm.goodsId = this.keyParams.goodsId;
            this.$refs['batchForm'].validate(async (valid) => {
                if (valid) {
                    await batchUpdateKeyApi(this.batchForm);
                    this.batchDialog = false;
                    await this.getKeyList();
                    this.batchForm.cardNoPrefix = "";
                    this.batchForm.startCardNo = "";
                    this.batchForm.endCardNo = "";
                    this.batchForm.status = "1";
                    this.batchForm.type = "1";
                }
            })
        },
        /**
         * 一键删除失效卡密
         */
        async deleteAllKey() {
            this.$confirm('此操作将会一键删除失效卡密?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await deleteAllKeyApi({
                    cardId: this.keyParams.cardId,
                    goodsId: this.keyParams.goodsId
                })
                await this.getKeyList();
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            })
        },
        /**
         * 商品分页切换
         */
        async handleGoodsCurrentChange(val) {
            this.params.current = val;
            await this.getGoodsList();
        },
        /**
         * 商品分页切换
         */
        async handleGoodsSizeChange(val) {
            this.params.size = val;
            await this.getGoodsList();
        },
        openQrCode(item) {
            this.qrCodeImag = ""
            this.$nextTick(async () => {
                this.qrCodeDialogVisible = true;
                let url = "https://eacrd.1c-1g.com/GiftBagDetailWithcode?cardId=" + this.item.id + "&comboId=" + item.id  + "&isClean=1";
                // qrcode.toDataURL(url, (err, url) => {
                //     if (!err) {
                //         this.qrCodeImag = url;
                //     }
                // });
                try {
                    const canvas = this.$refs.canvass;
                    const ctx = canvas.getContext('2d');

                    const qrCodeOptions = {
                        errorCorrectionLevel: 'H',
                        type: 'image/png',
                        quality: 1,
                        margin: 1,
                        color: {
                            dark: '#000000ff',
                            light: '#ffffffff'
                        }
                    };

                    const qrCodeDataUrl = await qrcode.toDataURL(url, qrCodeOptions);
                    const qrCodeImage = new Image();
                    qrCodeImage.src = qrCodeDataUrl;

                    const logoImage = new Image();
                    logoImage.src = './images/logo.png'; // 替换成你的Logo图片路径

                    qrCodeImage.onload = () => {
                        canvas.width = qrCodeImage.width;
                        canvas.height = qrCodeImage.height;

                        ctx.drawImage(qrCodeImage, 0, 0, qrCodeImage.width, qrCodeImage.height);
                        const logoSize = qrCodeImage.width * 0.3; // 调整Logo大小
                        const logoPositionX = (qrCodeImage.width - logoSize) / 2;
                        const logoPositionY = (qrCodeImage.height - logoSize) / 2;

                        logoImage.onload = () => {
                            ctx.drawImage(logoImage, logoPositionX, logoPositionY, logoSize, logoSize);
                            this.qrCodeImag = canvas.toDataURL();
                        };
                    };
                } catch (error) {
                    console.error('Error generating QR code with logo:', error);
                }
            });

        },
        async exportExcel(item) {
            const response = await exportCamiQRCodeExcelApi({
                cardId: this.item.id,
                goodsId: item.id
            })
            const blob = new Blob([response], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            let parts = item.name.split(" ");
            let name;
            if (parts.length > 1) {
                name = parts[1];
            } else {
                name = parts[0];
            }
            a.download = `${sd.format(new Date())}_${name}_卡密二维码.xlsx`; // 设置下载的文件名，根据需要修改
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }

    }
}