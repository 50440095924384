<template>
  <div style="padding-top: 10px">
    <title-card title="组合礼包" style="max-width: 97%; margin: 10px auto">
      <div style="position: absolute; right: 70px">
        <!--<el-button type="primary" size="mini">创建选品库</el-button>-->
      </div>
      <template v-slot:subTitle>

      </template>
    </title-card>
    <div style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px">
      <el-form label-position="left" size="small" label-width="100px">
        <el-row style="margin-top:10px;">
          <el-col :span="5" class="mg-r-20">
            <el-form-item label="组合礼包名称:">
              <el-input v-model="params.name" placeholder="请输入组合礼包名称进行搜索"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" class="mg-r-20">
            <el-form-item label="组合礼包id:">
              <el-input v-model="params.id" placeholder="请输入礼包id进行搜索"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="moreConditions = !moreConditions" size="small">更多条件
              <i v-if="moreConditions" class="el-icon-arrow-up"></i>
              <i v-if="!moreConditions" class="el-icon-arrow-down"></i>
            </el-button>
            <el-button @click="search" size="small">搜索</el-button>
            <el-button @click="reset" size="small">重置</el-button>
          </el-col>
          <el-col :span="7">
            <div class="flex_sb d-flex justify-content-end">
              <div class="flex_no">
                <el-badge :value="statusUnread" :max="99" class="badge-custom me-9">
                  <el-button icon="el-icon-message-solid badge-icon" @click="openDialog"></el-button>
                </el-badge>
                <div style="margin-right:7px;">已选择 <span style="color:#0f55b6;">{{ ids.length }}</span> 款礼包</div>
                <el-button size="small" @click="deleteComboGiftPack(false)">批量删除</el-button>
                <el-button size="small" type="primary" @click="addGoods">新增组合礼包</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-collapse-transition>
          <div v-show="moreConditions">
            <el-row>
              <el-col :span="5" class="mg-r-20">
                <el-form-item label="礼包状态:" class="d-flex align-items-center switchBox">
                  <el-select v-model="params.isShow" placeholder="请选择" class="el-select--small" style="width: 90%;">
                    <el-option label="启用" value="1"></el-option>
                    <el-option label="停用" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="mg-r-20">
                <el-form-item label="价格:" label-width="50px" class="el-form-item--small">
                  <div style="display: flex; align-items: center;">
                    <el-select v-model="priceValue" placeholder="请选择" class="el-select--small" style="width: 90%;">
                      <el-option v-for="(item,index) in priceType" :label="item.name" :value="item.type" :key="index">
                      </el-option>
                    </el-select>
                    <el-input v-model="price.minPrice" placeholder="请输入最低价"
                              class="w-400px el-input--small me-1 ms-3"></el-input>
                    <span class="middle-span">至</span>
                    <el-input v-model="price.maxPrice" placeholder="请输入最高价" class="w-400px el-input--small ms-1"></el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-collapse-transition>
      </el-form>

      <!-- 列表数据展示 -->
      <el-table class="m-auto" :data="tableData" style="width: 80%" height="70vh"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                @selection-change="handleSelectionChange" v-loading="loading"
                @sort-change="sortChange"
                >
        <el-table-column fixed width="55" type="selection">
        </el-table-column>
        <el-table-column label="礼包id" prop="id" fixed width="200" align="center">
        </el-table-column>
        <el-table-column label="礼包图片" fixed width="100" align="center">
          <template slot-scope="props">
            <img style="width:70px;height:70px;" :src="props.row.imageUrl" alt="">
          </template>
        </el-table-column>
        <el-table-column fixed prop="name" label="礼包名称" width="170" align="center">
        </el-table-column>
        <el-table-column prop="giftCode" label="礼包编码" width="200" align="center">
        </el-table-column>
        <el-table-column label="礼包分类" width="100">
          <template slot-scope="props">
            <span>{{ props.row.categoryId1Name }}</span><br/>
            <span>{{ props.row.categoryId2Name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="市场价" width="100" align="center" :sortable="true">
          <template slot-scope="props">
            <span>{{ props.row.giftPrice | format }}</span>
          </template>
        </el-table-column>
        <el-table-column label="供货价" width="100" align="center">
          <template slot-scope="props">
            <span>{{ props.row.giftSupplyPrice | format }}</span>
          </template>
        </el-table-column>
        <!--<el-table-column prop="giftSalePrice" label="销售价" width="100" sortable align="center">
        </el-table-column>-->
        <el-table-column label="利润率" width="100" align="center">
          <template slot-scope="props">
            <span>{{ props.row.giftRate.toFixed(2) }} % </span>
          </template>
        </el-table-column>
        <el-table-column prop="giftTotalGoods" label="商品种类" width="100" align="center">
        </el-table-column>
        <el-table-column label="单品详情" width="100" align="center">
          <template slot-scope="props">
            <span v-if="props.row.showSingle === 1">展示</span>
            <span v-else>隐藏</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" width="100" align="center">
          <template slot-scope="props">
            <span v-if="props.row.isShow === 1" class="text-success">启用</span>
            <span v-else class="text-danger">停用</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="140" align="center">
          <template slot-scope="scope">
            <el-button @click="$router.push({name: 'AddOrUpdateComboGiftPack',params: { id: scope.row.id },})"
                       type="text" size="small">编辑
            </el-button>
            <el-button type="text" size="small" @click="deleteComboGiftPack(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
      </el-pagination>
    </div>

    <!-- 礼包商品状态更新 -->
    <el-dialog :visible.sync="selectStatus" :modal="false" width="60%">
      <div slot="title">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <!-- 左侧文本 -->
          <span class="fs-3 form-label fw-bold" style="flex-grow: 1;">礼包中商品状态被改变</span>
          <!-- 右侧按钮 -->
          <span>
            <el-button class="me-19" type="primary" size="small" @click="updateStatus">全部已读</el-button>
          </span>
        </div>
      </div>

      <div style="text-align: left;">
        <span>通知状态：</span>
        <el-select v-model="comboParams.status" placeholder="请选择" @visible-change="getComboGiftPackAndGoods()"
                   class="el-select--small me-3" >
          <el-option v-for="(item,index) in dataStatus" :label="item.name" :value="item.status" :key="index">
          </el-option>
        </el-select>
        <span>通知类型：</span>
        <el-select v-model="comboParams.type" placeholder="请选择" @visible-change="getComboGiftPackAndGoods()"
                   class="el-select--small">
          <el-option v-for="(item,index) in dataType" :label="item.name" :value="item.type" :key="index">
          </el-option>
        </el-select>
      </div>

      <!-- 列表数据展示 -->
      <el-table class="m-auto" :data="comboStatus" style="width: 100%" height="70vh"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                v-loading="statusLoading">
        <el-table-column label="礼包图片" fixed width="100" align="center">
          <template slot-scope="props">
            <img style="width:70px;height:70px;" :src="props.row.imageUrl" alt="礼包图片">
          </template>
        </el-table-column>
        <el-table-column fixed label="礼包名称" width="170" align="center">
          <template slot-scope="props">
            <el-button @click="$router.push({name: 'AddOrUpdateComboGiftPack',params: { id: props.row.comboId }})">{{ props.row.comboName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="skuName" label="商品名称" width="200" align="center">
        </el-table-column>
        <el-table-column label="商品图片" fixed width="100" align="center">
          <template slot-scope="props">
            <img style="width:70px;height:70px;" :src="props.row.picUrl" alt="商品图片">
          </template>
        </el-table-column>

        <el-table-column label="状态" width="100" align="center">
          <template slot-scope="props">
            <span v-if="props.row.status === 0" class="text-danger">未读</span>
            <span v-else class="text-success">已读</span>
          </template>
        </el-table-column>

        <el-table-column label="类型" width="100" align="center">
          <template slot-scope="props">
            <span v-if="props.row.type === 1" class="text-danger">商品下架</span>
            <span v-if="props.row.type === 2" class="text-warning">厂供发货</span>
            <span v-if="props.row.type === 33" class="text-success">有货</span>
            <span v-if="props.row.type === 34" class="text-danger">无货</span>
            <span v-if="props.row.type === 36" class="text-warning">预定</span>
          </template>
        </el-table-column>

        <el-table-column label="下架时间" width="100" align="center">
          <template slot-scope="props">
            <label class="fs-7 form-label fw-bold">
              {{ props.row.createTime }}
            </label>
          </template>
        </el-table-column>

        <el-table-column label="已读时间" width="100" align="center">
          <template slot-scope="props">
            <label v-if="props.row.status === 1" class="fs-7 form-label fw-bold text-danger">
              {{ props.row.updateTime }}
            </label>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          ref="pagination"
          style="margin-top: 10px;text-align: center"
          @size-change="statusHandleSizeChange"
          @current-change="statusHandleCurrentChange"
          :current-page.sync="comboParams.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="comboParams.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="comboTotalcount">
      </el-pagination>
    </el-dialog>

  </div>
</template>
<script>

import {mapActions} from "vuex";

import {
  deleteComboGiftPackApi,
  getComboGiftPackAndGoodsApi,
  getComboGiftPackListApi,
  updateComboAndGoodsStatusApi
} from "@/api/backend/comboGiftPackApi";

export default {
  name: 'ComBo',
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      dataStatus: [
        {
          name: "未读",
          status: 0
        },
        {
          name: "已读",
          status: 1
        }
      ],
      dataType: [
        {
          name: "商品下架",
          type: 1
        },
        {
          name: "厂供配送",
          type: 2
        },
        {
          name: "商品有货",
          type: 33
        },
        {
          name: "商品无货",
          type: 34
        },
        {
          name: "商品预定",
          type: 36
        },
      ],
      statusUnread: 0,
      statusLoading: true,
      selectStatus: false,
      comboTotalcount: 999,
      comboStatus: [],
      comboParams: {
        size: 10,
        current: 1,
        status: '',
        type: '',
      },
      loading: true,
      //复选框id集合
      ids: [],
      // 商品集合
      dialogVisible: false,
      totalCount: 999,
      optionss: [
        {
          value: '1',
          label: '自有商品池'
        },
        {
          value: '2',
          label: '全国商品池'
        }
      ],
      paramss: {
        telNum: "",
        title: "",
        type: "1",
        categoryId1: "",
        categoryId2: "",
        size: 10,
        current: 1,
        isJd: 2
      },
      categoryId1: [],
      categoryId2: [],
      selGoodsList: [],
      idArr: [],
      jdType: [
        {
          type: 1,
          name: "京东商品"
        },
        {
          type: 2,
          name: "自有商品"
        }
      ],
      // 价格
      priceType: [
        {
          type: 1,
          name: "展示价"
        },
        {
          type: 2,
          name: "市场价"
        },
        {
          type: 3,
          name: "分销价"
        },
        {
          type: 4,
          name: "销售价"
        }
      ],
      priceValue: "",
      price: {
        minPrice: "",
        maxPrice: ""
      },
      // 更多查询
      moreConditions: false,
      // 筛选条件
      params: {
        id: '',
        current: 1,
        size: 10,
        name: '',
        giftCode: '',
        isShow: '',
        minGiftShowPrice: '', // 展示价
        maxGiftShowPrice: '',
        minGiftPrice: '',  // 市场价
        maxGiftPrice: '',
        minGiftSupplyPrice: '',  // 供应价
        maxGiftSupplyPrice: '',
        minGiftSalePrice: '',  // 销售价
        maxGiftSalePrice: '',
        minGiftTotalGoods: '',
        maxGiftTotalGoods: '',
        maxGiftRate: '',
        minGiftRate: '',
      },
      // 组合礼包数据
      tableData: [],
    };
  },
  // 组件的方法
  methods: {
    /* 列表查询 */
    async getComboGiftPackList() {
      //this.checkboxInput = false;
      this.loading = true;
      let {data} = await getComboGiftPackListApi(this.params);
      this.loading = false;
      this.tableData = data.rows;
      this.totalCount = data.total;
      // eslint-disable-next-line no-unused-vars
      let a = true;
      this.tableData.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.loading = false;
      //this.checkboxInput = a;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getComboGiftPackList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getComboGiftPackList();
    },
    /* 条件查询 */
    search() {
      // 展示价
      if (this.priceValue === 1) {
        this.params.minGiftShowPrice = this.price.minPrice;
        this.params.maxGiftShowPrice = this.price.maxPrice;
      } else if (this.priceValue === 2) {
        //  市场价
        this.params.minGiftPrice = this.price.minPrice;
        this.params.maxGiftPrice = this.price.maxPrice;
      } else if (this.priceValue === 3) {
        this.params.minGiftSupplyPrice = this.price.minPrice;
        this.params.maxGiftSupplyPrice = this.price.maxPrice;
      } else if (this.priceValue === 4) {
        this.params.minGiftSalePrice = this.price.minPrice;
        this.params.maxGiftSalePrice = this.price.maxPrice;
      }
      this.params.current = 1
      this.handleCurrentChange(1)
      // 获取数据
      this.getComboGiftPackList();
    },
    /*重置*/
    reset() {
      this.priceValue = "";
      this.price = {
        minPrice: "",
        maxPrice: ""
      };
      this.params = {
        current: 1,
        size: 10,
        name: '',
        giftCode: '',
        isShow: '',
        minGiftShowPrice: '',
        maxGiftShowPrice: '',
        minGiftPrice: '',
        maxGiftPrice: '',
        minGiftSupplyPrice: '',
        maxGiftSupplyPrice: '',
        minGiftSalePrice: '',
        maxGiftSalePrice: '',
        minGiftTotalGoods: '',
        maxGiftTotalGoods: '',
        maxGiftRate: '',
        minGiftRate: '',
      };
    },
    /* 新增组合礼包 */
    ...mapActions("goods", ["setGoodsList"]),
    addGoods() {
      this.setGoodsList([])
      this.$router.push({name: 'AddOrUpdateComboGiftPack'});
      location.reload();
    },
    /* 删除组合礼包*/
    async deleteComboGiftPack(item) {
      if (item) {
        this.ids = [item];
      }
      let arr = Array.from(this.ids)
      await deleteComboGiftPackApi(arr);
      this.$message.success('删除成功');
      this.getComboGiftPackList()
    },
    /* 复选框数据 */
    // 复选框 将id 组成集合
    handleSelectionChange(val) {
      let arr = []
      val.forEach(item => {
        arr.push(item.id)
      })
      this.ids = arr;
    },
    /* 礼包商品改变通知 */
    async openDialog() {
      await this.getComboGiftPackAndGoods();
      this.selectStatus = true;
    },
    async getComboGiftPackAndGoods() {
      this.statusLoading = true;
      let {data} = await getComboGiftPackAndGoodsApi(this.comboParams);
      this.statusLoading = false;
      this.comboStatus = data.rows;
      this.comboTotalcount = data.total;
      this.statusUnread = data.pages;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    statusHandleSizeChange(val) {
      this.comboParams.size = val;
      this.getComboGiftPackAndGoods();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    statusHandleCurrentChange(val) {
      this.comboParams.current = val;
      this.getComboGiftPackAndGoods();
    },
    async updateStatus() {
      await updateComboAndGoodsStatusApi();
      this.getComboGiftPackAndGoods();
    },
    sortChange(data) {
      console.log(data)
      const { prop, order } = data
      console.log(prop)
      console.log(order)
    }
  },
  // 在created钩子中执行的操作
  created() {
    this.getComboGiftPackList();
    this.getComboGiftPackAndGoods();
  },
  // activated() {
  //   this.getComboGiftPackList();
  //   this.getComboGiftPackAndGoods();
  // },
}
</script>
<style scoped>
.content_box {
  background: #fff;
  padding: 20px;
}

.mg-r-20 {
  margin-right: 20px;
}

.flex_no {
  display: flex;
  align-items: center;
}

.flex_sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


</style>
<!-- 组件的样式 -->
<style>
.switchBox .el-form-item__content {
  margin-left: 0 !important;
}
</style>