<!-- 组件模板内容 -->
<template>
  <div style="padding-top: 10px">
    <!-- 抬头 -->
    <div class="content_box d-flex align-items-center position-relative mb-5 mt-5"
         style="max-width: 98% !important; margin: 0 auto; border-radius: 10px; padding: 10px 20px;">
      <el-page-header @back="$router.push({path:'/combo'})" :content=" goodsTableData.id ? '编辑组合礼包' : '新增组合礼包'" class="fw-bold"></el-page-header>
      <div class="ml-auto" style="position: absolute; right: 30px">
        <el-button size="small" type="primary" @click="addGoods">挑选商品</el-button>
      </div>
    </div>
    <div class="content_box align-items-center position-relative mb-5 mt-5"
         style="max-width: 98% !important; margin: 0 auto; border-radius: 10px; min-height: 550px !important;">
      <div class="mt-10 mb-7" style="margin-left: 80px;">
        <label class="fs-3 form-label fw-bold">商品清单</label>
      </div>
      <!-- 表格 -->
      <el-table class="m-auto" v-if="goodsListHiden" :data="goodsTableData.goodsList" style="width: 87%" height="50vh">
        <el-table-column label="商品图片" fixed width="100">
          <template slot-scope="props">
            <img style="width:70px;height:70px;" class="symbol-label lozad" v-if="props.row.id && props.row.picUrl.indexOf('http') == 0" :src="`${props.row.picUrl}`"/>
            <img style="width:70px;height:70px;" class="symbol-label lozad" v-else :src="`https://www.1c-1g.cn${props.row.picUrl}`"/>
          </template>
        </el-table-column>
        <el-table-column fixed label="商品名称" width="260" align="center">
          <template slot-scope="props">
            <div class="d-flex align-items-center">
              <div
                  class="d-flex justify-content-start flex-column">
                <a :href="`https://m.jd.com/product/${props.row.skuId}.html`"
                   class="text-gray-800 text-hover-primary fs-5"
                   data-kt-ecommerce-product-filter="product_name"
                   target="_blank">{{ props.row.title }}</a>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isJd" label="商品来源" width="130" align="center">
          <template slot-scope="props">
            <span v-if="props.row.isJD === 1">京东精品</span>
            <span v-else>平台优选</span>
          </template>
        </el-table-column>
        <el-table-column label="商品分类" width="150" align="center">
          <template slot-scope="props">
            <span v-if="props.row.categoryName" v-html="props.row.categoryName"></span>
            <template v-else>
              <span>{{ props.row.categoryId1Name }}</span><br/>
              <span>{{ props.row.categoryId2Name }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="市场价" width="120" align="center">
          <template slot-scope="props">
            <span> {{ props.row.price | format }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="supplPrice" label="供货价" width="120" align="center">
          <template slot-scope="props">
            <span> {{ props.row.supplPrice | format }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="库存" width="100" align="center">
          <template slot-scope="props">
            <span v-if="props.row.isJD == 2">{{ props.row.stock }}</span>
            <span v-else>99</span>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="数量" width="100" align="center">
          <template slot-scope="props">
            <el-input v-model="props.row.number" @change="updateNumber" placeholder="请输入价格"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" width="100" align="center">
          <template slot-scope="props">
            <el-input v-model="props.row.sort" placeholder="请输入排序"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="状态" width="100" align="center">
          <template slot-scope="props">
            <label v-if="props.row.isJD == 2 && props.row.isShow.startsWith('上架')" v-html="props.row.isShow"
                   class="fs-7 form-label fw-bold text-success"></label>
            <label v-if="props.row.isJD == 1 && props.row.status != 2" class="fs-7 form-label fw-bold text-success">
              已上架
            </label><br>
            <label v-if="props.row.isJD == 1 && props.row.status != 2" class="fs-7 form-label fw-bold text-success">
              {{ props.row.pcTime.split(" ")[0] }}
            </label>
            <label v-if="props.row.isJD == 1 && props.row.status == 2" class="fs-7 form-label fw-bold text-danger">
              已上架
            </label><br>
            <label v-if="props.row.isJD == 1 && props.row.status == 2" class="fs-7 form-label fw-bold text-danger">
              {{ props.row.pcTime }}
            </label>
          </template>
        </el-table-column>
        <el-table-column fixed="right" prop="buyType" label="起购数量" width="190" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="140" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="delGroupGoods(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="mt-10 mb-7" style="margin-left: 80px;">
        <label class="fs-3 form-label fw-bold">礼包信息</label>
      </div>

      <!-- 表单 -->
      <el-form ref="goodsTableData" :model="goodsTableData" label-width="100px" :rules="rules" class="demo-ruleForm" label-position="top">
        <el-row>
          <el-col :span="4" style="margin-left: 200px !important;">
            <el-form-item label="礼包名称" prop="name" class="fw-bold fs-10">
              <el-input v-model="goodsTableData.name" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left: 100px !important;">
            <el-form-item label="礼包市场价" prop="giftPrice" class="fw-bold">
              <el-input v-model="goodsTableData.giftPrice" type="text" @input="handleInputPrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left: 100px !important;">
            <el-form-item label="礼包供货价" prop="giftSupplyPrice" class="fw-bold">
              <el-input v-model="goodsTableData.giftSupplyPrice" type="text" @input="handleInputPrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" style="margin-left: 100px !important;">
            <el-form-item label="运费" prop="giftSupplyPrice" class="fw-bold">
              <el-input v-model="freight" type="text" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left: 100px !important;">
            <el-form-item label="最终供货价" prop="giftSupplyPrice" class="fw-bold">
              <el-input v-model="supplPriceAndFreight" type="text" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- 主图 -->
          <el-col :span="4" style="margin-left:200px !important;">
            <el-form-item label="礼包主图" prop="giftCode" required class="fw-bold">
              <div>
                <div class="text-center">
                  <div
                      class="image-input image-input-outline image-input-placeholder mb-3"
                      :class="goodsTableData.imageUrl != ''
                        ? `image-input-empty`
                        : `image-input-changed`"
                      data-kt-image-input="true"
                      :style="{ cursor: goodsTableData.imageUrl !== '' ? 'pointer' : 'default' }"
                  >
                    <div
                        class="image-input-wrapper w-150px h-150px"
                        :style="`background-image: url(${goodsTableData.imageUrl})`"
                    ></div>
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="上传主图"
                    >
                      <i class="ki-outline ki-pencil fs-7"></i>
                      <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="uploadFileAvatar"
                      />
                      <input type="hidden" name="avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        @click="romverAvatar"
                        title="删除主图"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin-left: 200px !important;">
            <el-form-item label="后台分类" prop="category" class="fw-bold">
              <el-cascader v-model="category" v-if="hidenCategory" :props="categoryTree" placeholder="请选择后台分类" ref="category" style="width: 300px !important;"
                           @change="handleCategoryChanges"></el-cascader>
            </el-form-item>
            <el-form-item label="礼包编码" prop="giftCode" class="fw-bold">
              <el-input v-model="goodsTableData.giftCode" type="text" maxlength="30" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin-left:200px !important;">
            <el-form-item label="礼包状态" prop="type" class="fw-bold">
              <el-radio-group v-model="goodsTableData.isShow">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="2">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 轮播图 -->
        <el-form-item label="轮播图" prop="carousel" style="margin-left: 150px !important;" required class="fw-bold">
          <el-button @click="getCarouselImage" type="primary" size="mini">获取商品轮播图</el-button>
          <div class="card card-flush py-4">
            <div style="margin: 20px;display: flex;">
              <div class="imgList">
                <div
                    v-for="(item, index) in imgList"
                    :key="index"
                    class="imgItem"
                >
                  <img width="300px" alt="" :src="item"/>
                  <button
                      @click="removeImages(item, index)"
                      class="removeImage"
                      type="button"
                  >
                    <i class="el-icon-close"></i>
                  </button>
                </div>
              </div>
              <UploadFile
                  moduleName="comboGiftPackCarousel"
                  accept=".png,.jpg,.jpeg"
                  @upload-success="uploadSuccess"
                  :hidenList="false"
              ></UploadFile>
            </div>
          </div>
        </el-form-item>

        <!-- 详情图 -->
        <el-form-item label="详情图" prop="detail" required style="margin-left: 150px !important;" class="fw-bold" v-loading="loadings"
                      element-loading-text="拼命加载中"
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.8)">
          <div class="card card-flush py-4">
            <div style="margin: 20px">
              <Tinymce
                  v-if="status == 0"
                  ref="Tinymce"
                  v-model="goodsTableData.giftDetail"
                  :height="400"
              >
                <el-button @click="getDetailImage" type="primary" size="mini">获取商品详情</el-button>
              </Tinymce>
            </div>
          </div>
        </el-form-item>
        <el-form-item class="d-flex justify-content-end">
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 弹窗 挑选商品 -->
    <el-dialog title="请挑选商品到礼包" :visible.sync="selectProduct" :modal="false" width="95%">
      <el-row :gutter="20">
        <el-col :span="18">
          <div>
            <div style="width: 100%">
              <div class="card">
                <div class="card-body" style="padding: 5px 5px !important;">
                  <div class="d-flex mb-3">
                    <div class="col-lg-2 me-2">
                      <label class="fs-4 form-label fw-bold">商品池</label>
                      <br/>
                      <el-select v-model="paramss.type" placeholder="请选择" @change="shaixuan">
                        <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-lg-2" v-if="paramss.type==2">
                      <label class="fs-4 form-label fw-bold">商品来源</label>
                      <br/>
                      <el-select v-model="paramss.isJd" placeholder="请选择" @change="shaixuan">
                        <el-option v-for="item in jdType" :key="item.type" :label="item.name"
                                   :value="item.type">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-lg-9" v-if="paramss.type==2">
                      <label class="fs-4 form-label fw-bold">收货地址</label>
                      <br/>
                      <span>省：</span>
                      <el-select class="me-2" v-model="paramss.provinceId" clearable placeholder="请选择省" @visible-change="getAddressList(0,0)">
                        <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name"
                                   :value="`${item.kid}`"></el-option>
                      </el-select>
                      <span>市：</span>
                      <el-select class="me-2" v-model="paramss.cityId" clearable placeholder="请选择市" :disabled="!paramss.provinceId"
                                 @visible-change="getAddressList(paramss.provinceId,1)">
                        <el-option v-for="(item,index) in cityList" :key="index" :label="item.name"
                                   :value="item.kid"></el-option>
                      </el-select>
                      <span>区/县：</span>
                      <el-select class="me-3" v-model="paramss.countyId" clearable placeholder="请选择区/县" :disabled="!paramss.cityId"
                                 @visible-change="getAddressList(paramss.cityId,2)">
                        <el-option v-for="(item,index) in countyList" :key="index" :label="item.name"
                                   :value="item.kid"></el-option>
                      </el-select>
                      <el-button size="small" type="primary" plain @click="clearAddress">地址清空</el-button>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="position-relative w-md-300px me-md-2">
                      <!--<span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                            <rect height="24" width="24" x="0" y="0"></rect>
                            <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>-->
                      <!--<input id="sTitle" v-model="paramss.title" class="form-control form-control-solid ps-10"
                             name="shopName" placeholder="商品名称" type="text" value=""/>-->
<!--                      <el-autocomplete
                          class=" w-md-150px"
                          v-model="paramss.title"
                          :fetch-suggestions="querySearchAsync"
                          placeholder="请输入内容"
                          @select="handleSelect"
                      ></el-autocomplete>-->
                      <el-input v-model="paramss.title" class="me-2" style="width: 40%;" clearable
                                placeholder="商品名称" type="text" value=""/>
                      <el-input v-model="paramss.skuId" style="width: 40%;" clearable
                             placeholder="商品ID" type="text" value=""/>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="btn btn-primary me-5 btn-sm" @click="shaixuan">
                        查询
                      </div>
                      <div class="btn btn-secondary me-5 btn-sm" @click="resetBtn">
                        重置
                      </div>
                      <a id="kt_horizontal_search_advanced_link" class="btn btn-link me-5" data-bs-toggle="collapse"
                         href="#kt_advanced_search_form">高级查询</a>
                      <div class="btn btn-success  btn-sm" @click="openLink" v-loading="linkLoading" element-loading-text="拼命加载中"
                           element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                        打开此列商品京东链接
                      </div>
                      <div class="text btn-sm fs-5 form-label fw-bold">
                        库存最低发货数量：
                        <el-input style="width: 30% !important;" v-model="paramss.stockCheck" type="number" maxlength="11" show-word-limit></el-input>
                      </div>
                    </div>
                  </div>
                  <!--begin::高级搜索-->
                  <div id="kt_advanced_search_form" class="collapse">
                    <div class="separator separator-dashed mt-9 mb-6"></div>
                    <div class="row g-8 mb-8">
                      <div class="col-lg-3">
                        <label class="fs-6 form-label">一级分类</label>
                        <br/>
                        <el-select v-model="paramss.categoryId1" placeholder="请选择" @change="getCategory(1)">
                          <el-option v-for="item in categoryId1" :key="item.categoryId" :label="item.fullName"
                                     :value="item.categoryId">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-lg-3">
                        <label class="fs-6 form-label">二级分类</label>
                        <br/>
                        <el-select v-model="paramss.categoryId2" placeholder="请选择" @change="getCategory(2)">
                          <el-option v-for="item in categoryId2" :key="item.categoryId" :label="item.fullName"
                                     :value="item.categoryId">
                          </el-option>
                        </el-select>
                      </div>

                      <div class="col-lg-3" v-if="paramss.type == 1">
                        <label class="fs-6 form-label fw-bolder text-dark"
                        >库存区间</label
                        >
                        <div class="row">
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                type="text" id="minPoolSupplPrice" name="minPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minStock" placeholder="最小值" value=""
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                type="text"
                                id="maxPoolSupplPrice"
                                name="maxPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxStock"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                                value=""
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3" v-if="paramss.type == 2">
                        <label class="fs-6 form-label fw-bolder text-dark"
                        >分销价区间</label
                        >
                        <div class="row">
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                type="text" id="minPoolSupplPrice" name="minPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minPoolSupplPrice" placeholder="最小值" value=""
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                type="text"
                                id="maxPoolSupplPrice"
                                name="maxPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxPoolSupplPrice"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                                value=""
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <label class="fs-6 form-label fw-bolder text-dark"
                        >利润率区间</label
                        >
                        <div class="row">
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                v-if="paramss.type==1"
                                type="text"
                                id="minRightRate"
                                name="minRightRate"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最小值"
                                value=""
                            />
                            <input
                                v-if="paramss.type==2"
                                type="text"
                                id="minQuanRightRate"
                                name="minQuanRightRate"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minQuanRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最小值"
                                value=""
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                v-if="paramss.type==1"
                                type="text"
                                id="maxRightRate"
                                name="maxRightRate"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                                value=""
                            />
                            <input
                                v-else
                                type="text"
                                id="maxQuanRightRate"
                                name="maxQuanRightRate"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxQuanRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                                value=""
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" v-loading="loading" element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
              <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th>
                  SkuId
                </th>
                <th class="min-w-300px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                    aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                  商品信息
                </th>
                <th>
                  分类
                </th>
                <th class="text-center">
                  市场价
                </th>
                <th class="text-center">
                  分销价
                </th>
                <th class="text-center" v-if="paramss.type==1">
                  销售价
                </th>
                <th class="text-center">
                  利润率
                </th>
                <th class="text-center">
                  运费模板
                </th>
                <th class="text-center">
                  库存
                </th>
                <th class="text-center">
                  商品来源
                </th>
                <th class="text-center">
                  评论数
                </th>
                <th class="text-center">
                  起购数量
                </th>
                <th class="text-center">
                  能否发货
                </th>
                <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 100px;">
                  操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item, index) in selGoodsList" :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                  :key="index">
                <td class="text-center" v-if="paramss.type==2 && item.isJD == 1">{{ item.skuId }}</td>
                <td>
                  <div class="d-flex align-items-center">
                    <!--<a href="javascript:void(0);" class="symbol symbol-50px">
                      <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0" :src="`${item.picUrl}`"/>
                      <img class="symbol-label lozad" v-else :src="`https://www.1c-1g.cn${item.picUrl}`"/>
                    </a>-->
                    <div class="ms-5" v-if="item.picUrl.indexOf('/Uploads') ==0">
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-50px me-5">
                          <img :src="`https://www.1c-1g.cn${item.picUrl}`"
                               alt="" class="">
                        </div>
                        <div
                            class="d-flex justify-content-start flex-column">
                          <a :href="`${item.jdUrl}`"
                             class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                             target="_self">{{ item.title }}</a>
                        </div>
                      </div>
                    </div>
                    <div class="ms-5" v-else>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-50px me-5">
                          <img :src="item.picUrl">
                        </div>
                        <div
                            class="d-flex justify-content-start flex-column">
                          <a :href="`https://m.jd.com/product/${item.skuId}.html`"
                             class="text-gray-800 text-hover-primary fs-5"
                             data-kt-ecommerce-product-filter="product_name"
                             target="_blank">{{ item.title }}</a>
                        </div>
                      </div>
                    </div>
                    <!--<div class="ms-5">
                      <a href="" class="text-gray-800 text-hover-primary fs-5"
                         data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                    </div>-->
                  </div>
                </td>
                <td>
                  <div v-html="item.categoryName"></div>
                </td>
                <td class="text-center">{{ item.price | format }}</td>
                <td class="text-center">{{ item.supplPrice | format }}</td>
                <td class="text-center" v-if="paramss.type==1">{{ item.salePrice | format }}</td>
                <td class="text-center" v-html="item.rightRate" v-if="paramss.type==1"></td>
                <td class="text-center" v-html="item.maxRightRate" v-else></td>
                <td class="text-center" v-if="paramss.type==1">{{ item.freightId }}</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">厂供模板</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">京东模板</td>
                <td class="text-center" v-if="paramss.type==1">{{ item.stock || 0 }}</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">{{ item.stock || 0 }}</td>
                <td class="text-center text-success" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 33">该地区有货
                </td>
                <td class="text-center text-danger" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 34">该地区无货
                </td>
                <td class="text-center text-warning" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 36">该地区需要预定
                </td>
                <td class="text-center text-primary" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === -1">请选择地址
                </td>
                <td class="text-center text-primary" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 2004">不在用户商品池
                </td>
                <td class="text-center" v-if="paramss.type==1">{{ item.type }}</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">京东</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">自有</td>
                <td class="text-center" v-if="item.isJD == 1">{{ item.commentDefaultCount || 0 }}</td>
                <td class="text-center">{{ item.buyType }}</td>
                <td class="text-center text-success" v-if="item.deliveryAddressStatus == 1">可发货</td>
                <td class="text-center text-danger" v-if="item.deliveryAddressStatus == 2">不可发货</td>
                <td class="text-center text-primary" v-if="item.deliveryAddressStatus == 999">请选择地址</td>
                <!-- 操作 -->
                <td class="text-center">
                  <el-link type="success" @click="addGroupGoods(item)" v-if="!idsArr.includes(item.id)">添加</el-link>
                </td>
              </tr>
              </tbody>
            </table>
            <el-pagination
                class="table-responsive" v-loading="pageLoading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                ref="pagination"
                style="margin-top: 10px;text-align: center"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="paramss.current"
                :page-sizes="[10, 30, 50, 100, 500, 1000]"
                :page-size="paramss.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="display: flex;justify-content:space-between;">
            <h4>已选商品<span style="font-size:12px;margin-left: 5px;">(共选择 {{ goodList.length }} 个商品)</span></h4>
            <el-button type="danger" @click="clearSelectedGoods" v-if="goodList.length!=0" size="small">清空已选商品</el-button>
          </div>
          <div class="table-responsive">
            <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
              <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-200px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                    aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                  商品信息
                </th>
                <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 80px">
                  操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item, index) in goodList" :class="{ old: index % 2 == 0, even: index % 2 != 0 }" :key="index">
                <td>
                  <div class="ms-5">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                        <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0" :src="`${item.picUrl}`"/>
                        <img class="symbol-label lozad" v-else :src="`https://www.1c-1g.cn${item.picUrl}`"/>
                      </div>
                      <div
                          class="d-flex justify-content-start flex-column">
                        <a :href="`https://m.jd.com/product/${item.skuId}.html`"
                           class="text-gray-800 text-hover-primary fs-5"
                           data-kt-ecommerce-product-filter="product_name"
                           target="_blank">{{ item.title }}</a>
                      </div>
                    </div>
                  </div>
<!--                  <div class="d-flex align-items-center">
                    <a href="javascript:void(0);" class="symbol symbol-50px">
                      <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0" :src="`${item.picUrl}`"/>
                      <img class="symbol-label lozad" v-else :src="`https://www.1c-1g.cn${item.picUrl}`"/>
                    </a>
                    <div class="ms-5">
                      <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                         data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                    </div>
                  </div>-->
                </td>

                <!-- 操作 -->
                <td class="text-end">
                  <el-link type="danger" @click="delGroupGoods(item)">删除</el-link>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </el-col>
      </el-row>

    </el-dialog>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Tinymce from "@/components/Tinymce/index.vue";

import {getCategoryList} from "@/api/backend/categoryApi";
import {getChooseGoodsListApi, getChooseGoodsListCountApi} from "@/api/backend/chooseGoodsApi";
import {obtainCarouselImageApi, obtainDetailImageApi, uploadFileApi} from "@/api/backend/uploadFileApi";
import {getSjGoodsListApi, getSjGoodsListCountApi} from "@/api/backend/goodsManageApi";
//import {getChooseGoodsListApi, getChooseGoodsListCountApi} from "@/api/backend/chooseGoodsApi";
import {addComboGiftPackApi, editComboGiftPack, getComboGiftPackInfoApi} from "@/api/backend/comboGiftPackApi";
import {findBackendJdRegionApi} from "@/api/backend/addressApi";


export default {
  components: {Tinymce},
  computed: {
    ...mapState("goods", ["goodList"]),
    // 根据isJd将不同id放进不同数组 先过滤 再分组
    idsArr() {
      return this.goodList.filter(e => e.isJD == this.paramss.isJd).map(e => e.id);
    },
  },
  name: '',
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      freight: 0,
      supplPriceAndFreight: 0,
      /*收货地址*/
      countyList: [],
      cityList: [],
      provinceList: [],
      /* 自动补全 */
      suggestions: [],
      timeout: null,

      first: true,
      selectProduct: false,
      loading: true,
      pageLoading: true,
      linkLoading: false,
      loadings: false,
      // 详情图
      status: 0,
      // 表单验证规则
      rules: {
        name: [
          {required: true, message: '请输入礼包名称', trigger: 'blur'},
          {min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur'}
        ],
        giftCode: [
          {min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur'}
        ],
        category: [
          {required: true, message: '请选择分类数据', trigger: 'blur'}
        ],
        giftSupplyPrice: [
          {required: true, message: '请输入礼包供应价', trigger: 'blur'},
          {pattern: /^(?!0+(\.0+)?$)([1-9]\d*|0)(\.\d+)?$/, message: '请输入大于0的数字', trigger: 'blur'}
        ],
        giftPrice: [
          {required: true, message: '请输入礼包市场价', trigger: 'blur'},
          {pattern: /^(?!0+(\.0+)?$)([1-9]\d*|0)(\.\d+)?$/, message: '请输入大于0的数字', trigger: 'blur'}
        ],
      },
      // 商品数据
      goodsData: [{
        id: "",
        title: "",
        detail: "",
        picUrl: "",
        stock: "",
        startNum: "",
        supplPrice: "",
        showPrice: "",
        isJd: "",
        number: ""
      }],
      // 组合礼包数据
      goodsTableData: {
        id: "",
        name: "",
        isShow: 1,
        imageUrl: "",
        giftCode: "",
        giftPrice: "",
        giftSupplyPrice: "",
        giftSalePrice: "",
        giftShowPrice: 0,
        giftTotalGoods: "",
        stock: "",
        giftRate: "",
        showSingle: 1,
        staffId: "",
        giftDetail: "",
        categoryId1: "",
        categoryId2: "",
        categoryId3: "",
        categoryId1Name: "",
        categoryId2Name: "",
        categoryId3Name: "",
        goodsList: [],
        carouselList: [],
      },
      // 分类数据
      category: [],
      categoryTree: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level} = node;
          if (level === 0) {
            let {data} = await getCategoryList({
              parentId: 0
            });
            let nodes = data.list.map(item => ({
              value: item.categoryId,
              label: item.fullName,
              leaf: level >= 1
            }));
            resolve(nodes);
          } else {
            let {data} = await getCategoryList({
              parentId: node.value
            });
            let nodes = data.list.map(item => ({
              value: item.categoryId,
              label: item.fullName,
              leaf: level >= 2,
            }));
            resolve(nodes);
          }
        }
      },
      /* 弹窗实体 */
      // 请求参数
      paramss: {
        skuId: "",
        isTwoShow: 1,
        telNum: "",
        title: "", // 京东是sTitle 自营是title
        type: "2",
        categoryId1: "",
        categoryId2: "",
        size: 10,
        current: 1,
        isJd: 1,
        maxPrice: "",
        minPrice: "",
        minRightRate: "",
        maxRightRate: "",
        minQuanRightRate: "",
        maxQuanRightRate: "",
        minPoolSupplPrice: "",
        maxPoolSupplPrice: "",
        maxStock: "",
        minStock: "",
        cityId: "",
        provinceId: "",
        countyId: "",
        stockCheck: ""
      },
      idArr: [],
      categoryId1: [],
      categoryId2: [],
      selGoodsList: [],
      goodsListDialog: false,
      totalCount: 0,
      hidenCategory: true,
      goodsListHiden: true,
      // 商品池
      optionss: [
        {
          value: '1',
          label: '自有商品池'
        },
        {
          value: '2',
          label: '全国商品池'
        }
      ],
      // 商品来源
      jdType: [
        {
          type: 1,
          name: "京东商品"
        },
        //{
        //  type: 2,
        //  name: "厂供商品"
        //}
      ],
      imgList: [],
    };
  },

  // 组件的方法
  methods: {
    /* 主体方法 */
    // 展示价 更新 汇总加到 礼包数据中
    updateData() {
      let totalShowPrice = this.goodsTableData.goodsList.reduce((sum, item) => sum + parseFloat(item.showPrice * (item.number || 1) || 0), 0);
      //this.goodsTableData.giftShowPrice = (Math.ceil(totalShowPrice * 100) / 100).toFixed(2);
      this.goodsTableData.giftShowPrice = totalShowPrice.toFixed(2);
    },
    updateNumber() {
      let totalShowPrice = this.goodsTableData.goodsList.reduce((sum, item) => sum + parseFloat(item.showPrice * (item.number || 1) || 0), 0);
      const totalPrice = this.goodsTableData.goodsList.reduce((sum, item) => sum + parseFloat(item.price * (item.number || 1) || 0), 0);
      const totalSupplPrice = this.goodsTableData.goodsList.reduce((sum, item) => sum + parseFloat(item.supplPrice * (item.number || 1) || 0), 0);
      this.goodsTableData.giftPrice = totalPrice.toFixed(2);
      this.goodsTableData.giftSupplyPrice = totalSupplPrice.toFixed(2);
      if (parseFloat(this.goodsTableData.giftSupplyPrice) >= 59) {
        this.freight = 0;
        this.supplPriceAndFreight = this.goodsTableData.giftSupplyPrice;
      } else {
        this.freight = 6;
        this.supplPriceAndFreight = (parseFloat(this.goodsTableData.giftSupplyPrice) + parseFloat(this.freight)).toFixed(2);
      }
      this.goodsTableData.giftShowPrice = totalShowPrice.toFixed(2);
    },
    // 分类数据查询
    handleCategoryChanges(item) {
      let node = this.$refs.category.getCheckedNodes();
      //this.goodsTableData.categoryId1 = node[0].pathNodes[0].label;  分类名称
      this.goodsTableData.categoryId1 = node[0].pathNodes[0].value;
      this.goodsTableData.categoryId2 = node[0].pathNodes[1].value;
      this.goodsTableData.categoryId3 = node[0].pathNodes[2].value;
      this.goodsTableData.categoryId1Name = node[0].pathNodes[0].label;
      this.goodsTableData.categoryId2Name = node[0].pathNodes[1].label;
      this.goodsTableData.categoryId3Name = node[0].pathNodes[2].label;
    },

    // 输入价格
    handleInputPrice() {
      if (this.goodsTableData.giftShowPrice < 0) {
        this.$message.error("请输入正数");
        this.goodsTableData.giftShowPrice = 0;
      }
      if (this.goodsTableData.giftPrice < 0) {
        this.$message.error("请输入正数");
        this.goodsTableData.giftPrice = 0;
      }
      if (this.goodsTableData.giftSupplyPrice < 0) {
        this.$message.error("请输入正数");
        this.goodsTableData.giftSupplyPrice = 0;
      }
      if (parseFloat(this.goodsTableData.giftSupplyPrice) >= 59) {
        this.freight = 0;
        this.supplPriceAndFreight = this.goodsTableData.giftSupplyPrice;
      } else {
        this.freight = 6;
        this.supplPriceAndFreight = (parseFloat(this.goodsTableData.giftSupplyPrice) + parseFloat(this.freight)).toFixed(2);
      }
    },
    /* 弹窗方法 */
    ...mapActions("goods", ["setGoodsList"]),
    // 打开弹窗
    addGoods() {
      if (!this.goodsTableData.goodsList) {
        this.setGoodsList([]);
      }
      this.selectProduct = true;
      this.initCategory()
      this.resetBtn();
    },
    // 数据筛选
    shaixuan() {
      this.first = true;
      this.paramss.current = 1
      this.handleCurrentChange(1)
      //this.findGoodsList()
    },
    // 筛选数据清空
    resetBtn() {
      this.first = true;
      this.paramss.isTwoShow = ""
      if (this.paramss.isJd == 2) {
        this.paramss.isTwoShow = 1
      }
      this.paramss = {
        telNum: "",
        title: "",
        isTwoShow: this.paramss.isTwoShow,
        type: this.paramss.type,
        categoryId1: "",
        categoryId2: "",
        size: 10,
        current: 1,
        isJd: this.paramss.isJd,
        maxPrice: "",
        minPrice: "",
        minRightRate: "",
        maxRightRate: "",
        minQuanRightRate: "",
        maxQuanRightRate: "",
        minPoolSupplPrice: "",
        maxPoolSupplPrice: "",
        maxStock: "",
        minStock: "",
      }
      this.categoryId2 = []
      this.findGoodsList()
    },
    // 初始化分类
    async initCategory() {
      let {data} = await getCategoryList({
        parentId: 0
      })
      this.categoryId1 = data.list
    },
    // 查询分类数据
    async getCategory(item) {
      this.first = true;
      if (item === 1) {
        this.paramss.categoryId2 = ""
        let parentId = this.paramss.categoryId1
        let {data} = await getCategoryList({
          parentId
        })
        this.categoryId2 = data.list
      }
      this.findGoodsList()
    },
    // 查询商品数据
    async findGoodsList() {
      if (this.paramss.minStock || this.paramss.maxStock
          || this.paramss.minRightRate || this.paramss.maxRightRate) {
        this.paramss.maxPoolSupplPrice = ""
        this.paramss.minPoolSupplPrice = ""
        this.paramss.maxQuanRightRate = ""
        this.paramss.minQuanRightRate = ""
      }
      if (this.paramss.maxPoolSupplPrice || this.paramss.minPoolSupplPrice ||
          this.paramss.maxQuanRightRate || this.paramss.minQuanRightRate) {
        this.paramss.minStock = ""
        this.paramss.maxStock = ""
        this.paramss.minRightRate = ""
        this.paramss.maxRightRate = ""
      }
      /*if (this.paramss.maxQuanRightRate ) {
        this.paramss.maxQuanRightRate = (this.paramss.maxQuanRightRate / 100).toFixed(4)
      }
      if (this.paramss.minQuanRightRate) {
        this.paramss.minQuanRightRate = (this.paramss.minQuanRightRate / 100).toFixed(4)
      }*/

      if (this.paramss.type == 1) {
        this.paramss.isJd = 2;
        this.loading = true;
        this.paramss.isTwoShow = 1;
        this.paramss.isShow = 1;
        let {data} = await getSjGoodsListApi(this.paramss)
        this.selGoodsList = data.rows;
        this.loading = false;
        if (this.first) {
          let {data} = await getSjGoodsListCountApi(this.paramss)
          this.totalCount = data
        }
      } else {
        this.paramss.isJd = 1;
        this.loading = true;
        this.pageLoading = true;
        let json = JSON.parse(JSON.stringify(this.paramss))
        json.sTitle = json.title;
        delete json.title;
        /*let {data} = await getESGoodsListApi(json)
        this.totalCount = data.total*/

        let {data} = await getChooseGoodsListApi(json)
        this.loading = false;
        this.selGoodsList = data.rows;

        if (this.first) {
          let {data} = await getChooseGoodsListCountApi(json)
          this.totalCount = data
        }
        this.pageLoading = false;
      }
      this.first = false
    },
    // 商品分页
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.paramss.size = val;
      this.findGoodsList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.paramss.current = val;
      this.findGoodsList();
    },
    // 添加商品到组合礼包
    async addGroupGoods(item) {
      item.showPrice = "";
      item.sort = "";
      // 将item对象添加进vuex中
      this.goodList.push(item);
      this.goodsTableData.goodsList = JSON.parse(JSON.stringify(this.goodList))
      // 计算 price 和 supplPrice 的总和
      const totalPrice = this.goodsTableData.goodsList.reduce((sum, item) => sum + parseFloat(item.price || 0), 0);
      const totalSupplPrice = this.goodsTableData.goodsList.reduce((sum, item) => sum + parseFloat(item.supplPrice || 0), 0);
      //this.goodsTableData.giftPrice = (Math.ceil(totalPrice * 100) / 100).toFixed(2);
      //this.goodsTableData.giftSupplyPrice = (Math.ceil(totalSupplPrice * 100) / 100).toFixed(2);
      this.goodsTableData.giftPrice = totalPrice.toFixed(2);
      this.goodsTableData.giftSupplyPrice = totalSupplPrice.toFixed(2);
      if (parseFloat(this.goodsTableData.giftSupplyPrice) >= 59) {
        this.freight = 0;
        this.supplPriceAndFreight = this.goodsTableData.giftSupplyPrice;
      } else {
        this.freight = 6;
        this.supplPriceAndFreight = (parseFloat(this.goodsTableData.giftSupplyPrice) + parseFloat(this.freight)).toFixed(2);
      }
    },
    // 删除商品
    delGroupGoods(item) {
      let arr = []
      if (this.goodList) {
        this.goodList.forEach(e => {
          !(e.id === item.id && e.isJd === item.isJd) && arr.push(e)
        })
      }
      this.setGoodsList(arr);
      if (this.goodsTableData.goodsList) {
        let index = this.goodsTableData.goodsList.findIndex(e => e.id == item.id && e.isJd == item.isJd)
        if (index !== -1) {
          this.goodsTableData.goodsList.splice(index, 1);
        }
      }
      this.updateNumber()
    },
    /* 主图相关方法 */
    // 上传主图
    async uploadFileAvatar(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "comboGiftPack");
      let {data} = await uploadFileApi(formData);
      this.goodsTableData.imageUrl = data.fileUrl;
    },
    // 删除主图
    romverAvatar() {
      this.goodsTableData.imageUrl = "";
    },
    // 放大图片被弃用
    showEnlargeImageNoNeed() {
      if (this.goodsTableData.imageUrl !== '') {
        // 创建一个模态框
        const enlargedImage = document.createElement('div');
        enlargedImage.innerHTML = `<img src="${this.goodsTableData.imageUrl}" style="max-width: 100%; max-height: 100%;" />`;
        // 添加样式
        enlargedImage.style.position = 'fixed';
        enlargedImage.style.top = '0';
        enlargedImage.style.left = '0';
        enlargedImage.style.width = '100%';
        enlargedImage.style.height = '100%';
        //enlargedImage.style.background = 'rgba(0, 0, 0, 0.7)';
        enlargedImage.style.display = 'flex';
        enlargedImage.style.alignItems = 'center';
        enlargedImage.style.justifyContent = 'center';
        // 添加点击事件以关闭模态框
        enlargedImage.addEventListener('click', () => {
          document.body.removeChild(enlargedImage);
        });
        // 将模态框添加到 body 中
        document.body.appendChild(enlargedImage);
      }
    },
    /* 轮播图相关的方法 */
    // 上传成功方法
    uploadSuccess(res, fileList) {
      this.imgList.push(res.data.fileUrl);
    },
    //移除图片 不删除oss 但要删除数据库
    async removeImages(item, index) {
      this.imgList.splice(index, 1);
    },
    // 获取商品的轮播图
    async getCarouselImage() {
      if (this.goodList.length > 0) {
        const extractedArray = this.goodList.map(item => {
          //const goodId = item.isJD === '1' ? item.skuId : item.id;
          return {id: item.id, isJd: item.isJD};
        });
        let {data} = await obtainCarouselImageApi(extractedArray);
        this.imgList = this.imgList.concat(data);
      } else {
        this.$message.warning('请先添加商品');
      }
    },
    /* 详情图相关的方法 */
    async getDetailImage() {
      if (this.goodList.length > 0) {
        //this.loadings=true
        //this.status=1;
        const extractedArray = this.goodList.map(item => {
          //const goodId = item.isJD === '1' ? item.skuId : item.id;
          return {id: item.id, isJd: item.isJD};
        });
        let {data} = await obtainDetailImageApi(extractedArray);
        this.goodsTableData.giftDetail = data;
        //this.status=0;
        //this.loadings=false
        this.$refs.Tinymce.reload(data);
      } else {
        this.$message.warning('请先添加商品');
      }
    },
    /* 数据回显 */
    async getComboGiftPackInfo(id) {
      this.hidenCategory = false
      let {data} = await getComboGiftPackInfoApi({id});
      if (data) {
        let arr = [];
        data.goodsList.forEach(e => {
          e.id = e.goodsId
          delete e.goodsId;
          arr.push(e)
        })
        this.setGoodsList(arr)
        this.goodsTableData = data; // 整个数据
        let categoryList = [
          this.goodsTableData.categoryId1,
          this.goodsTableData.categoryId2,
          this.goodsTableData.categoryId3
        ]
        // 分类给值
        this.category = categoryList
        this.hidenCategory = true
        // 轮播图
        this.goodsTableData.carouselList.forEach(item => {
          this.imgList.push(item.carouselUrl);
        })
        if (parseFloat(this.goodsTableData.giftSupplyPrice) >= 59) {
          this.freight = 0;
          this.supplPriceAndFreight = this.goodsTableData.giftSupplyPrice;
        } else {
          this.freight = 6;
          this.supplPriceAndFreight = (parseFloat(this.goodsTableData.giftSupplyPrice) + parseFloat(this.freight)).toFixed(2);
        }
      }
    },

    /* 数据提交 */
    async onSubmit() {
      // 校验数据
      if (!this.dataVerification()) {
        return;
      }
      // 新增 商品列表有值 并且 主键id没值
      try {
        if (!this.goodsTableData.id && this.goodsTableData.goodsList.length > 0) {
          // 将数据重新装进对象中
          this.goodsTableData.goodsList = this.goodsTableData.goodsList.map(item => {
            return {
              id: null, // 根据实际情况选择对应的属性|| item.skuId || ""
              goodsId: item.id,
              comboId: null,
              showPrice: 0,
              isJd: item.isJD,
              sort: item.sort,
              number: item.number
            };
          });
          // 轮播图有值 并且 主键id没值
          if (this.imgList.length > 0) {
            this.goodsTableData.carouselList = this.imgList.map(item => {
              const fileNameWithExtension = item.split('/').pop();
              const fileName = fileNameWithExtension.split('.')[0];
              return {
                id: null,
                comboId: null,
                carouselName: fileName,
                carouselUrl: item
              }
            });
          }
          await addComboGiftPackApi(this.goodsTableData);
          this.$message.success('创建成功');
        }
      } catch (e) {
        this.$message.error('新增礼包失败！请重新输入数据！谢谢');
        this.goodsTableData.goodsList = [];
        this.goodsTableData.giftDetail = null;
        this.goodsTableData.carouselList = [];
        this.imgList = [];
        return;
      }
      // 编辑
      if (this.goodsTableData.id) {
        if (this.goodsTableData.goodsList.length > 0) {
          // 将数据重新装进对象中
          this.goodsTableData.goodsList = this.goodsTableData.goodsList.map(item => {
            return {
              id: null, // 根据实际情况选择对应的属性|| item.skuId || ""
              goodsId: item.id,
              comboId: null,
              showPrice: 0,
              isJd: item.isJD,
              sort: item.sort,
              number: item.number
            };
          });
        }
        // 轮播图有值 并且 主键id没值
        if (this.imgList.length > 0) {
          this.goodsTableData.carouselList = this.imgList.map(item => {
            const fileNameWithExtension = item.split('/').pop();
            const fileName = fileNameWithExtension.split('.')[0];
            return {
              id: null,
              comboId: null,
              carouselName: fileName,
              carouselUrl: item
            }
          });
        }
        await editComboGiftPack(this.goodsTableData);
        this.$message.success('编辑成功');
      }
      this.$router.push({path: '/combo'});
      location.reload();
    },
    // 数据校验
    dataVerification() {
      let data = this.goodsTableData;
      if (!data.giftPrice || data.giftPrice < 0) {
        this.$message.warning('请输入市场价');
        return false;
      }
      if (!data.giftSupplyPrice || data.giftSupplyPrice < 0) {
        this.$message.warning('请输入成本价');
        return false;
      }
      if (!data.goodsList || data.goodsList.length <= 0) {
        this.$message.warning('请挑选商品');
        return false;
      }
      if (data.goodsList.some(row => row.sort === undefined || row.sort === null || row.sort === '')) {
        this.$message.warning('请输入商品展示顺序');
        return false;
      }
      if (!data.imageUrl) {
        this.$message.warning('请选择礼包主图');
        return false;
      }
      if (!this.imgList || this.imgList.length <= 0) {
        this.$message.warning('请选择礼包轮播图');
        return false;
      }
      if (!data.giftDetail) {
        this.$message.warning('请选择礼包详情图');
        return false;
      }
      //检查一级分类
      if (!data.categoryId1) {
        this.$message.warning('请选择分类');
        return false;
      }
      //检查二级分类
      if (!data.categoryId2) {
        this.$message.warning('请选择二级分类');
        return false;
      }
      //检查当存在三级分类是判断是否选择三级分类
      if (data.length != 0 && data.categoryId3 == "") {
        this.$message.warning('请选择三级分类');
        return false;
      }
      return true;
    },
    clearSelectedGoods() {
      this.goodsListHiden = false;
      this.setGoodsList([]);
      this.goodsTableData.goodsList = [];
      this.goodsListHiden = true;
    },
    /**
     * 获取地址
     */
    async getAddressList(id, type) {
      console.log(this.paramss.provinceId)
      let {data} = await findBackendJdRegionApi({
        id
      })
      if (type == 0) {
        this.provinceList = data
      } else if (type == 1) {
        this.cityList = data
      } else if (type == 2) {
        this.countyList = data
      }
    },
    // 清楚地址信息
    clearAddress() {
      this.paramss.provinceId = "";
      this.paramss.cityId = "";
      this.paramss.countyId = "";
    },
    async querySearchAsync(queryString, cb) {
      /*let name = queryString;
      let {data} = await getSuggestionListApi({name})
      let suggestions = data.map((item, index) => {
        return {value: item};
      });
      cb(suggestions);*/
      /*clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(suggestions);
      }, 3000 * Math.random());*/
    },
    handleSelect(item) {
      this.first = true;
      this.paramss.isTwoShow = ""
      if (this.paramss.isJd == 2) {
        this.paramss.isTwoShow = 1
      }
      this.paramss = {
        telNum: "",
        title: item.value,
        isTwoShow: this.paramss.isTwoShow,
        type: this.paramss.type,
        categoryId1: "",
        categoryId2: "",
        size: 10,
        current: 1,
        isJd: this.paramss.isJd,
        maxPrice: "",
        minPrice: "",
        minRightRate: "",
        maxRightRate: "",
        minQuanRightRate: "",
        maxQuanRightRate: "",
        minPoolSupplPrice: "",
        maxPoolSupplPrice: "",
        maxStock: "",
        minStock: "",
      }
      this.categoryId2 = []
      this.findGoodsList();
    },
    // 打开商品的京东链接
    openLink() {
      this.linkLoading = false;
      // 获取 selGoodsList 中的 skuId 列表
      var skuIds = this.selGoodsList.map(item => item.skuId);
// 定义基础链接
      var baseUrl = 'https://item.m.jd.com/product/';
// 创建链接列表
      var links = skuIds.map(skuId => baseUrl + skuId + '.html');
// 在新窗口打开链接
//      links.forEach(link => window.open(link, '_blank'));
      var count = 0; // 计数已经打开的链接次数
      var maxCount = 50; // 最大打开链接次数
      var that = this;

      function openLinks(links) {
        count++;
        if (links.length === 0) {
          return;
        }
        var link = links.shift(); // 从数组中取出第一个链接
        var newWindow = window.open(link, '_blank'); // 在新窗口打开链接
        newWindow.location = link;
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          // 如果浏览器阻止了弹出窗口，则终止打开链接操作
          return;
        }
        // 休眠次数
        console.log("最大限制" + count)
        if (count == maxCount) {
          console.log("到达休眠范围  开始休眠")
          count = 0;
          var randomNum = Math.floor(Math.random() * 1200000) + 420000;//分钟
          // 休眠十分钟
          setTimeout(function () {
            console.log('休眠结束');
          }, randomNum);
        }
        var randomDelay = Math.floor(Math.random() * 30000) + 10000; // 生成 9000 到 15000 毫秒之间的随机时间 秒
        var randomClose = Math.floor(Math.random() * 10000) + 1000; // 生成 1000 到 5000 毫秒之间的随机时间
        setTimeout(function () {
          window.close();
          newWindow.close();
        }, randomClose);
        //window.close();
        //newWindow.close();
        setTimeout(function () {
          openLinks(links); // 递归调用，打开下一个链接
        }, randomDelay); // 设置随机间隔时间
      }

      openLinks(links); // 调用函数开始打开链接
      window.focus();
      this.linkLoading = false
    },

  },//  结束标签
// 在created钩子中执行的操作
  created() {
    this.initCategory();
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      this.goodsTableData.id = id;
      this.getComboGiftPackInfo(id);
    }
    this.getAddressList(0, 0);
  },
  // activated() {
  //   this.initCategory();
  //   if (this.$route.params.id) {
  //     let id = this.$route.params.id;
  //     this.goodsTableData.id = id;
  //     this.getComboGiftPackInfo(id);
  //   }
  // },
}
;
</script>
<style scoped>

.content_box {
  background: #ffffff;
  padding: 20px;
}

.imgList {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

.imgItem {
  width: 180px;
  aspect-ratio: 1/1;
  padding: 1px;
  //margin-top: 24px; position: relative; margin-right: 10px;
}

.imgItem img {
  width: 100%;
  aspect-ratio: 1/1;
}

.removeImage {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  background: #ffffffc9;
  border-radius: 50%;
  border: 0;
  text-align: center;
  line-height: 25px;
}

</style>