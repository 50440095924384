import service from "@/utils/requestBackend";

/**
 * 获取e卡卡密列表
 * @param data
 * @returns {*}
 */
export function getECardItemListApi(data) {
    return service({
        url: '/ecard-card-item',
        method: 'get',
        params: data
    })
}

/**
 * 生成卡密
 */
export function createECardApi(data) {
    return service({
        url: '/ecard-card-item/generate',
        method: 'post',
        data: data
    })
}

/**
 * 删除卡密
 */
export function deleteKeyApi(data) {
    return service({
        url: '/ecard-card-item',
        method: 'DELETE',
        params: data
    })
}

/**
 * 批量操作卡密
 */
export function batchUpdateKeyApi(data) {
    return service({
        url: '/ecard-card-item/batch',
        method: 'post',
        data: data
    })
}

/**
 * 一键删除失效卡密
 */
export function deleteAllKeyApi(data) {
    return service({
        url: '/ecard-card-item/delete-all',
        method: 'post',
        data
    })
}

/**
 * 修改备注
 */
export function editRemarkApi(data) {
    return service({
        url: '/ecard-card-item/editRemark',
        method: 'post',
        data: data
    })
}