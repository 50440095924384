<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container"
           style="margin-top:10px;max-width:100%;flex-direction:row!important;display:flex; flex-wrap: wrap;">
        <!-- 搜索开始 -->
        <div style="width: 100%; margin-bottom: 10px;">
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem; display: flex;">
                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">分类ID：</label>
                <div class="demo-input-suffix">
                  <el-input
                      placeholder="请输入id"
                      prefix-icon="el-icon-search"
                      v-model.trim="cardPicCategoryDto.cardPicCategoryId"
                      clearable @clear="getCardPicCategoryList">
                  </el-input>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">分类名称：</label>
                <div class="demo-input-suffix">
                  <el-input
                      @change="change"
                      placeholder="请输入内容"
                      prefix-icon="el-icon-search"
                      v-model.trim="cardPicCategoryDto.cardPicCategoryName"
                      clearable @clear="getCardPicCategoryList">
                  </el-input>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">分类级别：</label>
                <div class="demo-input-suffix">
                  <el-select v-model.number="cardPicCategoryDto.level" clearable placeholder="请选择分类级别" >
                    <el-option :value="1" label="一级分类"></el-option>
                    <el-option :value="2" label="二级分类"></el-option>
                  </el-select>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">状态：</label>
                <div class="demo-input-suffix">
                  <el-select v-model.number="cardPicCategoryDto.status" clearable placeholder="请选择状态">
                    <el-option :value="1" label="启用"></el-option>
                    <el-option :value="-1" label="弃用"></el-option>
                  </el-select>
                </div>

                <el-button style="margin-left: 20px; height: 40px;" type="primary" size="mini" @click="getCardPicCategoryList"> 搜索</el-button>
                <el-button style="margin-left: 20px; height: 40px;" @click="reset" size="mini">重置</el-button>
                <el-button style="margin-left: 20px; height: 40px;" @click="AddCardPicCategoryDialog = true" size="mini">添加卡主题分类</el-button>

              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <div class="card-body py-3" style="padding-left: 15px;">
            <div class="table-responsive">
              <!-- 表格数据-->
              <el-table
                  :data="rows"
                  style="width: 100%"
                  :row-class-name="tableRowClassName"
                  @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <!--        子列表 下拉列表 start-->
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="margin: 10px">
                      <el-table
                          :data="props.row.cardPicCategoryRelationQueryDtoList"
                          style="width: 100%;margin-bottom: 20px;">
                        <el-table-column
                            label="卡图片ID"
                            prop="cardPicId"
                            width="120">
                        </el-table-column>
                        <el-table-column
                            label="卡图片名称"
                            prop="cardPicName"
                            width="120">
                        </el-table-column>
                        <el-table-column
                            label="卡图片详情"
                            prop="cardPicName"
                            width="120">
                          <template slot-scope="scope">
                            <div class="demo-image__preview">
                              <el-image
                                  style="width: 100px; height: 100px"
                                  :src="scope.row.cardPicUrl"
                                  :preview-src-list="getSrcList(scope.row.cardPicUrl)">
                              </el-image>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="卡分享详情图名称"
                            prop="cardShareDetailPicName"
                            width="140">
                        </el-table-column>
                        <el-table-column
                            label="卡分享详情图"
                            prop="cardPicName"
                            width="120">
                          <template slot-scope="scope">
                            <div class="demo-image__preview">
                              <el-image
                                  style="width: 100px; height: 100px"
                                  :src="scope.row.cardShareDetailPicUrl"
                                  :preview-src-list="getSrcList(scope.row.cardShareDetailPicUrl)">
                              </el-image>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="卡分享预览图"
                            prop="cardPicName"
                            width="120">
                          <template slot-scope="scope">
                            <div class="demo-image__preview">
                              <el-image
                                  style="width: 100px; height: 100px"
                                  :src="scope.row.cardSharePreviewPicUrl"
                                  :preview-src-list="getSrcList(scope.row.cardSharePreviewPicUrl)">
                              </el-image>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="卡分享详情图背景色"
                            prop="bgColor"
                            width="150">
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="300" align="center">
                          <template slot-scope="scope">
                            <el-button :disabled="props.row.cardPicCategoryRelationQueryDtoList.length < 1"
                                       type="text" size="small" @click="deleteCardPicById(props.row.cardPicCategoryId,scope.row.cardPicId)">解除分类与图的关系</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </template>
                </el-table-column>
                <!--        子列表 下拉列表end-->
                <el-table-column prop="cardPicCategoryId" label="主题分类id" align="center" class="100">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="cardPicCategoryName"
                    label="主题分类名称"
                    width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="分类级别" width="120" align="center">
                  <template #default="{row}">
                    <div v-if="row.level === 1">一级分类</div>
                    <div v-if="row.level === 2">二级分类</div>
                  </template>
                </el-table-column>
                <el-table-column prop="parentId" label="父分类Id" width="120" align="center">
                </el-table-column>
                <el-table-column label="创建时间" width="200" align="center">
                  <template #default="{row}">
                    <div v-html="row.createTime"></div>
                  </template>
                </el-table-column>

                <el-table-column label="状态" width="200" align="center">
                  <template #default="{row}">
                    <div v-if="row.status === -1">弃用</div>
                    <div v-if="row.status === 1">启用</div>
                  </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="300" align="center">
                  <template slot-scope="scope">
                    <el-button :disabled="scope.row.level == 1" type="text" size="small" @click="selectCardPic(scope.row.cardPicCategoryId)">
                      选择新图并添加
                      </el-button>
                    <el-button type="text" size="small" @click="getCardPicCategoryById(scope.row.cardPicCategoryId)">编辑卡主题分类详情</el-button>
                    <el-button type="text" size="small" @click="deleteCardPicCategoryById(scope.row.cardPicCategoryId)">删除卡主题分类</el-button>

                  </template>
                </el-table-column>

              </el-table>
              <el-pagination
                  ref="pagination"
                  style="margin-top: 10px;text-align: center"
                  @size-change="statusHandleSizeChange"
                  @current-change="statusHandleCurrentChange"
                  :current-page.sync="cardPicCategoryDto.current"
                  :page-sizes="[10, 20, 30, 50]"
                  :page-size="cardPicCategoryDto.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCount">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- 查询卡的所有图片   -->
    <el-dialog title="卡图片列表" :visible.sync="selectCardPicDialog" :modal="false" width="60%">
      <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">图片名称：</label>
      <div class="demo-input-suffix">
        <el-input
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            @change="getCardPicList"
            v-model.trim="picParams.cardPicName"
            clearable @clear="getCardPicList">
        </el-input>
      </div>
      <!-- 列表数据展示 -->
      <el-table class="m-auto" :data="cardPicList" style="width: 100%" height="70vh"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                v-loading="picLoading">
        <el-table-column prop="id" label="卡图片ID" fixed width="100" align="center">
        </el-table-column>

        <el-table-column
            label="卡图片名称"
            prop="cardPicName"
            width="120">
        </el-table-column>
        <el-table-column
            label="卡图片详情"
            prop="cardPicName"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.cardPicUrl"
                  :preview-src-list="getSrcList(scope.row.cardPicUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="卡分享详情图名称"
            prop="cardShareDetailPicName"
            width="140">
        </el-table-column>
        <el-table-column
            label="卡分享详情图"
            prop="cardPicName"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.cardShareDetailPicUrl"
                  :preview-src-list="getSrcList(scope.row.cardShareDetailPicUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="卡分享省略图"
            prop="cardPicName"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.cardSharePreviewPicUrl"
                  :preview-src-list="getSrcList(scope.row.cardSharePreviewPicUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="卡分享详情图背景色"
            prop="bgColor"
            width="150">
        </el-table-column>
        <el-table-column
            label="卡分享LOGO图"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.logoUrl"
                  :preview-src-list="getSrcList(scope.row.logoUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addCardPicInRelation(scope.row.id)">
              添加到分类中
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          ref="pagination"
          style="margin-top: 10px;text-align: center"
          @size-change="picHandleSizeChange"
          @current-change="picHandleCurrentChange"
          :current-page.sync="picParams.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="picParams.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="picTotalcount">
      </el-pagination>
    </el-dialog>
    <!-- 查询卡的所有图片   end-->

    <!-- 添加卡分类   -->
    <el-dialog title="卡分类信息" :visible.sync="AddCardPicCategoryDialog" :modal="false" width="60%">
      <!-- 表单 -->
      <el-form ref="goodsTableData" :model="cardPicCategory" label-width="100px" class="demo-ruleForm" label-position="top">
        <el-row>
          <el-col :span="3" style="margin-left: 200px !important;">
            <el-form-item label="主题分类名称" prop="name" class="fw-bold fs-10">
              <el-input v-model="cardPicCategory.cardPicCategoryName" type="text" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left: 200px !important;">
            <el-form-item label="分类级别" prop="name" class="fw-bold fs-10">
              <el-select v-model.number="cardPicCategory.level" clearable placeholder="请选择分类级别" >
                <el-option :value="1" label="一级分类"></el-option>
                <el-option :value="2" label="二级分类"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left: 200px !important;">
            <el-form-item label="父分类Id" prop="name" class="fw-bold fs-10">
              <el-select v-model.number="cardPicCategory.parentId" clearable placeholder="请选择父级分类" >
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin-left:200px !important;">
            <el-form-item label="礼包状态" prop="type" class="fw-bold">
              <el-radio-group v-model="cardPicCategory.status">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="2">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="d-flex justify-content-end">
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>


import {getCardPicCategoryListApi,deleteCardPicByIdApi,getCardPicCategoryByLevelApi,getCardPicCategoryByIdApi,editCardPicCategoryApi,deleteCardPicCategoryByIdApi,
  getCardPicListApi,addCardPicInRelationApi} from "@/api/backend/xitunbaoCardApi";


export default {
  name: "XiTunBaoCardPicCategory",
  data() {
    return {
      rows: [],
      loading: false,
      ids: [],
      id: "",
      totalCount: 999,
      first: true,
      cardPicCategoryDto: {
        current: 1,
        size: 10,
        cardPicCategoryId: "",
        cardPicCategoryName: "",
        status: "",
        parentId: "",
        level: "",
      },
      srcList: [],
      cardPicCategoryId: "",
      selectCardPicDialog: false,
      picLoading: false,
      cardPicList: [],
      picParams: {
        current: 1,
        size: 10,
        cardPicCategoryId: "",
        cardPicName: "",
      },
      picTotalcount: 99,
      AddCardPicCategoryDialog: false,
      cardPicCategory: {
        cardPicCategoryId: "",
        cardPicCategoryName: "",
        status: "",
        parentId: "",
        level: "",
      },
      options: [],
    };
  },
  watch: {
    'cardPicCategory.level': function (level) {
      if (level == 1) {
        this.cardPicCategory.parentId = 0;
        this.options = "";
      } else if (level == 2) {
        this.options = [];
        this.getCardPicCategoryByLevel();
      }
    }
  },
  // 方法
  methods: {
    async getCardPicCategoryList() {
      this.loading = true;
      let {data} = await getCardPicCategoryListApi(this.cardPicCategoryDto)
      this.loading = false;
      this.rows = data.rows;
      this.totalCount = data.total;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    statusHandleSizeChange(val) {
      this.cardPicCategoryDto.size = val;
      this.getCardPicCategoryList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    statusHandleCurrentChange(val) {
      this.cardPicCategoryDto.current = val;
      this.getCardPicCategoryList();
    },
    change(value) {
      if (!value) {
        this.getCardPicCategoryList();
      }
    },
    // 复选框 将id 组成集合
    handleSelectionChange(val) {
      let arr = []
      val.forEach(item => {
        arr.push(item.cardPicCategoryId)
      })
      this.ids = arr;
    },
    /*重置*/
    reset() {
      this.cardPicCategoryDto = {
        current: 1,
        size: 10,
        cardPicCategoryName: "",
        status: "",
        parentId: "",
        level: "",
      };
      this.getCardPicCategoryList();
    },
    /* 主列表*/
    /**
     * 修改表的颜色
     */
    tableRowClassName({row, rowIndex}) {
      if (row.cardPicCategoryRelationQueryDtoList.length > 0) {
        return 'success-row';
      }
    },
    /**
     *查询父级分类的
     */
    async getCardPicCategoryByLevel() {
      let level = this.cardPicCategory.level;
      if (level == 0) {
        this.cardPicCategory.parentId = 0;
      } else if (level > 0){
        let {data} = await getCardPicCategoryByLevelApi({level: 1});
        for (let i = 0; i < data.length; i++) {
          let config = {
            id: data[i].id,
            name: data[i].cardPicCategoryName
          }
          this.options.push(config);
        }
      }
    },
    /**
     * 查询卡分类数据详情
     */
    async getCardPicCategoryById(cardPicCategoryId) {
      let {data} = await getCardPicCategoryByIdApi({cardPicCategoryId: cardPicCategoryId});
      let { id, ...restData } = data;  // 从 data 中提取 id，并将剩余属性放入 restData
      let config = {
        ...restData,          // 将 data 中的其他属性复制到 config
        cardPicCategoryId: id // 设置 cardPicCategoryId 为 data.id 的值
      };
      // 如果需要移除 data.id，以下是示例代码，尽管上面的代码已经实现了这个效果
      delete data.id;
      this.cardPicCategory = config;
      this.AddCardPicCategoryDialog = true;
    },
    dataVerification() {
      let data = this.cardPicCategory;
      if (!data.cardPicCategoryName) {
        this.$message.warning('请输入名称');
        return false;
      }
      if (!data.status) {
        this.$message.warning('请选择状态');
        return false;
      }
      if (!data.level) {
        this.$message.warning('请选择级别');
        return false;
      }
      return true;
    },
    /**
     * 新增图片 提交*/
    async onSubmit() {
      if (!this.dataVerification()) {
        return;
      }
      // 新增 商品列表有值 并且 主键id没值
      try {
        await editCardPicCategoryApi(this.cardPicCategory);
        this.AddCardPicCategoryDialog = false;
        this.getCardPicCategoryList();
        if (!this.cardPicCategory.cardPicCategoryId) {
          this.$message.success('新增主题分类成功!');
        } else {
          this.$message.success('修改主题分类成功!');
        }
        this.cardPicCategory =  {
          cardPicCategoryId: "",
              cardPicCategoryName: "",
              status: "",
              parentId: "",
              level: "",
        };
      } catch (e) {
        this.$message.error('新增主题分类失败！请重新输入数据！谢谢');
      }
    },
    async deleteCardPicCategoryById(id) {
      this.$confirm('此操作将会删除分类是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        await deleteCardPicCategoryByIdApi({id: id});
        this.getCardPicCategoryList();
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '删除失败'
        });
      })
    },
    /* 下拉菜单的 子列表*/
    getSrcList(cardPicUrl) {
      if (cardPicUrl) {
        return cardPicUrl.split(',');
      }
    },
    async deleteCardPicById(cardPicCategoryId, cardPicId) {
      this.$confirm('此操作将会一键删除与分类绑定的图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        let config = {
          cardPicCategoryId: cardPicCategoryId,
          cardPicId: cardPicId
        };
        await deleteCardPicByIdApi(config);
        await this.getCardPicCategoryList();
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      })
    },
    /* 查询 卡的所有图片 */
    /**
     * 查询卡图片集合
     */
    selectCardPic(cardPicCategoryId) {
      this.picParams.cardPicCategoryId = cardPicCategoryId;
      this.selectCardPicDialog = true;
      this.getCardPicList();
    },
    async getCardPicList() {
      this.picLoading = true;
      let {data} = await getCardPicListApi(this.picParams)
      this.picLoading = false;
      this.cardPicList = data.rows;
      this.picTotalcount = data.total;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    picHandleSizeChange(val) {
      this.picParams.size = val;
      this.getCardPicList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    picHandleCurrentChange(val) {
      this.picParams.current = val;
      this.getCardPicList();
    },
    /**
     * 将图片添加到分类中
     */
    async addCardPicInRelation(picId) {
      this.$confirm('此操作将会一键将该图片绑定到分类中?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(async () => {
        let config = {
          cardPicCategoryId: this.picParams.cardPicCategoryId,
          cardPicId: picId
        };
        await addCardPicInRelationApi(config);
        await this.getCardPicCategoryList();
        await this.getCardPicList();
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
      })
    },

  },

  // 初始化这个方法
  created() {
    this.getCardPicCategoryList();
  },
};
</script>
<!--组件样式-->
<style>

.el-table .success-row {
  background: dodgerblue;
}

</style>