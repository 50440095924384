import service from "@/utils/requestBackend";

// 获取商品分类
export function getCategoryList(data) {
    return service({
        url: '/category/getCategoryList',
        method: 'get',
        params:data
    })
}

// 获取规格参数
export function getValueListApi(data) {
    return service({
        url: '/category/getValueList',
        method: 'get',
        params:data
    })
}

export function getCategoryPageApi(data) {
    return service({
        url: '/category/list',
        method: 'get',
        params:data
    })
}

export function updateCategoryByCategoryIdApi(data) {
    return service({
        url: '/category/updateDownload',
        method: 'post',
        data
    })
}

export function downloadDataByCategoryApi(data) {
    return service({
        url: '/download/category',
        method: 'post',
        data
    })
}