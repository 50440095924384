import service from "@/utils/requestBackend";


//上传图片
export function uploadFileApi(data) {
    return service({
        url: '/upload/img',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data
    })
}
//移除图片
export function removeFileApi(data) {
    return service({
        url: '/upload',
        method: 'delete',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params:data
    })
}

// 获取轮播图
export function obtainCarouselImageApi(data) {
    return service({
        url: '/sjGoods/getCarouselByGoodsId',
        method: 'post',
        data
    })
}

// 获取详情图
export function obtainDetailImageApi(data) {
    return service({
        url: '/giftPack/getGoodsDetail',
        method: 'post',
        data
    })
}