export const commentType = [
    {
        title: "0",
        count: "0"
    },
    {
        title: "小于100",
        count: "99"
    }, {
        title: "100+",
        count: "100+"
    }, {
        title: "200+",
        count: "200+"
    }, {
        title: "500+",
        count: "500+"
    }, {
        title: "1000+",
        count: "1000+"
    }, {
        title: "2000+",
        count: "2000+"
    },
    {
        title: "5000+",
        count: "5000+"
    },
    {
        title: "1万+",
        count: "1万+"
    },
    {
        title: "2万+",
        count: "2万+"
    },
    {
        title: "5万+",
        count: "5万+"
    },
    {
        title: "10万+",
        count: "10万+"
    },
    {
        title: "20万+",
        count: "20万+"
    },
    {
        title: "50万+",
        count: "50万+"
    },
    {
        title: "100万+",
        count: "100万+"
    },
    {
        title: "200万+",
        count: "200万+"
    },
    {
        title: "300万+",
        count: "300万+"
    },
    {
        title: "400万+",
        count: "400万+"
    },
    {
        title: "500万+",
        count: "500万+"
    }
]