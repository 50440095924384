<template>
    <div class="header navbar navbar-inverse navbar-fixed-top" style="height:56px;display: flex;justify-content:flex-start">
      <!-- 导航条内容开始 -->
      <div class="header-inner">
        <!-- 公司logo开始 -->
        <a href="/" class="navbar-brand" style="padding-top: 5px;padding-left: 15px">
          <img src="../../assets/img/logo.png" alt="logo" class="img-responsive"
               style="margin-left: 8px;"/>
        </a> <a class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
        <img src="" alt=""/>
      </a>
        <!-- 公司logo结束 -->
        <!-- 一级菜单目录开始 -->
        <div class="hor-menu hidden-sm hidden-xs">
          <ul id="firstModules" class="nav navbar-nav">
            <li class="active"><a onclick="changeFirstModules(this)" id="productEntryReview"
                                  moduleid="375" staffid="1"
                                  modulehref="./newSelection.html">选品专区</a></li>
            <li><a onclick="changeFirstModules(this)" id="productEntryReview"
                   moduleid="375" staffid="1"
                   modulehref="./jdGoods.html">京东商品</a></li>
            <li><a onclick="changeFirstModules(this)" id="productEntryReview"
                   moduleid="375" staffid="1"
                   modulehref="./idc.html">数据中心</a></li>
          </ul>
        </div>
        <!-- 一级菜单目录结束 -->
        <ul></ul>
        <!-- 右侧  账户详情 信息 -->
        <!-- 右侧 消息提示，员工登陆信息  -->
      </div>
      <!-- 导航条内容结束 -->
    </div>
</template>

<script>

export default {
  name: 'NavComponent',
  components: {},
  computed: {},
  methods: {},

  created() {
  },
};
</script>
