import service from "@/utils/requestAdmin";

// 用户登录
export function userLoginApi(data) {
    return service({
        url: '/user/login',
        method: 'post',
        data
    })
}

// 退出登录
export function userLogoutApi() {
    return service({
        url: '/user/logout',
        method: 'post',
    })
}