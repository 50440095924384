import service from "@/utils/requestBackend";

export function getCardPicCategoryListApi(params) {
    return service({
        url: '/xitunbao/cardPic/categoryList',
        method: 'get',
        params
    })
}

export function deleteCardPicByIdApi(data) {
    return service({
        url: '/xitunbao/cardPic/deleteCardPicRelation',
        method: 'delete',
        data
    })
}


export function getCardPicListApi(params) {
    return service({
        url: '/xitunbao/cardPic/picList',
        method: 'get',
        params
    })
}


export function addCardPicInRelationApi(data) {
    return service({
        url: '/xitunbao/cardPic/addCardPicInRelation',
        method: 'post',
        data
    })
}

export function addCardPicApi(data) {
    return service({
        url: '/xitunbao/cardPic/addCardPic',
        method: 'post',
        data
    })
}

export function getCardPicByIdApi(params) {
    return service({
        url: '/xitunbao/cardPic',
        method: 'get',
        params
    })
}

export function getCardPicCategoryByLevelApi(params) {
    return service({
        url: '/xitunbao/cardPic/cardPicCategoryLevel',
        method: 'get',
        params
    })
}


export function getCardPicCategoryByIdApi(params) {
    return service({
        url: '/xitunbao/cardPic/cardPicCategory',
        method: 'get',
        params
    })
}

export function editCardPicCategoryApi(data) {
    return service({
        url: '/xitunbao/cardPic/editCardPicCategory',
        method: 'post',
        data
    })
}

export function deleteCardPicCategoryByIdApi(data) {
    return service({
        url: '/xitunbao/cardPic/deleteCardPicCategoryById',
        method: 'delete',
        data
    })
}

/**
 * 用户购买自动生成的消费卡 列表查询
 */
export function getCardOrderAssociatedCardsListApi(params) {
    return service({
        url: "/xitunbao/card/getCardOrderAssociatedCardsList",
        method: "get",
        params,
    })
}

export function getCardOrderAssociatedCardsCountApi(params) {
    return service({
        url: "/xitunbao/card/getCardOrderAssociatedCardsCount",
        method: "get",
        params,
    })
}

export function exportXiTunBaoCardOrderListApi(params) {
    return service({
        url: "/xitunbao/card/exportXiTunBaoCardOrderListApi",
        method: "get",
        params,
        responseType: 'blob',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

export function editCardOrderApi(data) {
    return service({
        url: "/xitunbao/card/editCardOrder",
        method: "post",
        data,
    })
}






