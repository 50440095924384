import service from "@/utils/requestBackend";

export function getXiTunBaoOrderApi(params) {
    return service({
        url: '/xitunbao/order',
        method: 'get',
        params
    })
}

export function getXiTunBaoDeliveryByJdOrderIdApi(params) {
    return service({
        url: '/xitunbao/order/getBackendDeliveryByJdOrderId',
        method: 'get',
        params
    })
}

export function exportXiTunBaoOrderApi(params) {
    return service({
        url: "/xitunbao/order/exportOrder",
        method: "get",
        params,
        responseType: 'blob',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

export function getXiTunBaoCardListApi(params) {
    return service({
        url: '/xitunbao/card',
        method: 'get',
        params
    })
}

export function getXiTunBaoCardNameListApi() {
    return service({
        url: '/xitunbao/card/getCardNameList',
        method: 'get',
    })
}

export function getCardUserItemListApi(params) {
    return service({
        url: '/xitunbao/card/getCardUserItemList',
        method: 'get',
        params
    })
}

export function editLogisticsOrderNoApi(data) {
    return service({
        url: '/xitunbao/card/editLogisticsOrderNo',
        method: 'post',
        data
    })
}

export function editRemarkApi(data) {
    return service({
        url: '/xitunbao/card/editRemark',
        method: 'post',
        data
    })
}

// editKeyRemarkApi
export function editKeyRemarkApi(data) {
    return service({
        url: '/xitunbao/card/editKeyRemark',
        method: 'post',
        data
    })
}

// exportXiTunBaoCardListApi
export function exportXiTunBaoCardListApi(params) {
    return service({
        url: "/xitunbao/card/exportXiTunBaoCardListApi",
        method: "get",
        params,
        responseType: 'blob',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

export function exportXiTunBaoCardItemListApi(params) {
    return service({
        url: "/xitunbao/card/exportXiTunBaoCardItemListApi",
        method: "get",
        params,
        responseType: 'blob',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

export function xiTunBaoCardItemCreateApi(data) {
    return service({
        url: "/xitunbao/card/generate",
        method: "post",
        data,
    });
}

export function deleteXiTunBaoCardItemCreateApi(data) {
    return service({
        url: "/xitunbao/card/delete",
        method: "post",
        data,
    });
}

/**
 * 通过手机号获取用户信息
 */
export function getPhoneByUserApi(params) {
    return service({
        url: "/xitunbao/card/getPhoneByUser",
        method: "get",
        params,
    })
}

export function getKdlOrderApi(params) {
    return service({
        url: "/xitunbao/order/getKdlOrder",
        method: "get",
        params,
    })
}

/**
 * 修改卡的状态
 */
export function editCardStatusApi(data) {
    return service({
        url: "/xitunbao/card/editCardStatus",
        method: "post",
        data,
    });
}

export function checkCardNoPrefixApi(params) {
    return service({
        url: "/xitunbao/card/checkCardNoPrefix",
        method: "get",
        params,
    })
}

export function getCardUserReservationListApi(params) {
    return service({
        url: "/xitunbao/card/getCardUserReservationList",
        method: "get",
        params,
    })
}

export function uploadMobileFileApi(data) {
    return service({
        url: "/xitunbao/card/importMobileBindCardUser",
        method: "post",
        data,
    })
}

export function getIntUserPageApi(params) {
    return service({
        url: "/xitunbao/intUser",
        method: "get",
        params,
    })
}

export function adminEditIntegralApi(data) {
    return service({
        url: "/xitunbao/intUser/adminEditIntegral",
        method: "post",
        data,
    })
}

export function getIntUserIntegralDetailPageApi(params) {
    return service({
        url: "/xitunbao/intUser/getUserIntegralDetailPage",
        method: "get",
        params,
    })
}