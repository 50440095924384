<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container"
           style="margin-top:10px;max-width:100%;flex-direction:row!important;display:flex; flex-wrap: wrap;">
        <!-- 搜索开始 -->
        <div style="width: 100%; margin-bottom: 10px;">
          <form action="#" id="brand_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem; display: flex;">
                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">品牌名称：</label>
                <div class="demo-input-suffix">
                  <el-input
                      @change="change"
                      placeholder="请输入内容"
                      prefix-icon="el-icon-search"
                      v-model.trim="brandDto.brandName"
                      clearable @clear="getBrandList">
                  </el-input>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">品牌级别：</label>
                <div class="demo-input-suffix">
                  <el-select v-model.number="brandDto.brandLevel" clearable placeholder="请选择品牌级别" size="small">
                    <el-option :value="0" label="一级品牌"></el-option>
                    <el-option :value="1" label="二级品牌"></el-option>
                    <el-option :value="2" label="三级品牌"></el-option>
                  </el-select>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">下载需求：</label>
                <div class="demo-input-suffix">
                  <el-select v-model.number="brandDto.need" clearable placeholder="请选择品牌级别" size="small">
                    <el-option :value="1" label="需要"></el-option>
                    <el-option :value="-1" label="舍弃"></el-option>
                  </el-select>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">下载状态：</label>
                <div class="demo-input-suffix">
                  <el-select v-model="brandDto.downloadStatus" multiple clearable placeholder="请选择品牌级别" size="small">
                    <el-option :value="-1" label="暂未开始"></el-option>
                    <el-option :value="0" label="需要重下"></el-option>
                    <el-option :value="1" label="正在下载"></el-option>
                    <el-option :value="2" label="下载完成"></el-option>
                  </el-select>
                </div>

                <el-button style="margin-left: 10px; height: 40px;" type="primary" size="mini" @click="getBrandList"> 搜索</el-button>
                <el-button style="margin-left: 10px; height: 40px;" @click="reset" size="mini">重置</el-button>
                <el-button style="margin-left: 10px; height: 40px;" type="primary" size="mini" icon="el-icon-edit"
                           :disabled="ids.length < 1" @click="updateDialog = true">
                  批量修改状态
                </el-button>

                <el-button style="margin-left: 10px; height: 40px;" type="primary" size="mini" icon="el-icon-edit"
                           :disabled="ids.length < 1" @click="downloadDialog = true">
                  下载选中
                </el-button>

                <el-button style="margin-left: 10px; height: 40px;" type="primary" size="mini" icon="el-icon-edit"
                           @click="downloadDialog = true,ids = []">
                  下载全部
                </el-button>

                <el-button style="margin-left: 10px; height: 40px;" type="primary" size="mini" icon="el-icon-download"
                           @click="openLogViewerDialog">
                  查看日志
                </el-button>

                <el-dialog
                    title="提示"
                    :visible.sync="updateDialog"
                    width="30%">
                  <span>修改选中的下载需求</span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="updateDialog = false">取 消</el-button>
                    <el-button type="primary" @click="updateBrand(0,1,0)">需 要</el-button>
                    <el-button type="danger" @click="updateBrand(0,-1,0)">舍 弃</el-button>
                  </span>
                </el-dialog>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <div class="card-body py-3" style="padding-left: 15px;">
            <div class="table-responsive">
              <!-- 表格数据-->
              <el-table
                  :data="rows"
                  style="width: 100%"
                  @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column prop="brandId" label="品牌id" align="center" class="100">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="brandName"
                    label="品牌名称"
                    width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="类目备注"
                    width="200"
                    show-overflow-tooltip>
                  <template #default="{row}">
                    <el-input v-loading="remarkLoading"
                              element-loading-text="拼命加载中"
                              element-loading-spinner="el-icon-loading"
                              element-loading-background="rgba(0, 0, 0, 0.8)" v-model="row.remark" size="mini" placeholder="请输入类目备注"  clearable @change="editRemark(row)"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="下载时间" width="200" align="center">
                  <template #default="{row}">
                    <div v-html="row.downloadTime"></div>
                  </template>
                </el-table-column>
                <el-table-column label="结束时间" width="200" align="center">
                  <template #default="{row}">
                    <div v-html="row.downloadEnd"></div>
                  </template>
                </el-table-column>

                <el-table-column label="下载顺序" height="100" width="120" align="center">
                  <template #default="{row}">
                    <div class="center-block text-black text-hover-info border-hover-info cursor-pointer "
                         style="border: 1px solid rgba(9, 9, 9, 0.35);padding: 3px; width: 70%; border-radius: 10px;"
                         @click="openUpdateBrandDialog(3,row.downloadSort,row.brandId)"
                         v-html="row.downloadSort"></div>
                  </template>
                </el-table-column>
                <el-table-column label="评论数限制" width="150" align="center">
                  <template #default="{row}">
                    <div class="center-block text-black text-hover-info border-hover-info cursor-pointer "
                         style="border: 1px solid rgba(9, 9, 9, 0.35);padding: 3px; width: 70%; border-radius: 10px;"
                         @click="openUpdateBrandDialog(2,row.commentLimit,row.brandId)"
                         v-html="row.commentLimit"></div>
                  </template>
                </el-table-column>
                <el-table-column label="下载需求" width="150" align="center">
                  <template #default="{row}">
                    <div class="center-block text-primary text-hover-info border-hover-info cursor-pointer "
                         style="border: 1px solid rgba(9, 9, 9, 0.35);padding: 3px; width: 70%; border-radius: 10px;"
                         v-if="row.need === 1" @click="openUpdateBrandDialog(1,-1, row.brandId)"> 需要
                    </div>
                    <div class="center-block text-danger text-hover-info border-hover-info cursor-pointer "
                         style="border: 1px solid rgba(9, 9, 9, 0.35);padding: 3px; width: 70%; border-radius: 10px;"
                         v-if="row.need === -1" @click="openUpdateBrandDialog(1,1, row.brandId)"> 舍弃
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="下载状态" width="200" align="center">
                  <template #default="{row}">
                    <div v-if="row.downloadStatus === -1">暂未开始</div>
                    <div v-if="row.downloadStatus === 0">需要重下</div>
                    <div v-if="row.downloadStatus === 1">正在下载</div>
                    <div v-if="row.downloadStatus === 2">下载完成</div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                  ref="pagination"
                  style="margin-top: 10px;text-align: center"
                  @size-change="statusHandleSizeChange"
                  @current-change="statusHandleCurrentChange"
                  :current-page.sync="brandDto.current"
                  :page-sizes="[10, 20, 30, 50]"
                  :page-size="brandDto.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCount">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="brandDownload.title"
        :visible.sync="updateDownloadDialog"
        width="30%">
      <div v-if="brandDownload.type === 1">
        <span class="fs-5">点击舍弃或需要按钮修改该数据的下载需求</span>
      </div>
      <div v-if="brandDownload.type === 2">
        <span class="fw-bold fs-5">评论限制：</span>
        <el-input class="ms-2" style="width: 200px;" v-model="brandDownload.commentLimit" placeholder="请输入评论限制次数"></el-input>
      </div>
      <div v-if="brandDownload.type === 3">
        <span class="fw-bold fs-5">下载顺序：</span>
        <el-input class="ms-2" style="width: 200px;" v-model="brandDownload.downloadSort" placeholder="请输入下载顺序"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDownloadDialog = false">取 消</el-button>
        <el-button v-if="brandDownload.need === 1" type="primary" @click="updateBrand(1,1)">需 要</el-button>
        <el-button v-if="brandDownload.need === -1" type="danger" @click="updateBrand(1,-1)">舍 弃</el-button>
        <el-button v-if="brandDownload.type === 2" type="primary" @click="updateBrand(2,brandDownload.commentLimit)">确 认</el-button>
        <el-button v-if="brandDownload.type === 3" type="primary" @click="updateBrand(3,brandDownload.downloadSort)">确 认</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="将下载选中品牌的数据"
        :visible.sync="downloadDialog"
        width="30%">

      <span class="fs-5"> 是否确认下载数据</span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadDialog = false">取 消</el-button>
        <el-button @click="downloadDataByBrand">确 认</el-button>
      </span>
    </el-dialog>

    <LogViewer ref="logViewer" v-if="logViewerVisible"/>


  </div>
</template>
<script>

import {getJdBrandListApi, updateBrandByBrandIdApi,editRemarkApi} from "@/api/backend/brandApi";
import {downloadDataByBrandApi} from "@/api/download/brandApi";
import LogViewer from "@/components/LogViewer/index.vue";

export default {
  components: {
    LogViewer,
  },
  data() {
    return {
      remarkLoading: false,
      logViewerVisible: false, // 加在组件
      rows: [],
      loading: false,
      ids: [],
      id: "",
      updateDialog: false, // 修改弹出框
      totalCount: 999,
      first: true,
      brandDto: {
        current: 1,
        size: 10,
        brandName: "",
        brandId: "",
        downloadStatus: "",
        downloadSort: "",
        need: "",
        commentLimit: "",
        id: ""
      },
      updateDownloadDialog: false,
      brandDownload: {
        title: "",
        type: "",
        brandIds: [],
        downloadSort: "",
        need: "",
        commentLimit: ""
      },
      downloadDialog: false, //下载数据
      downloadBrand: {
        brandIds: [],
        current: 1,
        size: 10
      }
    };
  },
  // 方法
  methods: {
    async editRemark(row) {
      this.remarkLoading =true;
      this.$confirm('此操作将修改备注, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editRemarkApi(row);
        this.$message({
          type: 'success',
          message: '修改成功!'
        });
        this.remarkLoading =false;
        this.getBrandList();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });

    },
    //  打开日志查看器 组件
    openLogViewerDialog() {
      this.logViewerVisible = true;
      // 使用 $nextTick 确保子组件已经渲染完成
      this.$nextTick(() => {
        // 通过 $refs 访问子组件并调用方法
        this.$refs.logViewer.openLogsDialog();
      });
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    statusHandleSizeChange(val) {
      this.brandDto.size = val;
      this.getBrandList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    statusHandleCurrentChange(val) {
      this.brandDto.current = val;
      this.getBrandList();
    },
    // 当输入框值为空值 或者改变 会执行语句或刷新 列表
    change(value) {
      if (!value) {
        this.getBrandList();
      }
    },
    async getBrandList() {
      this.loading = true;
      let config = {
        ...this.brandDto,
        downloadStatus:this.brandDto.downloadStatus ? this.brandDto.downloadStatus.join(','):''
      }
      let {data} = await getJdBrandListApi(config)
      this.loading = false;
      this.rows = data.rows;
      this.totalCount = data.total;
    },
    // 复选框 将id 组成集合
    handleSelectionChange(val) {
      let arr = []
      val.forEach(item => {
        arr.push(item.id)
      })
      this.ids = arr;
    },

    // 修改单个品牌下载数据的弹出框
    openUpdateBrandDialog(type, data, id) {
      this.brandDownload.type = type;
      // 1 需求 2 评论 3 顺序
      if (type === 1) {
        this.brandDownload.brandIds = [id];
        this.brandDownload.title = "修改选中数据的下载需求";
        this.brandDownload.need = data;
      } else if (type === 2) {
        this.brandDownload.brandIds = [id];
        this.brandDownload.title = "修改选中数据的评论限制";
        this.brandDownload.commentLimit = data;
      } else if (type === 3) {
        this.brandDownload.brandIds = [id];
        this.brandDownload.title = "修改选中数据的下载顺序";
        this.brandDownload.downloadSort = data;
      }
      this.updateDownloadDialog = true;
    },
    // 修改品牌是否需要参与下载的数据
    async updateBrand(type, data) {
      if (type === 0) {
        // 需求的批量数据修改
        this.brandDownload.brandIds = this.ids;
        this.brandDownload.need = data;
      }
      /*// 需求的单个数据修改
      if (type === 1) {
        this.brandDownload.need = data;
      } else if (type === 0) {
        // 需求的批量数据修改
        this.brandDownload.brandIds = this.ids;
        this.brandDownload.need = data;
      } else if (type === 2) {
        this.brandDownload.commentLimit = data;
      } else if (type === 3) {
        this.brandDownload.downloadSort = data;
      }*/
      await updateBrandByBrandIdApi(this.brandDownload);
      this.getBrandList();
      this.updateDialog = false;
      this.brandDownload.need = "";
      this.brandDownload.commentLimit = "";
      this.brandDownload.downloadSort = "";
      this.ids = [];
      this.updateDownloadDialog = false;
    },
    // 根据品牌下载数据
    async downloadDataByBrand() {
      if (this.ids) {
        let brandList = [];
        this.ids.forEach(id => {
          let brandDo = {id: id}; // 创建一个包含 brandId 的简单对象
          brandList.push(brandDo); // 将对象添加到 brandList 数组中
        });
        this.downloadBrand.brandIds = brandList;
      }
      downloadDataByBrandApi(this.downloadBrand);
      this.downloadDialog = false;
      this.$message({
        message: '下载启动成功',
        type: 'success'
      });
      this.openLogViewerDialog();
    },
    /*重置*/
    reset() {
      this.brandDto = {
        current: 1,
        size: 10,
        brandName: "",
        brandId: "",
        brandLevel: "",
        parentId: "",
        fullName: "",
        downloadStatus: "",
        downloadSort: "",
        need: "",
        commentLimit: "",
        id: ""
      };
      this.getBrandList();
    },

  },

  // 初始化这个方法
  created() {
    this.getBrandList();
  },
};
</script>
<!--组件样式-->
<style scoped>

/deep/ .el-input--small .el-input__inner {
  height: 40px;
}
</style>