<template>
  <div style="padding-top: 10px">
    <title-card title="京东商品" style="max-width: 97%; margin: 10px auto">
      <div style="position: absolute; right: 70px">
        <!--<el-button type="primary" size="mini">创建选品库</el-button>-->
      </div>
      <template v-slot:subTitle>

      </template>
    </title-card>
    <div style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px">
      123123123
    </div>
  </div>
</template>
<script>
export default {
  name: "JdGoods"
}
</script>