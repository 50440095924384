<template>
  <div>
    <el-dialog
        :title="`选品库(${item.title})`"
        :visible.sync="dialog"
        top="50px"
        width="98%"
        @close="closeChange"
    >
      <el-row
          v-if="index==0"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-spinner="el-icon-loading"
          element-loading-text="请求推送中..请勿刷新网页">
        <el-col :span="4">
          <div style="padding: 10px">
            <el-form id="topicGoodsForm" :model="selectionGoods" label-position="top" label-width="80px">
              选品库ID: {{selectionGoods.topicId}}
              <el-form-item label="商品名称">
                <el-input v-model="selectionGoods.title" placeholder="请输入需要查询的商品名称"></el-input>
              </el-form-item>
              <el-form-item label="商品ID">
                <el-input v-model="selectionGoods.goodsId" placeholder="请输入需要查询的商品ID"></el-input>
              </el-form-item>
              <el-form-item v-if="false" label="商品货源">
                <el-radio-group v-model="selectionGoods.isJd" size="small" @input="isJdChange">
                  <el-radio-button :label="1">京东货源</el-radio-button>
                  <el-radio-button :label="2">自营货源</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="一级类别">
                <el-select v-model="selectionGoods.categoryId1Name" placeholder="请选择" style="width: 100%"
                           @change="getCategory1List">
                  <el-option label="请选择" value="">
                  </el-option>
                  <el-option
                      v-for="(item,index) in categoryId1"
                      :key="index"
                      :label="item.fullName"
                      :value="item.categoryId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="二级类别">
                <el-select v-model="selectionGoods.categoryId2Name" placeholder="请选择" style="width: 100%"
                           @change="queryGetList">
                  <el-option label="请选择" value="">
                  </el-option>
                  <el-option
                      v-for="(item,index) in categoryId2"
                      :key="index"
                      :label="item.fullName"
                      :value="item.categoryId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="全国分销价区间">
                <el-input v-model="selectionGoods.minSupplyPrice" placeholder="最小值" style="width: 45%;"></el-input>
                <span style="margin: 0 5px;">~</span>
                <el-input v-model="selectionGoods.maxSupplyPrice" placeholder="最大值" style="width: 45%;"></el-input>
              </el-form-item>
              <el-form-item label="折扣区间">
                <el-input v-model="selectionGoods.minProfitMargin" placeholder="最小值" style="width: 45%;"></el-input>
                <span style="margin: 0 5px;">~</span>
                <el-input v-model="selectionGoods.maxProfitMargin" placeholder="最大值" style="width: 45%;"></el-input>
              </el-form-item>
              <el-form-item label="评论数">
                <el-select v-model="selectionGoods.commentType" placeholder="请选择" style="width: 100%"
                           @change="queryGetList">
                  <el-option label="请选择" value="">
                  </el-option>
                  <el-option
                      v-for="item in commentType()"
                      :key="item.count"
                      :label="item.title"
                      :value="item.count">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="市场价排序">
                <el-select v-model="selectionGoods.priceSort" placeholder="请选择" style="width: 100%"
                           @change="queryGetList">
                  <el-option label="默认" value=""></el-option>
                  <el-option label="正序" value="asc"></el-option>
                  <el-option label="倒序" value="desc"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="评论数最小值">
                <el-input v-model="selectionGoods.commentOkCount" placeholder="评论最小值" style="width: 45%;"></el-input>
              </el-form-item>
              <el-form-item label="配送方式">
                <el-select v-model="selectionGoods.logisticsType" placeholder="请选择配送方式" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="京东" :value="1"></el-option>
                  <el-option label="其他" :value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="margin-top: 20px">
                <el-button type="primary" @click="queryGetList">筛选</el-button>
                <el-button @click="reset">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="20">
          <div style="padding: 10px;">
            <div style="display: flex;justify-content: space-between;width:100%;align-items: center;">
              <div>
                <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">
                选品库商品列表
              </span>
                  <br/>
                  <span class="text-muted mt-1 fw-bold fs-7">
                请从全部已上架商品列表中，挑选合适的商品放到店铺中展示
              </span>
                </h3>
              </div>
              <div>
                <label style="font-weight: bold; font-size: 16px;">京东溢价比率：</label>
                <el-input v-model="jdRate" size="small" style="width: 7%; margin-right: 6px;" @blur="editRate"></el-input>
                <label style="font-weight: bold; margin-right: 6px; font-size: 16px;">分销价加价比率：</label>
                <el-input v-model="rate" size="small" style="width: 7%; margin-right: 6px;" @blur="editRate"></el-input>
                <el-button :plain="index!==0" size="small" type="primary" @click="switchGoods(0)">可选商品</el-button>
                <el-button :plain="index!==1" size="small" type="primary" @click="switchGoods(1)">已选商品</el-button>
                <el-button size="small" type="success" @click="selTopicCurrentGoodsAll">一键挑选</el-button>
                <el-button size="small" type="success" @click="selTopicPushGoodsAll">一键挑选(新)</el-button>
              </div>
            </div>
            <el-table
                :data="selectGoods"
                :row-class-name="tableRowClassName"
                style="width: 100%">
              <el-table-column label="商品ID" width="88px">
                <template slot-scope="scope">
                  {{scope.row.id}}
                </template>
              </el-table-column>
              <el-table-column label="商品信息">
                <template slot-scope="scope">
                  <div v-if="scope.row.picUrl.indexOf('/Uploads') ==0">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                        <img :src="`https://www.1c-1g.cn${scope.row.picUrl}`"
                             alt="" class="">
                      </div>
                      <div
                          class="d-flex justify-content-start flex-column">
                        <a :href="`${scope.row.jdUrl}`"
                           class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                           target="_blank">{{ scope.row.title }}</a>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                        <img :src="scope.row.picUrl">
                      </div>
                      <div
                          class="d-flex justify-content-start flex-column">
                        <a :href="`https://m.jd.com/product/${scope.row.jdUrl}.html`"
                           class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                           target="_blank">{{ scope.row.title }}</a>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分类" width="120px">
                <template slot-scope="scope">
                  <span class="text-muted fw-bold text-muted d-block fs-7">
                    {{ scope.row.categoryId1Name ? scope.row.categoryId1Name : "" }}
                    <br>
                    {{ scope.row.categoryId2Name ? scope.row.categoryId2Name : "" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="商品来源" width="100">
                <template slot-scope="scope">
                  <el-tooltip
                      class="item"
                      effect="dark"
                      :content="String(scope.row.skuId)"
                      placement="top">
                    <span>{{ scope.row.isJd == 1 ? "京东" : "自营" }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="市场价" width="100">
                <template slot-scope="scope">
                  <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.price | format }}
                  </div>
                  加价：
                  <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.price * jdRate | format }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分销价" width="100">
                <template slot-scope="scope">
                  <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                       @click="editDisPrice(scope.row)">
                    {{ scope.row.disPrice | format }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="喜豚宝销售价" width="100">
                <template slot-scope="scope">
                  <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.disPrice * rate | format }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="折扣"
                  prop="price" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.isJd==1">
                    <a v-if="scope.row.disPrice"
                       class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{
                        (scope.row.discount
                            * 10).toFixed(1)
                      }}折</a>
                    <a v-else>-</a>
                  </div>
                  <div v-else>
                    <a v-if="scope.row.disPrice"
                       class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{
                        (((scope.row.poolDisPrice)
                            / scope.row.price) * 10).toFixed(1)
                      }}折</a>
                    <a v-else>-</a>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                   label="利润率"
                   prop="price" width="100">
                 <template slot-scope="scope">
                   <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                     {{ (scope.row.profit * 100).toFixed(2) }}%
                   </div>
                 </template>
               </el-table-column>-->
              <el-table-column
                  label="销售方式"
                  prop="price" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.lowestBuy == 1 && scope.row.isJd == 1"
                        class="text-muted fw-bold text-muted d-block fs-7">一件代发</span>
                  <span v-else class="text-muted fw-bold text-muted d-block fs-7">
                    集采({{ scope.row.lowestBuy }}个起售)
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="评论" width="100">
                <template slot-scope="scope">
                  <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.commentOkCount }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="配送" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.logisticsType === 1" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    京东配送
                  </span>
                  <span v-else class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    其他配送
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="setTopicCurrentGoodsId(scope.row)">挑选</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
              :page-size="selectionGoods.size"
              :page-sizes="[10, 30, 50,100,200]"
              :total="total"
              :current-page.sync="selectionGoods.current"
              layout="total, sizes, prev, pager, next, jumper"
              style="margin-top: 10px;text-align: center"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange">
          </el-pagination>
        </el-col>
      </el-row>
      <SelGoods v-if="index===1" :items="item" @SwithGoods="switchGoods"></SelGoods>
    </el-dialog>
  </div>
</template>
<script>
import {mixins} from "@/views/SelectGoods/components/Goods/mixins";

export default {
  mixins: [mixins]
}
</script>
<style>
#topicGoodsForm div label.el-form-item__label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

#topicGoodsForm .el-form-item {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.el-table .warning-row {
  background: oldlace;
}
</style>