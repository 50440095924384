<template>
  <div style="padding-top: 10px">
    <div v-loading="loading"
         :element-loading-text="loadingMsg"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         style="max-width: 100%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px"
    >
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="params.phone" placeholder="请输入需要查询的收货手机号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="params.nickName" placeholder="请输入需要查询的收件人"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.status" clearable placeholder="请选择支付状态" size="mini" @change="getCardOrderList">
            <el-option label="请选择支付状态" value=""></el-option>
            <el-option label="未支付" value="0"></el-option>
            <el-option label="已支付" value="1"></el-option>
            <el-option label="作废" value="2"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="validCode" multiple clearable placeholder="请选择卡片状态" size="mini">
            <!--订单状态-2 部分取消 -1 已取消 0 未提交京东(失败) 1 提交京东待发货 2 已发货 3 已妥投 4 部分妥投 5 已完成 6 部分完成 7 部分发货-->
            <el-option label="未支付" value="3"></el-option>
            <el-option label="已退款" value="4"></el-option>
            <el-option label="申请退款" value="5"></el-option>
            <el-option label="待发货" value="15"></el-option>
            <el-option label="已发货" value="16"></el-option>
            <el-option label="已完成" value="17"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value2"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束下单日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始下单日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.provinceId" placeholder="请选择省" size="mini" @change="provinceChange">
            <el-option v-for="(item,index) in addressList.provinceList" :key="index" :label="item.name"
                       :value="`${item.name}`"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="getCardOrderList">搜索</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="resetListParams">重置</el-button>
        </div>
<!--        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="exportCardList">导出卡数据</el-button>
        </div>-->
      </div>
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          消费卡批次号：
          <el-input v-model="params.mdlOrder" placeholder="请输入需要查询的消费卡批次号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          使用者ID：
          <el-input v-model="params.useUserId" placeholder="使用者ID"
                    size="mini" style="width: 200px"></el-input>
        </div>
      </div>

      <el-table
          :data="rows"
          :row-class-name="tableRowClassName"
          style="width: 100%">
        <!--        子列表 下拉列表 start-->
        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="margin: 10px">
              <el-table
                  :data="props.row.xiTunBaoCardUserItemVos"
                  :row-class-name="tableRowClassName"
                  style="width: 100%;margin-bottom: 20px;">
                <el-table-column
                    label="父卡批次号"
                    prop="parentId"
                    width="200">
                </el-table-column>
                <el-table-column
                    label="备注"
                    prop="remark"
                    width="100">
                </el-table-column>
                <el-table-column
                    label="分享状态"
                    width="110">
                  <template slot-scope="{row}">
                    {{ row.shareStatus | shareStatusF }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="卡片状态"
                    width="110">
                  <template slot-scope="{row}">
                    {{ row.status | cardItemStatusF }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="卡图片"
                    width="150">
                  <template slot-scope="scope">
                    <div class="demo-image__preview">
                      <el-image
                          style="width: 100px; height: 100px"
                          :src="scope.row.cardImg"
                          :preview-src-list="getSrcList(scope.row.cardImg)">
                      </el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="卡类型"
                    width="100">
                  <template slot-scope="{row}">
                    {{ row.cardType | cardTypeF }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="开通时间"
                    width="176">
                  <template slot-scope="{row}">
                    <div v-if="row.openTime">
                      {{ `${row.openTime}000` | formatDate }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="到期时间"
                    width="176">
                  <template slot-scope="{row}">
                    <div v-if="row.expiryTime">
                      {{ `${row.expiryTime}000` | formatDate }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="兑换时间"
                    width="176">
                  <template slot-scope="{row}">
                    <div v-if="row.receiveTime">
                      {{ `${row.receiveTime}000` | formatDate }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="分享时间"
                    width="100">
                  <template slot-scope="{row}">
                    {{ row.shareTime }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="分享结束时间"
                    width="120">
                  <template slot-scope="{row}">
                    {{ row.shareEndTime }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="卡密"
                    prop="cdkey"
                    width="120">
                </el-table-column>
                <el-table-column
                    label="卡编号"
                    prop="cardNum"
                    width="200">
                </el-table-column>
                <el-table-column
                    label="购买类型"
                    width="100">
                  <template slot-scope="{row}">
                    {{ row.cardOrigin | cardOriginF }}
                  </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="200px">
                  <template slot-scope="{row}">
                    <el-button
                        size="mini"
                        @click="editCardOrder(row,3)">申请退款
                    </el-button>
                    <el-button
                        size="mini"
                        @click="editCardOrder(row,4)">退款完成
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <!--        子列表 下拉列表end-->
        <el-table-column
            label="卡订单ID"
            prop="id"
            width="100">
        </el-table-column>
        <el-table-column
            label="购买者"
            prop="userId"
            width="65">
        </el-table-column>
        <el-table-column
            label="使用者"
            prop="useUserId"
            width="65">
        </el-table-column>
        <el-table-column
            label="支付状态"
            width="80">
          <template slot-scope="{row}">
            {{ row.status | statusF }}
          </template>
        </el-table-column>
        <el-table-column
            label="卡片状态"
            width="80">
          <template slot-scope="{row}">
            {{ row.validCode | validCodeF }}
          </template>
        </el-table-column>
        <el-table-column
            label="喜豚宝售卖面值(点 1:1)"
            width="100">
          <template slot-scope="{row}">
            {{ row.faceValue | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="卡片单价"
            width="80">
          <template slot-scope="{row}">
            {{ row.unitPrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="购买数量"
            prop="num"
            width="50">
        </el-table-column>
        <el-table-column
            label="支付现金"
            width="100">
          <template slot-scope="{row}">
            {{ row.payPrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="收货人"
            prop="consignee"
            width="80">
        </el-table-column>
        <el-table-column
            label="手机号"
            prop="phone"
            width="120">
        </el-table-column>
        <el-table-column
            label="收货地址"
            prop="harvestAddress"
            width="100">
        </el-table-column>
        <el-table-column
            label="收益"
            width="100">
          <template slot-scope="{row}">
            {{ row.reward | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="收益状态"
            width="100">
          <template slot-scope="{row}">
            {{ row.rewardStatus | rewardStatusF }}
          </template>
        </el-table-column>
        <el-table-column
            label="卡片类型"
            width="100">
          <template slot-scope="{row}">
            {{ row.cardType | cardTypeF }}
          </template>
        </el-table-column>
        <el-table-column
            label="支付时间"
            width="176">
          <template slot-scope="{row}">
            <div v-if="row.payTime">
              {{ `${row.payTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="下单时间"
            width="176">
          <template slot-scope="{row}">
            {{ `${row.createTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            label="喜豚宝订单号"
            prop="mdlOrder"
            width="200">
        </el-table-column>

<!--        <el-table-column
            label="操作"
            width="200px">
          <template slot-scope="{row}">
&lt;!&ndash;            <el-button
                size="mini"
                @click="editCardOrder(row,3)">申请退款
            </el-button>
            <el-button
                size="mini"
                @click="editCardOrder(row,4)">退款完成
            </el-button>&ndash;&gt;
          </template>
        </el-table-column>-->

      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-dialog
        :visible.sync="deliveryDialog"
        title="物流"
        width="30%">
      <el-timeline :reverse="true">
        <el-timeline-item
            v-for="(activity, index) in deliveryList"
            :key="index"
            :timestamp="activity.trackMsgTime | parseTime">
          {{ activity.trackContent }}
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliveryDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import {
  getCardOrderAssociatedCardsListApi,getCardOrderAssociatedCardsCountApi,exportXiTunBaoCardOrderListApi,editCardOrderApi
} from "@/api/backend/xitunbaoCardApi";
import sd from "silly-datetime";
import {findBackendJdRegionApi} from "@/api/backend/addressApi";
import {exportXiTunBaoCardListApi} from "@/api/backend/xitunbaoApi";


export default {
  name: "XiTunBaoCardOrder",
  data() {

    return {
      value2: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      rows: [],
      size: 10,
      current: 1,
      total: 0,
      loading: false,
      loadingMsg: "列表加载中...",
      addressList: {
        provinceList: [],
        cityList: [],
        countyList: [],
        townList: []
      },
      params: {
        current: 1,
        size: 10,
        validCode: "",
        status: "",
        nickName: "",
        phone: "",
        startCreateTime: "",
        endCreateTime: "",
        provinceId: "",
        mdlOrder: ""
      },
      validCode: [],
      deliveryList: [],
      deliveryDialog: false
    }
  },
  filters: {
    orderStatusF(v) {
      // 订单状态-2 部分取消 -1 已取消 0 未提交京东(失败) 1 提交京东待发货 2 已发货 3 已妥投 4 部分妥投 5 已完成 6 部分完成 7 部分发货
      switch (v) {
        case "-2":
          return '部分取消';
        case "-1":
          return '已取消';
        case "0":
          return '未提交京东';
        case "1":
          return '提交京东待发货';
        case "2":
          return '已发货';
        case "3":
          return '已妥投';
        case "4":
          return '部分妥投';
        case "5":
          return '已完成';
        case "6":
          return '部分完成';
        case "7":
          return '部分发货';
      }
    },
    cardItemStatusF(v) {
      // -1 已取消  1  '待发货' 2  '已发货' 3  '已妥投' 4 '已完成'
      switch (v) {
        case "-1":
          return '未开通';
        case "0":
          return '未使用'
        case "1":
          return '已发货'
        case "2":
          return '已使用'
        case "3":
          return '申请退款'
        case "4":
          return '已退款'
      }
    },
    validCodeF(value) {
      // 商品状态:3=未支付,4=退款,5=申请退款,15=已支付等待发货,16=已发货,17=确认收货
      switch (value) {
        case "3":
          return '未支付'
        case "4":
          return '已退款'
        case "5":
          return '申请退款'
        case "15":
          return '已支付等待发货'
        case "16":
          return '已发货'
        case "17":
          return '已完成'
      }
    },
    rewardStatusF(value) {
      // 收益状态:0=未结算,1=已结算
      switch (value) {
        case "0":
          return '未结算'
        case "1":
          return '已结算'
      }
    },
    cardTypeF(value) {
      switch (value) {
        case "1":
          return '电子卡'
        case "2":
          return '实体卡'
      }
    },
    cardOriginF(value) {
      switch (value) {
        case "1":
          return '平台购买'
        case "2":
          return '批量购买'
      }
    },
    formatDate(value) {
      return sd.format(+value)
    },
    goodsTypeF(type) {
      switch (type) {
        case "1":
          return '普通商品'
        case "2":
          return '限时秒杀'
        case "3":
          return '送余额商品'
        case "4":
          return '一元购商品'
      }
    },
    statusF(type) {
      switch (type) {
        case "0":
          return '未支付'
        case "1":
          return '已支付'
        case "2":
          return '已作废'
      }
    },
    shareStatusF(type) {
      switch (type) {
        case "0":
          return '未分享'
        case "1":
          return '已分享'
      }
    },
    orderTypeF(type) {
      // 1=普通商品订单,2=限时秒杀订单,3=送余额商品订单
      switch (type) {
        case "1":
          return '普通商品订单'
        case "2":
          return '限时秒杀订单'
        case "3":
          return '送余额商品订单'

      }
    }
  },
  methods: {
    /* 导出数据*/
    async exportCardList() {
      this.loadingMsg = "文件导出中..."
      this.loading = true;
      const response = await exportXiTunBaoCardOrderListApi(this.params)
      const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sd.format(new Date())}.xlsx`; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.$message({
        message: '文件下载成功',
        type: 'success'
      });
      this.loading = false;
    },
    //  查询省
    provinceChange(id) {
      this.findBackendJdRegion("cityList", id);
      this.getCardOrderList()
    },
    async findBackendJdRegion(type, id) {
      let {data} = await findBackendJdRegionApi({
        id
      })
      this.addressList[type] = data
    },
    /* 下拉菜单的 子列表*/
    getSrcList(cardPicUrl) {
      if (cardPicUrl) {
        return cardPicUrl.split(',');
      }
    },
    resetListParams() {
      this.params = {
        current: 1,
        size: 10,
        status: "",
        nickName: "",
        phone: "",
        startCreateTime: "",
        endCreateTime: "",
        provinces: "",
        city: "",
        area: "",
        street: "",
        orderStatus: ""
      }
      this.value2 = []
      this.getCardOrderList();
    },
    tableRowClassName({row}) {
      

    },
    async getCardOrderList() {
      if (this.validCode.length > 0 && this.validCode != null) {
        this.params.validCode = this.validCode.join(',');
      }
      this.loading = true;
      this.loadingMsg = "列表加载中...";
      this.params.startCreateTime = this.value2.length > 0 ? `${+this.value2[0]}`.slice(0, 10) : ""
      this.params.endCreateTime = this.value2.length > 0 ? `${+this.value2[1]}`.slice(0, 10) : ""
      let {data} = await getCardOrderAssociatedCardsListApi(this.params)
      let totalData = await getCardOrderAssociatedCardsCountApi(this.params)
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = totalData.data
      this.loading = false;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getCardOrderList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getCardOrderList();
    },
    async editCardOrder (item,status) {
      this.$confirm('此操作将修改卡的状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let config = {
          ...item,
          status: status,
          cardUserId: item.id
        };
        await editCardOrderApi(config);
        this.$message({
          type: 'success',
          message: '修改成功!'
        });
        this.getCardOrderList();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });

    }
  },
  created() {
    this.getCardOrderList();
    this.findBackendJdRegion("provinceList", 0);
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>