/**
 * 卡密规则
 * @type {{}}
 */
export const CardKeyRule = [
    {
        value: 1,
        label: '纯数字',
        level: "简单"
    }, {
        value: 2,
        label: '英文小写',
        level: "简单"
    }, {
        value: 3,
        label: '英文大写',
        level: "简单"
    }, {
        value: 4,
        label: '纯数字 + 英文小写',
        level: "一般"
    }, {
        value: 5,
        label: '纯数字 + 英文大写',
        level: "一般"
    }, {
        value: 6,
        label: '纯数字 + 英文大小写',
        level: "复杂"
    }
]
