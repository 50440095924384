import service from "@/utils/requestAdmin";

/**
 * 获取选品库列表
 * @param params
 * @returns {*}
 */
export function getSelectionListApi(params) {
    return service({
        url: '/selection-topic',
        method: 'get',
        params,
    })
}

/**
 * 修改选品库名字
 * @param data
 * @returns {*}
 */
export function editSaveTopicApi(data) {
    return service({
        url: '/selection-topic',
        method: 'put',
        data,
    })
}

/**
 * 复制新选品库
 * @param data
 * @returns {*}
 */
export function copyTopicApi(data) {
    return service({
        url: '/selection-topic/copyTopic',
        method: 'POST',
        data,
    })
}

/**
 * 删除选品库
 * @param data
 * @returns {*}
 */
export function delTopicApi(params) {
    return service({
        url: '/selection-topic',
        method: 'DELETE',
        params,
    })
}

/**
 * 修改选品库关键词
 */
export function editSaveTopicKeywordApi(data) {
    return service({
        url: '/selection-topic/updateKeywords',
        method: 'put',
        data,
    })
}

/**
 * 获取选品库可选择商品列表
 * @param params
 * @returns {*}
 */
export function getListTopicApi(params) {
    return service({
        url: '/selection-topic-goods-relation',
        method: 'get',
        params,
    })
}

/**
 * 获取选品库可选择商品列表条数
 * @param params
 * @returns {*}
 */
export function getListTopicCountApi(params) {
    return service({
        url: '/selection-topic-goods-relation/count',
        method: 'get',
        params,
    })
}

/**
 * 将商品挑选到选品库中
 */
export function updateGoodsPriceApi(data) {
    return service({
        url: '/selection-topic-goods-relation',
        method: 'POST',
        data,
    })
}

/**
 * 一键挑选
 */
export function pushAllSelGoodsApi(data) {
    return service({
        url: '/selection-topic-goods-relation/pushAllSelGoods',
        method: 'POST',
        data
    })
}
/**
 * 创建选品库
 */
export function createTopicApi(data) {
    return service({
        url: '/selection-topic',
        method: 'POST',
        data,
    })
}

/**
 * 获取已选择商品
 */
export function getSelectedPageApi(params) {
    return service({
        url: '/selection-topic-goods-relation/getSelectedPage',
        method: 'get',
        params,
    })
}

/**
 * 修改排序
 */
export function updateSortApi(data) {
    return service({
        url: '/selection-topic-goods-relation/modifySorting',
        method: 'PUT',
        data,
    })
}

/**
 * 删除商品
 */
export function deleteGoodsApi(data) {
    return service({
        url: '/selection-topic-goods-relation/delSelected',
        method: 'DELETE',
        data
    })
}

/**
 * 清空选品库
 */
export function clearTopicApi(params) {
    return service({
        url: '/selection-topic-goods-relation/clear',
        method: 'DELETE',
        params,
    })
}
/**
 * 导出选品库
 */
export function exportTopicApi(params) {
    return service({
        url: '/selection-topic-goods-relation/exportSelectedTopicGoods',
        method: 'get',
        responseType: 'blob',
        params,
    })
}