import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import userModules from "./modules/user"
import goodsModules from "./modules/goods"


export default new Vuex.Store({
  modules: {
    user: userModules,
    goods:goodsModules
  }
})
