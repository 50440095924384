<template>
  <div>
    <el-dialog
        :visible.sync="dialogVisible"
        title="卡密管理"
        top="50px"
        width="98%">
      <el-input v-model="params.name" placeholder="商品名称" size="mini" style="width: 200px"></el-input>&nbsp;&nbsp;
      <el-button size="mini" type="primary" @click="getGoodsList"> 搜 索</el-button>
      <el-table v-loading="loading"
                :data="list" element-loading-background="rgba(0, 0, 0, 0.8)"
                element-loading-spinner="el-icon-loading" element-loading-text="拼命加载中" style="width: 100%;">
        <el-table-column align="center" fixed label="礼包图片" width="100">
          <template slot-scope="props">
            <img :src="props.row.imageUrl" alt="" style="width:70px;height:70px;">
          </template>
        </el-table-column>
        <el-table-column align="center" fixed label="礼包名称" prop="name" width="170">
        </el-table-column>
        <el-table-column align="center" label="礼包编码" prop="giftCode" width="200">
        </el-table-column>
        <el-table-column label="礼包分类" width="100">
          <template slot-scope="props">
            <span>{{ props.row.categoryId1Name }}</span><br/>
            <span>{{ props.row.categoryId2Name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="市场价" width="100">
          <template slot-scope="props">
            <span>{{ props.row.giftPrice | format }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="供货价" width="100">
          <template slot-scope="props">
            <span>{{ props.row.giftSupplyPrice | format }}</span>
          </template>
        </el-table-column>
        <!--<el-table-column prop="giftSalePrice" label="销售价" width="100" sortable align="center">
        </el-table-column>-->
        <el-table-column align="center" label="利润率" sortable width="100">
          <template slot-scope="props">
            <span>{{ props.row.giftRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品数" prop="giftTotalGoods" width="100">
        </el-table-column>
        <el-table-column align="center" label="单品详情" width="100">
          <template slot-scope="props">
            <span v-if="props.row.showSingle === 1">显示</span>
            <span v-else>省略</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="state" width="100">
          <template slot-scope="props">
            <span v-if="props.row.isShow === 1">启用</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="props">
            <el-link :underline="false" type="primary" @click="openQrCode(props.row)">查看二维码</el-link>
            &nbsp;&nbsp;
            <el-link :underline="false" type="primary" @click="openKey(props.row)">查看卡密</el-link>
            &nbsp;&nbsp;
            <el-link :underline="false" type="primary" @click="exportExcel(props.row)">导出卡密二维码</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :page-size="params.size"
          :page-sizes="[10, 20, 30, 50]"
          :total="goodsTotal"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleGoodsSizeChange"
          @current-change="handleGoodsCurrentChange">
      </el-pagination>
    </el-dialog>
    <!--  内部 弹窗 -->
    <el-dialog :visible.sync="dialogVisibleKey" append-to-body title="查看卡密" top="50px" width="98%">
      <div style="width: 100%;position: relative">
        <div style="position: absolute;right: 0;top:-30px">
          <el-button size="mini" type="success" @click="batchDialog=true">批量操作</el-button>
          <el-button size="mini" type="success" @click="batchUpdateRemark">批量修改备注</el-button>
          <el-button size="mini" type="danger" @click="deleteAllKey">一键删除失效卡密</el-button>
          <el-button size="mini" type="primary" @click="generateDialog = true">生成卡密</el-button>
        </div>
        <div style="position: absolute;left: 0;top:-30px">
          <el-input v-model="keyParams.cardNo" placeholder="请输入卡号" size="mini" style="width: 200px"></el-input>&nbsp;&nbsp;
          <el-input v-model="keyParams.cardNoPrefix" placeholder="请输入卡号前缀" size="mini"
                    style="width: 200px"></el-input>&nbsp;&nbsp;
          <el-input v-model="keyParams.tel" placeholder="请输入使用人手机号" size="mini"
                    style="width: 200px"></el-input>&nbsp;&nbsp;
          <el-button size="mini" type="primary" @click="getKeyList">搜 索</el-button>
        </div>
        <div>
          <el-table
              :data="keyList"
              @selection-change="handleSelectionChange"
              style="width: 100%;min-height: 50vh">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column align="center" label="卡号" prop="cardNo">
            </el-table-column>
            <el-table-column align="center" label="卡号前缀" prop="cardNoPrefix">
            </el-table-column>
            <el-table-column align="center" label="卡密" prop="cardSecret">
            </el-table-column>
            <el-table-column align="center" label="使用人手机号" prop="tel">
            </el-table-column>

            <el-table-column align="center" label="状态" prop="cardSecret">
              <template slot-scope="scope">
                <span v-if="scope.row.status === 0">未开通 未使用</span>
                <span v-if="scope.row.status === 1">已开通 未使用</span>
                <span v-if="scope.row.status === 2">已兑换</span>
                <span v-if="scope.row.status === 3">失效</span>
                <span v-if="scope.row.status === 4">存入卡包 未使用</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" prop="crTime">
              <template slot-scope="scope">
                {{ scope.row.crTime | parseTime }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="备注" prop="remark">
            </el-table-column>
            <el-table-column align="center" label="操作" prop="crTime">
              <template slot-scope="scope">
                <!--<el-link type="primary" @click="openQrCode(scope.row)">查看</el-link>-->
                &nbsp;&nbsp;
                <el-link type="danger" @click="deleteKey(scope.row)">删除</el-link>
                &nbsp;&nbsp;
                <el-link @click="updateRemark(scope.row)">修改备注</el-link>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :page-size="keyParams.size"
              :page-sizes="[10, 20, 30, 50]"
              :total="keyTotal"
              layout="total, sizes, prev, pager, next, jumper"
              style="margin-top: 10px;text-align: center"
              @size-change="handleKeySizeChange"
              @current-change="handleKeyCurrentChange">
          </el-pagination>
        </div>
      </div>
      <!--批量操作-->
      <el-dialog id="batchId" :visible.sync="batchDialog" append-to-body title="批量操作卡密" top="50px" width="20%">
        <el-form ref="batchForm" :model="batchForm" :rules="batchRules" label-width="60px">
          <el-form-item label="状态">
            <el-select v-model="batchForm.status" placeholder="请选择开通状态" size="mini" style="width: 100%">
              <el-option label="开通" value="1"></el-option>
              <el-option label="失效" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model="batchForm.type" size="mini">
              <el-radio-button label="1" style="width: 50%">按照卡号区间</el-radio-button>
              <el-radio-button label="2" style="width: 50%">按照卡号前缀</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="batchForm.type==='1'" label="区间" prop="cardNo" style="width: 100%">
            <el-input v-model="batchForm.startCardNo" placeholder="开始卡号" size="mini" style="width: 45%;"></el-input>
            <span style="margin: 0 5px;">~</span>
            <el-input v-model="batchForm.endCardNo" placeholder="结束卡号" size="mini" style="width: 45%;"></el-input>
          </el-form-item>
          <el-form-item v-if="batchForm.type==='2'" label="前缀" prop="cardNoPrefix" style="width: 100%">
            <el-input v-model="batchForm.cardNoPrefix" placeholder="卡号前缀" size="mini"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" type="primary" @click="batchOperation">确 定</el-button>
        </span>
      </el-dialog>

      <!--生成卡密-->
      <el-dialog :visible.sync="generateDialog" append-to-body title="生成卡密" top="50px" width="20%">
        <el-form ref="form" v-loading="generateKeyLoading" :model="keyForm" element-loading-background="rgba(0, 0, 0, 0.8)"
                 element-loading-spinner="el-icon-loading"
                 element-loading-text="卡密生成中..."
                 label-width="80px">
          <el-form-item :rules="[
               { required: true, message: '请输入生成数量', trigger: 'blur' },
             ]"
                        label="生成数量"
          >
            <el-input v-model.number="keyForm.num" placeholder="请输入生成数量" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="卡密长度">
            <el-select v-model="keyForm.length" placeholder="请选择生成位数" size="mini" style="width: 100%">
              <el-option :value="5" label="5位"></el-option>
              <el-option :value="6" label="6位"></el-option>
              <el-option :value="7" label="7位"></el-option>
              <el-option :value="8" label="8位"></el-option>
              <el-option :value="9" label="9位"></el-option>
              <el-option :value="10" label="10位"></el-option>
              <el-option :value="11" label="11位"></el-option>
              <el-option :value="12" label="12位"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生成规则">
            <el-select v-model="keyForm.rule" placeholder="请选择生成规则" size="mini" style="width: 100%">
              <el-option v-for="(item,index) in getCardKeyRule()" :key="index" :label="item.label" :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.level }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="卡密前缀">
            <el-input v-model="keyForm.cardNoPrefix" placeholder="请输入卡密前缀" size="mini"></el-input>
          </el-form-item>
          <el-button size="small" type="primary" @click="generateKey">生 成</el-button>
        </el-form>
      </el-dialog>

    </el-dialog>
    <el-dialog
        :modal="false"
        :visible.sync="qrCodeDialogVisible"
        title="扫码"
        width="20%">
      <img :src="qrCodeImag" width="100%">
    </el-dialog>
    <canvas ref="canvass" style="width: 100%;height: 100%;display: none"></canvas>
  </div>
</template>
<script>
import {mixins} from "./mixins";

export default {
  mixins: [mixins]
}
</script>
<style>
#batchId .el-dialog__body {
  padding-top: 0px !important;
}

#batchId .el-form-item {
  margin-bottom: 0 !important;
}
</style>