<template>
  <div
      class="bg-body"
      id="kt_body"
      style="display: flex; flex-direction: column; height: 100vh"
  >
    <div class="d-flex flex-column flex-root">
      <div
          class="d-flex flex-column my-component flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
          style="background-image: url('/development-hd.png')"
      >
        <div
            class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
        >
          <a class="mb-14">
            <img alt="Logo" src="/logo-2-dark.png" class="h-65px"/>
          </a>
          <div
              class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto"
          >
            <form
                class="form w-100"
                novalidate="novalidate"
                id="kt_sign_in_form"
                action=""
            >
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">欢迎进入系统</h1>
              </div>
              <div class="fv-row mb-10">
                <label class="form-label fs-6 fw-bolder text-dark">账号</label>
                <input
                    placeholder="请输入账号名"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    id="username"
                    v-model="parame.userName"
                    name="username"
                    autocomplete="off"
                />
              </div>
              <div class="fv-row mb-10" data-kt-password-meter="true">
                <div class="d-flex flex-stack mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0"
                  >密码</label>
                </div>
                <div class="position-relative mb-3">
                  <input
                      class="form-control form-control-lg form-control-solid"
                      v-model="parame.password"
                      :type="inputType"
                      placeholder="请输入登录密码"
                      name="password"
                      id="password"
                      autocomplete="off"
                      @keyup.enter="userLoginMethod"
                  />
                  <span
                      class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      data-kt-password-meter-control="visibility"
                  >
                    <i
                        class="bi bi-eye-slash fs-2"
                        style="padding-right: 40px"
                        v-show="inputType == 'password'"
                        @click="inputType = 'text'"
                    ></i>
                    <i
                        class="bi bi-eye fs-2"
                        style="padding-right: 40px"
                        v-show="inputType != 'password'"
                        @click="inputType = 'password'"
                    ></i>
                  </span>
                </div>
              </div>
              <div class="text-center">
                <button
                    type="button"
                    @click="userLoginMethod"
                    class="btn btn-lg btn-primary w-100">
                  登录系统
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex flex-center flex-column-auto p-10">
          <div class="d-flex align-items-center fw-bold fs-6">
            <a class="text-muted text-hover-primary px-2">联系我们：</a>
            <a class="text-muted text-hover-primary px-2">18958041665</a>
            <a class="text-muted text-hover-primary px-2">server@enower.com</a>
            <a href="https://beian.miit.gov.cn" class="text-muted text-hover-primary px-2"
               target="_blank">浙ICP备20022022号</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"
import {encrypt} from "@/utils/encrypt";

export default {
  name: "Login",
  data() {
    return {
      parame: {
        userName: "",
        password: "",
      },
      inputType: "password"
    };
  },
  methods: {
    ...mapActions("user", ["userLogin"]),
    async userLoginMethod() {
      const obj = JSON.parse(JSON.stringify(this.parame));
      obj.password = encrypt(obj.password)
      await this.userLogin(obj);
    },
  },
  computed: {},
};
</script>
<style>

</style>